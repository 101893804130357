import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 24 24">
      <title>Verifications</title>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="UI-Elements"
          transform="translate(-680.000000, -676.000000)"
          fill="#626466"
        >
          <g id="private-active" transform="translate(680.000000, 676.000000)">
            <polygon
              id="Fill-1"
              points="7.443 1 2 6.443 1 7.443 1 16.557 7.443 23 16.556 23 23 16.557 23 7.443 22.991 7.434 21 9.426 21 15.727 16.727 20 8.443 20 3 14.556 3 8.272 8.272 3 15.728 3 17.896 5.168 9.112 13.953 6.707 11.548 5.293 12.963 9.829 17.5 20.736 6.593 20.735 5.18 20.49 4.933 16.556 1"
            ></polygon>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
