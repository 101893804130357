import { useMatch } from 'react-router-dom';
import { NavLink as MuiNavLink } from 'react-router-dom';

import ListItem from '@mui/material/ListItem';

function NavListItem({ to, target, children, ...props }) {
  let match = useMatch({
    path: to,
    exact: true,
  });

  return (
    <ListItem
      to={to}
      target={target}
      selected={Boolean(match)}
      component={MuiNavLink}
      {...props}
    >
      {children}
    </ListItem>
  );
}

export default NavListItem;
