import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <title>Biography</title>
      <g
        id="PWA---UI"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="UI-Elements"
          transform="translate(-1363.9996, -291)"
          fill="#626466"
        >
          <g id="bios" transform="translate(1363.9996, 291)">
            <path
              d="M14.811,2.1244 L19.672,6.9844 L21.883,9.1964 L19.295,18.8554 L8.379,21.9994 L24.001,21.9994 L24.001,23.9994 L1.436,23.9994 L12.179,13.2414 C12.35,13.2874 12.526,13.3104 12.701,13.3104 C13.21,13.3104 13.717,13.1194 14.104,12.7314 C14.885,11.9494 14.885,10.6844 14.104,9.9034 C13.714,9.5124 13.202,9.3174 12.69,9.3174 C12.178,9.3174 11.667,9.5124 11.276,9.9034 C10.754,10.4254 10.59,11.1614 10.765,11.8274 L0,22.6004 L5.152,4.7124 L14.811,2.1244 Z M14.214,4.3554 L6.761,6.3524 L4.069,15.6984 L8.707,11.0584 C8.768,10.1014 9.17,9.1804 9.862,8.4894 C10.617,7.7334 11.622,7.3174 12.69,7.3174 C13.759,7.3174 14.763,7.7334 15.518,8.4894 C17.078,10.0484 17.078,12.5854 15.519,14.1454 C14.826,14.8384 13.921,15.2434 12.948,15.3024 L10.419,17.8354 L17.295,15.8554 L19.074,9.2154 L14.214,4.3554 Z M16.9297,0 L24.0007,7.071 L22.5857,8.485 L15.5157,1.414 L16.9297,0 Z"
              id="Combined-Shape"
            ></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
