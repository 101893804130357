import React from 'react';
import { Helmet } from 'react-helmet';

const TrackingCode = () => {
  return (
    <Helmet>
      {/* Replace 'your-tracking-code' with your actual tracking code */}
      {/*Fathom - beautiful, simple website analytics */}
      <script
        src="https://cdn.usefathom.com/script.js"
        data-site="BUQLFVNC"
        defer
      ></script>
      {/* Fathom */}
    </Helmet>
  );
};

export default TrackingCode;
