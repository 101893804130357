import React from 'react';
import { makeStyles } from '@mui/styles';
import styles from './styles';
import { Box, Typography } from '@mui/material';
import CPVisibleIcon from '../../CPVisibleIcon';
import LabelBox from '../../LabelBox';
import PhoneIcon from '../../../../icons/phoneIcon';
import MailIcon from '../../../../icons/atomsMail';
import { repTypes } from '../repTypes';

const useStyles = makeStyles(styles);

const ReadOnlyRepresentative = ({ representative, isVisible }) => {
  const classes = useStyles();

  const contactRepType = repTypes[representative?.representativeType]?.label;

  return (
    <Box className={classes.info}>
      <Box className={classes.top}>
        <LabelBox>{contactRepType}</LabelBox>
        <div className={classes.icon}>
          <CPVisibleIcon isVisible={isVisible} />
        </div>
      </Box>
      <Typography className={classes.infoName} variant="h5">
        {representative?.repName}
      </Typography>
      <Box className={classes.infoContact}>
        <Typography className={classes.infoContactSelf}>
          {representative?.contactPhone ? (
            <>
              <PhoneIcon
                sx={{
                  fontSize: '16px',
                  height: '14.67px',
                  fullWidth: '14.67px',
                }}
              />
              {representative?.contactPhone}
            </>
          ) : null}
        </Typography>
        <Typography className={classes.infoContactSelf}>
          <MailIcon
            sx={{ fontSize: '16px', fullWidth: '16px', height: '13.33px' }}
          />
          {representative?.contactEmail}
        </Typography>
      </Box>
      <Typography>{representative?.contactNote}</Typography>
    </Box>
  );
};

export default ReadOnlyRepresentative;

// const Representative = ({
//                           editMode,
//                           representative,
//                           onDelete,
//                           onDataChange,
//                         }) => {
//
//   return editMode ? (
//     <>
//     </>
//   ) : (
//     <Box className={classes.info}>
//       <Box className={classes.top}>
//         <LabelBox>{contactRepType}</LabelBox>
//         <div className={classes.icon}>
//           <CPVisibleIcon dataField={representative} />
//         </div>
//       </Box>
//       <Typography className={classes.infoName} variant="h5">
//         {representative?.value?.repName}
//       </Typography>
//       <Box className={classes.infoContact}>
//         <Typography className={classes.infoContactSelf}>
//           {representative?.value?.contactPhone ? (
//             <>
//               <PhoneIcon
//                 sx={{
//                   fontSize: '16px',
//                   height: '14.67px',
//                   fullWidth: '14.67px',
//                 }}
//               />
//               {representative?.value?.contactPhone}
//             </>
//           ) : null}
//         </Typography>
//         <Typography className={classes.infoContactSelf}>
//           <MailIcon
//             sx={{ fontSize: '16px', fullWidth: '16px', height: '13.33px' }}
//           />
//           {representative?.value?.contactEmail}
//         </Typography>
//       </Box>
//       <Typography>{representative?.value?.contactNote}</Typography>
//     </Box>
//   );
// };
