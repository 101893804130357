import React from 'react';
import { SvgIcon } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

function Icon({ hasGradient, sx, ...rest }) {
  const gradientId = uuidv4();
  return (
    <SvgIcon
      sx={[
        {
          ...sx,
        },
        hasGradient
          ? {
              '&': {
                g: {
                  fill: `url(#gradient-${gradientId})`,
                },
              },
            }
          : {
              '&': {
                g: {
                  fill: '',
                },
              },
            },
      ]}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      viewBox="0 0 24 24"
    >
      {hasGradient && (
        <defs>
          <linearGradient
            id={`gradient-${gradientId}`}
            gradientTransform="rotate(10)"
          >
            <stop offset="0%" stopColor="#FF2667" />
            <stop offset="51%" stopColor="#73AEE6" />
            <stop offset="100%" stopColor="#00FFCC" />
          </linearGradient>
        </defs>
      )}
      <g fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill={hasGradient ? null : '#626466'}>
          <path
            d="M17.543,15 L18.141,15 L19.85,19.882 L19.406,20.327 L17.543,15 Z M11.982,1 C12.783,1 13.574,1.212 14.268,1.612 C15.677,2.426 16.552,3.942 16.552,5.569 L16.552,9.536 L16.534,9.552 L16.534,7.569 C16.534,5.23 15.275,3.05 13.25,1.88 C12.573,1.489 11.832,1.224 11.065,1.093 C11.366,1.032 11.674,1 11.982,1 L11.982,1 Z M18.851,14 L13.092,14 L17.552,9.981 L17.552,5.569 C17.552,3.58 16.49,1.741 14.767,0.746 C13.044,-0.249 10.921,-0.249 9.198,0.746 C7.475,1.741 6.413,3.58 6.413,5.569 L6.413,9.981 L10.873,14 L5.149,14 L3,20.142 L6.834,24 L17.167,24 L21,20.142 L18.851,14 Z"
            id="Combined-Shape"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
