import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import MailIcon from '../../../icons/atomsMail';
import CMSTitleInfo from '../../../CMS/components/Title';
import { PersonaContext } from '../../../v2api/PersonaContext/PersonaContext';

const Contacts = () => {
  const { persona } = useContext(PersonaContext)
  const theme = useTheme();
  // FIXME
  if (!persona?.representatives?.length) return null;
  return (
    <React.Fragment>
      <CMSTitleInfo title="Contacts/Representatives" />
      {persona.representatives.map((representative, i) => (
        <Box
          sx={{
            mb: 4,
            width: '100%',
            borderRadius: '1rem',
            [theme.breakpoints.down('sm')]: {
              borderRadius: '0rem',
            },
            bgcolor: 'background.paper',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            p: 3,
          }}
          key={i}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: theme.palette.blue.dark,
              width: 'fit-content',
              p: '0.3rem',
              px: '0.7rem',
              borderRadius: '0.5rem',
            }}
          >
            <Typography
              sx={{
                mr: 'auto',
                ml: 'auto',
                fontWeight: '700',
                color: theme.palette.blue.lighter,
                fontSize: '12px',
                textTransform: 'capitalize',
              }}
            >
              {representative.representativeType}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {representative.repName && (
              <Typography
                sx={{
                  mt: 1,
                  fontSize: '18px',
                  lineHeight: '26px',
                  fontWeight: '700',
                  textTransform: 'capitalize',
                }}
              >
                {representative.repName}
              </Typography>
            )}
            {representative.contactPhone && (
              <Typography
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mt: 1,
                  fontSize: '14px',
                  lineHeight: '26px',
                  fontWeight: '500',
                }}
              >
                <Box
                  component="img"
                  src="/images/icon/phone.svg"
                  sx={{ mr: 2, width: '15px', height: '15px' }}
                />{' '}
                {representative.contactPhone}
              </Typography>
            )}
            {representative.contactEmail && (
              <Typography
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mt: 1,
                  fontSize: '14px',
                  lineHeight: '26px',
                  fontWeight: '500',
                }}
              >
                <MailIcon
                  color={theme.palette.primary.hint}
                  sx={{
                    mr: 1,
                    width: '15px',
                    height: '15px',
                  }}
                />
                {representative.contactEmail}
              </Typography>
            )}
            {representative.contactNote && (
              <Typography
                sx={{
                  mt: 1,
                  fontSize: '14px',
                  lineHeight: '26px',
                  fontWeight: '400',
                }}
              >
                {representative.contactNote}
              </Typography>
            )}
          </Box>
        </Box>
      ))}
    </React.Fragment>
  );
};

export default Contacts;
