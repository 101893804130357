import React from 'react';
import { SvgIcon } from '@mui/material';

export default function Icon(props) {
  const { color } = props;
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <title>Gender</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="UI-Elements"
          transform="translate(-1076.000000, -460.000000)"
          fill={color || '#626466'}
        >
          <g id="gender" transform="translate(1076.000000, 460.000000)">
            <path
              d="M18,8 C18,10.834 16.022,13.21 13.376,13.834 C9.736,13.084 7,9.862 7,6 C7,5.482 7.051,4.978 7.146,4.488 C8.237,2.984 10.004,2 12,2 C15.309,2 18,4.691 18,8 M13.654,15.827 C13.712,15.814 13.767,15.796 13.824,15.783 C14.034,15.734 14.243,15.681 14.446,15.616 C14.488,15.602 14.528,15.584 14.57,15.57 C14.787,15.497 15.001,15.417 15.209,15.326 C15.233,15.315 15.257,15.302 15.281,15.291 C15.505,15.19 15.723,15.081 15.935,14.961 C15.944,14.955 15.954,14.948 15.964,14.942 C16.188,14.814 16.406,14.675 16.616,14.526 C18.661,13.077 20,10.697 20,8 C20,3.582 16.418,-1.0658141e-14 12,-1.0658141e-14 C9.725,-1.0658141e-14 7.676,0.954 6.22,2.479 C6.213,2.486 6.207,2.493 6.201,2.5 C5.94,2.774 5.7,3.066 5.48,3.375 C5.47,3.39 5.458,3.403 5.447,3.418 C5.235,3.721 5.046,4.039 4.877,4.37 C4.851,4.421 4.827,4.473 4.803,4.525 C4.652,4.835 4.519,5.155 4.409,5.486 C4.399,5.514 4.388,5.54 4.379,5.567 C4.27,5.911 4.188,6.266 4.126,6.627 C4.111,6.709 4.1,6.792 4.088,6.875 C4.036,7.244 4,7.617 4,8 L4,8 C4,12.096 7.081,15.468 11.051,15.938 L11.051,23.967 L12.984,23.967 L12.984,15.934 C12.998,15.932 13.012,15.929 13.025,15.927 C13.237,15.9 13.448,15.87 13.654,15.827"
              id="Fill-1"
            ></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
