const styles = (theme) => ({
  root: {
    marginBottom: theme.spacing(1),
    paddingLeft: '10px',
    paddingRight: '7px',
  },
  heading: {
    marginTop: '1.0rem',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1.0rem',
    justifyContent: 'space-between',
    width: '100%',
  },

  headingText: {
    margin: 0,
    display: 'flex',
    gap: '0.6rem',
    alignItems: 'center',
    textTransform: 'capitalize',
    color: theme.palette.primary.hint,
  },
  headingEye: {
    margin: 0,
    fontSize: '15px',
    display: 'block',
    height: '15px',
  },
  items: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '0.5rem',
  },
  itemFull: {
    display: 'flex',
    alignItems: 'stretch',
    textTransform: 'capitalize',
  },
  item: {
    padding: '4px 6px',
    border: 'solid 2px #00FFCC',
    backgroundColor: '#00FFCC30',
    borderRadius: '5px',
    color: '#181919',
    fontSize: '12px',
    marginTop: '5px',
  },
  itemOwned: {
    padding: '4px 4px 4px 6px',
    border: 'solid 2px #00FFCC',
    backgroundColor: '#00FFCC30',
    borderRadius: '5px',
    color: '#181919',
    fontSize: '12px',
    display: 'flex',
    gap: 5,
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '5px',
  },
  itemKey: {
    fontSize: 20,
    borderRadius: '50%',
    backgroundColor: '#313233',
    color: '#00FFCC',
    marginTop: '-1px',
    marginBottom: '-1px',
    padding: 3,
  },
  subHeadingRoot: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    color: '#959595',
  },
  subHeading: {
    margin: 0,
    color: '#d3d2d2',
    textTransform: 'uppercase',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
});

export default styles;
