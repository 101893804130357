const styles = (theme) => ({
  empty: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: '1rem',
    borderRadius: '18px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0',
    },
  },
  top: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0.5rem',
      marginRight: '1rem',
    },
  },
  items: {
    [theme.breakpoints.only('xs')]: {
      padding: '0rem 1rem',
    },
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'start',
    flexWrap: 'wrap',
    gap: '1rem',
  },
  buttonBed: {
    display: 'grid',
    placeItems: 'center',
  },
  buttonContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'start',
    gap: '1rem',
  },
  button: {
    minWidth: '44px',
    minHeight: '44px',
    borderRadius: '13px',
    backgroundColor: 'transparent',
    padding: '0',
    boxShadow: '0.67px 0.67px 4px 0 rgba(0,0,0,0.1)',
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: '0.67px 0.67px 4px 0 rgba(0,0,0,0.1)',
    },
  },
  icon: {
    width: '44px',
    '&:hover': {
      filter: 'brightness(70%)',
      WebkitFilter: 'brightness(80%)',
      transition: 'all 0.1s ease',
      WebkitTransition: 'all 0.1s ease',
    },
  },
  linkpanel: {
    backgroundColor: '#f6f8f9',
    borderRadius: '8px',
    padding: '16px',
    marginTop: '16px',
  },
});

export default styles;
