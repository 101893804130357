import React from 'react';
import { SvgIcon } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

function Icon({ hasGradient, sx, ...rest }) {
  const gradientId = uuidv4();
  return (
    <SvgIcon
      sx={[
        {
          ...sx,
        },
        hasGradient
          ? {
              '&': {
                g: {
                  fill: `url(#gradient-${gradientId})`,
                },
              },
            }
          : {
              '&': {
                g: {
                  fill: '',
                },
              },
            },
      ]}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      viewBox="0 0 24 24"
    >
      {hasGradient && (
        <defs>
          <linearGradient
            id={`gradient-${gradientId}`}
            gradientTransform="rotate(10)"
          >
            <stop offset="0%" stopColor="#FF2667" />
            <stop offset="51%" stopColor="#73AEE6" />
            <stop offset="100%" stopColor="#00FFCC" />
          </linearGradient>
        </defs>
      )}
      <g fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill={hasGradient ? null : '#626466'}>
          <path
            d="M15.709,17.661 L16.586,17.661 L17.18,17.015 L20.332,13.591 L15.797,20.994 L9.694,20.994 L7.652,17.661 L8.291,17.661 L15.709,17.661 Z M11.985,5.658 C12.503,5.658 13.014,5.795 13.482,6.065 C14.394,6.592 14.959,7.571 14.959,8.623 L14.959,11.57 L12.908,13.419 L9.829,10.644 L9.829,6.969 C9.829,6.829 9.852,6.694 9.863,6.557 C10.053,6.364 10.267,6.193 10.509,6.054 C10.957,5.795 11.468,5.658 11.985,5.658 L11.985,5.658 Z M24,11.433 L19.789,2.647 L21.862,2.938 L22.001,1.948 L19.271,1.564 L19,1 L18,1 L18.079,1.396 L8.138,5.32907052e-15 L7.998,0.99 L18.287,2.435 L20,11 L15.709,15.661 L12.91,15.661 L16.626,12.311 L16.626,8.623 C16.626,6.973 15.745,5.447 14.316,4.622 L14.295,4.61 C13.58,4.197 12.783,3.991 11.985,3.991 C11.188,3.991 10.39,4.197 9.675,4.61 L9.665,4.616 L9.664,4.616 L9.654,4.622 C8.225,5.447 7.344,6.973 7.344,8.623 L7.344,8.635 L7.344,12.311 L11.061,15.661 L8.291,15.661 L4,11 L6,1 L5.562,1 L5,1 L-4.05009359e-13,11.433 L7.694,23.994 L16.305,23.994 L18.143,20.994 L24,11.433 Z"
            id="Fill-1"
          ></path>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
