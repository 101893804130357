import { FormControl, MenuItem, Select, TextField } from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';
import styles from './styles';
import { Box } from '@mui/system';
import CPVisibleToggle from '../../CPVisibleToggle';
import CPDeleteButton from '../../CPDeleteButton';
import { repTypes } from '../repTypes';

const useStyles = makeStyles(styles);

const EditRepresentative = ({
  representativeType,
  repName,
  contactEmail,
  contactNote,
  contactPhone,
  handleChange,
  onDelete,
  isVisible,
  setVisible,
  areRepsHidden,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <form>
        <FormControl className={classes.topForm} size="small">
          <Box className={classes.topEdit}>
            <Select
              labelId={`rep-type`}
              id={`rep-type`}
              label="Representative Type"
              disableUnderline
              variant="filled"
              value={representativeType}
              onChange={(e) =>
                handleChange({ representativeType: e.target.value })
              }
              required
              className={classes.repTypeSelect}
            >
              {Object.keys(repTypes).map((key) => (
                <MenuItem value={key}>{repTypes[key].label}</MenuItem>
              ))}
            </Select>
            <Box sx={{ marginTop: '0.3rem' }}>
              <CPVisibleToggle
                isVisible={isVisible}
                setVisible={setVisible}
                isDisabled={areRepsHidden}
                darker={true}
                sx={{ marginTop: '8px', height: '2rem' }}
              />
            </Box>
            <CPDeleteButton onClick={onDelete} />
          </Box>
        </FormControl>
        <FormControl className={classes.form}>
          <TextField
            label="Name"
            variant="standard"
            fullWidth
            className={classes.editField}
            defaultValue={repName}
            required
            name="repName"
            onKeyDown={(e) => e.stopPropagation()} // fixes a dnd-kit bug
            onChange={(e) => handleChange({ repName: e.target.value })}
          />
          <TextField
            label="Email"
            variant="standard"
            fullWidth
            className={classes.editField}
            defaultValue={contactEmail}
            name="contactEmail"
            onKeyDown={(e) => e.stopPropagation()} // fixes a dnd-kit bug
            onChange={(e) => handleChange({ contactEmail: e.target.value })}
          />
          <TextField
            label="Phone Number"
            variant="standard"
            className={classes.editField}
            defaultValue={contactPhone}
            name="contactPhone"
            onKeyDown={(e) => e.stopPropagation()} // fixes a dnd-kit bug
            onChange={(e) => handleChange({ contactPhone: e.target.value })}
          />{' '}
          <TextField
            label="Description"
            variant="standard"
            className={classes.editField}
            defaultValue={contactNote}
            name="contactNote"
            onKeyDown={(e) => e.stopPropagation()} // fixes a dnd-kit bug
            onChange={(e) => handleChange({ contactNote: e.target.value })}
            multiline={true}
          />{' '}
        </FormControl>
      </form>
    </div>
  );
};

export default EditRepresentative;

// <Stack direction="row" justifyContent="space-between">
//   <TextField
//     variant="standard"
//     label={'Representative Name'}
//     type="text"
//     value={'not implemented'}
//     onChange={(e) => handleChange({ fieldName: e.target.value })}
//     placeholder={''}
//     sx={{
//       width: '100%'
//     }}
//   />
//
//   <Stack direction="row" spacing={1.5} alignItems="center">
//     <CPVisibleToggle
//       darker={true}
//       isVisible={isVisible}
//       setVisible={setVisible}
//       isDisabled={areRepsHidden}
//     />
//     <Box
//       sx={{
//         backgroundColor: 'white',
//         boxShadow: '2px 2px 6px 0px rgba(0,0,0,0.1)',
//         height: '35px',
//         width: '35px',
//         borderRadius: '20px',
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//         '&:hover': {
//           backgroundColor: '#D3D3D3'
//         }
//       }}
//       onClick={() => onDelete()}
//     >
//       <Delete
//         sx={{
//           fontSize: '40px',
//           cursor: 'pointer'
//         }}
//       />
//     </Box>
//   </Stack>
// </Stack>
//
// <TextField
//   type="text"
//   label={'Project Description'}
//   variant="standard"
//   multiline
//   value={'not implemented'}
//   onChange={(e) => handleChange({ value: e.target.value })}
//   placeholder={''}
//   sx={{
//     width: '100%',
//     mt: 3
//   }}
// />
