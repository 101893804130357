import React, { useContext, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Avatar, Box, Tooltip } from '@mui/material';
import { toast } from 'react-hot-toast';
import { BiImage } from 'react-icons/bi';
import styles from './styles';
import CPDeleteButton from '../../CMS/components/CPDeleteButton';
import CMSModal from '../../CMS/components/CMSModal';
import emptyAvatar from '../../images/emptyavatar.svg';
import { PersonaImageContext } from '../../v2api/PersonaImageContext/PersonaImageContext';
//import CheckMark from '../../icons/checkMark';

const MAX_UPLOAD = 8 * 1024 ** 2 - 1; // 8MB

const useStyles = makeStyles(styles);

const ProfileImage = ({
  alt,
  disableUpload,
  toolTipTitle,
  showTooltip,
  isVerified = false,
  isPublicPage = false,
}) => {
  const classes = useStyles();
  const [showImageDelete, setShowImageDelete] = useState(false);
  const { imgObjectURL, updateImage } = useContext(PersonaImageContext);

  if (isPublicPage) {
    // FIXME this is awful
    return (
      <div className={classes.avatarContainer}>
        <div className={classes.avatarWrapper}>
          <Box
            component="img"
            className={classes.avatarImage}
            src={imgObjectURL || emptyAvatar}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = emptyAvatar;
            }}
          />
        </div>
      </div>
    );
  }

  const handleOnChange = (e) => {
    const file = e.target.files[0];
    if (file.size > MAX_UPLOAD) {
      toast.error('Maximum of 8MB');
      return;
    }
    updateImage({ method: 'UPLOAD', payload: file });
  };

  const handleImageDelete = () => {
    updateImage({ method: 'DELETE' });
  };

  const mainImage = (
    <>
      <Box
        sx={[
          {
            '.input-helper': {
              display: 'none',
            },
            '&:hover': {
              '.input-helper': {
                display: 'flex',
              },
            },
            height: '155px',
            width: '165px',
          },
        ]}
        display={'flex'}
        flexDirection={'column'}
        className={classes.root}
      >
        {!disableUpload && !isPublicPage ? (
          <>
            <label htmlFor="profile-image" style={{ display: 'none' }}>
              Profile Photo
            </label>
            <input
              className={classes.inputField}
              id="profile-image"
              data-testid="picture"
              type="file"
              accept="image/*"
              onChange={handleOnChange}
              style={{
                cursor: 'pointer',
              }}
            />
          </>
        ) : null}
        {imgObjectURL ? (
          <Avatar
            src={imgObjectURL}
            alt={alt}
            className={classes.image}
            tabIndex="0"
            role="button"
          />
        ) : (
          <div className={classes.emptyImage}>
            <BiImage />
          </div>
        )}
        {/*TODO only show on hover and position relative to parent (top-right?)*/}
        {!disableUpload && !isPublicPage ? (
          <Box sx={{ display: 'flex', 'z-index': 20 }}>
            <CPDeleteButton onClick={() => setShowImageDelete(true)} />
          </Box>
        ) : null}
        {/*  {isVerified && (
          <Box className={classes.checkmark}>*/}
        {/* Change the Icon */}
        {/*<CheckMark sx={{ maxWidth: '13.66px', maxHeight: '13.33px' }} />
          </Box>
        )}*/}
        <CMSModal
          open={showImageDelete}
          onClose={() => setShowImageDelete(false)}
          onSave={() => {
            handleImageDelete();
            setShowImageDelete(false);
          }}
          altSaveText={'Confirm Delete'}
        >
          Are you sure you want to delete your profile photo?
        </CMSModal>
      </Box>
    </>
  );

  if (!showTooltip) return mainImage;
  else
    return (
      <Tooltip
        title={
          toolTipTitle
            ? toolTipTitle
            : 'Profile Image must be less than 2MB (recommended dimensions: at least 420px x 420px)'
        }
        classes={{ tooltip: classes.tooltip }}
      >
        {' '}
        {mainImage}{' '}
      </Tooltip>
    );
};

export default ProfileImage;
