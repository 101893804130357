import * as React from 'react';
import '../../styles/PublicPage.css';
import { useParams } from 'react-router-dom';
import PublicNavbar from './PublicNavbar';
import InfoSection from './InfoSection';
import ProfileInfo from './ProfileInfo';
import PublicPageStylingOverride from '../themes/publicPageStylingOverride';
import { Container } from '@mui/material';
import SignedInHeader from '../../components/SignedInHeader';
import { PersonaProvider } from '../../v2api/PersonaContext/PersonaContext.tsx';
import { useAuth } from 'react-oidc-context';

export default function PublicPage() {
  const auth = useAuth();
  const { personaId } = useParams();
  return (
    <PersonaProvider auth={null} activePersonaId={personaId}>
      <PublicPageStylingOverride>
        {auth.isAuthenticated ? <SignedInHeader /> : <PublicNavbar />}
        <Container>
          <InfoSection />
          <ProfileInfo />
        </Container>
      </PublicPageStylingOverride>
    </PersonaProvider>
  );
}
