import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import {
  Box,
  Stack,
  FormGroup,
  Typography,
  Link as MuiLink,
  Input,
} from '@mui/material';

import RefreshIcon from '@mui/icons-material/Refresh';
import AboutCreativePassport from '../modals/AboutCreativePassport.js';
import CPButton from '../../old/components/CPButton';
import Logo from '../../icons/cpLogoWithTextWhite';

const styles = (theme) => ({
  activeButtonStyle: {
    fontWeight: '500',
    color: 'rgba(49, 50, 51, 1)',
    backgroundColor: 'rgba(255,255,255, 1)',
    transition: '0.3s',
  },
  passiveButtonStyle: {
    transition: '0.3s',
    backgroundColor: 'transparent',
    color: '#fff',
    fontWeight: '400',
    '&:hover': {
      fontWeight: '500',
      color: 'rgba(49, 50, 51, 1)',
      backgroundColor: 'rgba(255,255,255, 1)',
    },
  },
  submitButton: {
    position: 'absolute',
    bottom: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255,255,255, 1)',
    width: 'calc(100% - 40px)',
    height: '40px',
    padding: '10px',
    borderRadius: '28px',
    boxShadow: '2px 2px 6px rgba(0,0,0,0.1)',
    '&:hover': {
      boxShadow: '0px 0px 6px rgba(0,0,0,0.1)',
    },
    transition: '0.2s',
    cursor: 'pointer',
    color: '#313233',
    fontWeight: '500',
    fontSize: '15px',
    textTransform: 'none',
  },
});

// @deprecate - this will be handled by keycloak
class PasswordResetPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Email: '',
      code: '',
      Password: '',
      confirm_password: '',
      passwordsMatch: false,
      codeSent: false,
      isSendingCode: false,
      isConfirming: false,
      confirmed: false,
      isAboutModalOpen: false,
    };

    this.handleSendCodeClick = this.handleSendCodeClick.bind(this);
    this.handleConfirmClick = this.handleConfirmClick.bind(this);
  }

  handleAboutModalOpen = () => {
    this.setState({ isAboutModalOpen: true });
  };

  handleAboutModalClose = () => {
    this.setState({ isAboutModalOpen: false });
  };

  componentDidMount() {
    this.setState({ passwordMatching: false });
  }

  async handleConfirmClick(event) {
    event.preventDefault();

    if (this.state.passwordsMatch) {
      this.setState({ isConfirming: true });

      try {
        console.warn('not implemented');
        // await Auth.forgotPasswordSubmit(
        //   this.state.email.toLowerCase(),
        //   this.state.code,
        //   this.state.password
        // );
        this.setState({ confirmed: true });
      } catch (error) {
        this.setState({
          isConfirming: false,
        });
      }
    }
  }

  async handleSendCodeClick(event) {
    event.preventDefault();

    this.setState({ isSendingCode: true });

    try {
      console.warn('not implemented');
      // await Auth.forgotPassword(this.state.email.toLowerCase());
      this.setState({ codeSent: true });
    } catch (error) {
      this.setState({ isSendingCode: false });
    }
  }

  renderConfirmationForm() {
    return (
      <form onSubmit={this.handleConfirmClick}>
        <Typography variant="h2">Reset your password</Typography>
        <FormGroup>
          <Input
            inputProps={{
              'data-testid': 'confirmation-code-input',
            }}
            autocomplete="one-time-code"
            sx={{ mt: '45px' }}
            placeholder="type the verificaiton code sent to your email…"
            id="confirmation-code"
            required
            fullWidth
            value={this.state.code}
            label="Confirmation Code"
            name="code"
            onChange={this.changeCode}
            margin="normal"
          />
        </FormGroup>
        <FormGroup>
          <Input
            inputProps={{
              'data-testid': 'new-password-input',
            }}
            sx={{ mt: '48px' }}
            id="password"
            required
            fullWidth
            value={this.state.password}
            placeholder="new password"
            label="New Password"
            name="password"
            type="password"
            onChange={this.changePassword}
            margin="normal"
          />
        </FormGroup>
        <FormGroup>
          <Input
            inputProps={{
              'data-testid': 'confirm-password-input',
            }}
            sx={{ mt: '48px' }}
            id="confirm-password"
            required
            fullWidth
            value={this.state.confirm_password}
            placeholder="confirm password"
            label="Confirm Password"
            name="password"
            type="password"
            onChange={this.confirmPassword}
            margin="normal"
          />
        </FormGroup>

        <CPButton
          sx={{
            position: 'absolute!important',
            bottom: '20px!important',
            display: 'flex!important',
            alignItems: 'center!important',
            justifyContent: 'center!important',
            backgroundColor: 'rgba(255,255,255, 1)!important',
            width: 'calc(100% - 40px)!important',
            height: '40px!important',
            padding: '10px!important',
            borderRadius: '28px!important',
            boxShadow: '2px 2px 6px rgba(0,0,0,0.1)!important',
            '&:hover': {
              boxShadow: '0px 0px 6px rgba(0,0,0,0.1)!important',
            },
            transition: '0.2s!important',
            cursor: 'pointer!important',
            color: '#313233!important',
            fontWeight: '500!important',
            fontSize: '15px!important',
            textTransform: 'none',
          }}
          type="submit"
          disabled={this.state.disabled || this.state.isLoading}
        >
          {this.state.isConfirming && <RefreshIcon className="spinning" />}
          Confirm
        </CPButton>
      </form>
    );
  }

  renderSuccessMessage() {
    return (
      <CPButton
        sx={{
          position: 'absolute!important',
          bottom: '20px!important',
          display: 'flex!important',
          alignItems: 'center!important',
          justifyContent: 'center!important',
          backgroundColor: 'rgba(255,255,255, 1)!important',
          width: 'calc(100% - 40px)!important',
          height: '40px!important',
          padding: '10px!important',
          borderRadius: '28px!important',
          boxShadow: '2px 2px 6px rgba(0,0,0,0.1)!important',
          '&:hover': {
            boxShadow: '0px 0px 6px rgba(0,0,0,0.1)!important',
          },
          transition: '0.2s!important',
          cursor: 'pointer!important',
          color: '#313233!important',
          fontWeight: '500!important',
          fontSize: '15px!important',
          textTransform: 'none',
        }}
        fullWidth
        onClick={(e) => this.props.history.push('/signin')}
      >
        Return To Login
      </CPButton>
    );
  }

  changeEmail = (event) => {
    this.setState({ email: event.target.value });
  };

  changeCode = (event) => {
    this.setState({ code: event.target.value });
  };

  changePassword = (event) => {
    this.setState({ password: event.target.value });
  };

  confirmPassword = (event) => {
    this.setState({ confirm_password: event.target.value });
    if (this.state.password === event.target.value) {
      this.setState({ passwordsMatch: true });
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <Box
        sx={{
          height: { xs: '', sm: '', md: '100vh', lg: '100vh' },
          minheight: { xs: '', sm: '', md: '768px', lg: '768px' },
          width: '100vw',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            height: { xs: '100%', sm: '100%', md: '100%', lg: '100%' },
            width: { xs: '100%', sm: '100%', md: '100%', lg: '100%' },
            maxHeight: { xs: '100%', sm: '100%', md: '100%', lg: '100%' },
            borderRadius: { xs: '0px', sm: '0px', md: '0px', lg: '0px' },
            padding: {
              xs: '0px',
              sm: '20px',
              md: '20px',
              lg: '20px',
            },
            backgroundImage: {
              xs: 'linear-gradient(328deg, rgba(255, 38, 103, 1) 0%, rgba(102, 154, 203, 1) 51%, rgba(0, 255, 204, 1) 100%)',
              m: 'linear-gradient(328deg, rgba(255, 38, 103, 1) 0%, rgba(102, 154, 203, 1) 51%, rgba(0, 255, 204, 1) 100%)',
              md: 'linear-gradient(-54deg, rgba(255, 38, 103, 1) 0%, rgba(102, 154, 203, 1) 51%, rgba(0, 255, 204, 1) 100%)',
              lg: 'linear-gradient(-54deg, rgba(255, 38, 103, 1) 0%, rgba(102, 154, 203, 1) 51%, rgba(0, 255, 204, 1) 100%)',
            },
            boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
            display: 'flex',
            flexDirection: {
              xs: 'column',
              sm: 'column',
              md: 'row',
              lg: 'row',
            },
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '15px',
            '& > div': {
              height: {
                xs: 'auto',
                sm: 'auto',
                md: '100%',
                lg: '100%',
              },
              width: {
                xs: '100%',
                sm: '512px',
                md: '100%',
                lg: '50%',
              },
            },
          }}
        >
          {/* Logo Section */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: {
                xs: '30%',
                sm: '30%',
                md: '100%',
                lg: '100%',
              },
            }}
          >
            <Logo
              sx={{
                fontSize: '180px',
                width: '256px',
                margin: {
                  xs: '32px',
                  sm: '32px',
                },
                height: 'auto',
              }}
            />
            <Typography
              marginTop={{ md: '70px', lg: '100px' }}
              marginBottom={{ md: '70px', lg: '100px' }}
              color="white"
              textAlign="center"
              variant="h2"
              display={{ xs: 'none', sm: 'none', md: 'block', lg: 'block' }}
            >
              Empowering the music <br /> makers community
            </Typography>
            {/* Footer Link Desktop */}
            <MuiLink
              sx={{
                transition: '0.3s',
                color: 'white',
                fontSize: '14px',
                textDecorationColor: 'rgba(255,255,255,0.5)!important',
                '&:hover': {
                  textDecorationColor: 'rgba(255,255,255,1)!important',
                },
                textDecoration: 'underline',
                display: { xs: 'none', sm: 'none', md: 'inline', lg: 'inline' },
              }}
              onClick={this.handleAboutModalOpen}
            >
              About the project
            </MuiLink>
            <AboutCreativePassport
              isOpen={this.state.isAboutModalOpen}
              onClose={this.handleAboutModalClose}
            />
          </Box>

          {/* Password Section */}
          <Box
            sx={{
              paddingLeft: {
                xs: '0px',
                sm: '0px',
                md: '0px',
                lg: '40px',
              },
              paddingRight: {
                xs: '0px',
                sm: '0px',
                md: '20px',
                lg: '40px',
              },
              height: {
                lg: '80%!important',
                md: '80%!important',
              },
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{
                mb: '16px',
                paddingX: {
                  xs: '15px',
                  sm: '0px',
                  md: '0px',
                  lg: '0px',
                },
                button: {
                  width: '48.6%',
                  height: '40px',
                  borderRadius: '13px',
                  cursor: 'pointer',
                  fontSize: {
                    xs: '15px',
                    sm: '15px',
                    md: '15px',
                    lg: '15px',
                  },
                },
              }}
            >
              <Box
                className={classes.passiveButtonStyle}
                component="button"
                type="button"
                onClick={() => {
                  this.props.history.push('/register');
                }}
              >
                Sign up
              </Box>
              <Box
                className={classes.activeButtonStyle}
                component="button"
                type="button"
                onClick={() => {
                  this.props.history.push('/signin');
                }}
              >
                Login
              </Box>
            </Stack>
            <Box
              container
              sx={{
                height: {
                  xs: '90vh',
                  sm: '60vh',
                  md: 'calc(100% - 45px)',
                  lg: 'calc(100% - 45px)',
                },
                backgroundColor: '#fff',
                borderRadius: { xs: '0px', sm: '13px', md: '13px', lg: '13px' },
                padding: '24px',
                boxShadow: '0px 0px 24px rgba(0,0,0, 0.1)',
                position: 'relative',
              }}
            >
              {!this.state.codeSent ? (
                <form
                  className={classes.inputForm}
                  onSubmit={this.handleSendCodeClick}
                >
                  <Typography sx={{ mb: '45px' }} variant="h2">
                    Reset your password
                  </Typography>
                  <Input
                    inputProps={{
                      'data-testid': 'email-input',
                    }}
                    placeholder="insert your email address"
                    type="email"
                    id="email"
                    required
                    fullWidth
                    value={this.state.email}
                    label="E-Mail"
                    name="email"
                    onChange={this.changeEmail}
                  />

                  <CPButton
                    fullWidth
                    type="submit"
                    className={classes.submitButton}
                    data-testid="reset"
                  >
                    Reset password
                  </CPButton>
                </form>
              ) : !this.state.confirmed ? (
                this.renderConfirmationForm()
              ) : (
                this.renderSuccessMessage()
              )}
              <div>{this.props.error}</div>
            </Box>
          </Box>
          {/* Footer Link Mobile */}
          <MuiLink
            sx={{
              display: {
                xs: 'flex',
                sm: 'flex',
                md: 'none',
                lg: 'none',
              },
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              height: '10vh !important',
              textDecorationColor: 'rgba(255,255,255,0.7)!important',
              '&:hover': {
                textDecorationColor: 'rgba(255,255,255,1)!important',
              },
              textDecoration: 'underline',
              color: 'white',
            }}
            href="https://www.creativepassport.net/"
            target="_blank"
            rel="noopener noreferrer"
          >
            More about the project
          </MuiLink>
        </Box>
      </Box>
    );
  }
}

PasswordResetPage.propTypes = {
  classes: PropTypes.object.isRequired,
  clearCache: PropTypes.func,
  error: PropTypes.string,
  email: PropTypes.string,
};

export default withStyles(styles)(PasswordResetPage);
