import {
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormHelperText,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import * as yup from 'yup';
import Button from '../Button';

const useStyles = makeStyles((theme) => ({
  code: {
    textAlign: 'center',
    width: '100%',
  },
  error: {
    color: 'red',
  },
  saveButton: {
    backgroundColor: theme.palette.background.paper,
    fontSize: '13px',
    [theme.breakpoints.down('sm')]: {
      padding: '0',
    },
  },
  cancelButton: {
    backgroundColor: theme.palette.background.dark,
    fontSize: '13px',
    [theme.breakpoints.down('sm')]: {
      padding: '0',
    },
  },
}));

function SessionCode({ onNext, onCancel }) {
  const classes = useStyles();
  const [state, setState] = useState({ sessionCode: '', terms: false });
  const [error, setError] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const handleChange = (event) => {
    setState((s) => ({ ...s, [event.target.name]: event.target.value }));
  };
  const handleChangeCheckbox = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const handleNext = (e) => {
    e.preventDefault();
    try {
      setError('');
      setSubmitting(true);
      console.warn('Not implemented');
      // authManager
      //   .getJwtToken()
      //   .then((jwtToken) => vevaValidateSession(state, jwtToken))
      //   .then(({ data: { valid, accessToken, sessionName } }) => {
      //     if (!valid) {
      //       setError({ sessionCode: 'Invalid session code' });
      //       return;
      //     }
      //     setSubmitting(false);
      //     onNext({ accessToken, sessionName });
      //   })
      //   .catch((e) => {
      //     setError({ sessionCode: 'Error validating session' });
      //     setSubmitting(false);
      //   });
    } catch (e) {
      if (e instanceof yup.ValidationError) {
        setError({ [e.path]: e.errors });
      }
    }
  };
  return (
    <form onSubmit={handleNext}>
      <DialogContent>
        <Typography>Enter your 6 digit VEVA Collect session code.</Typography>
        <TextField
          autoFocus
          fullWidth
          value={state.sessionCode}
          onChange={handleChange}
          name="sessionCode"
          variant="standard"
          label="Check-in code"
          helperText={error.sessionCode}
          error={Boolean(error.sessionCode)}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={state.terms}
              onChange={handleChangeCheckbox}
              name="terms"
              color="primary"
            />
          }
          label={
            <>
              I agree to the{' '}
              <Link
                href="https://www.vevacollect.com/legal/legal"
                target="_blank"
                rel="noopener noreferrer"
              >
                VEVA Collect Terms and Conditions
              </Link>
            </>
          }
        />
        {error.terms && <FormHelperText error>{error.terms}</FormHelperText>}
      </DialogContent>
      <DialogActions>
        <Button
          size="large"
          className={classes.cancelButton}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          size="large"
          className={classes.saveButton}
          type="submit"
          disabled={submitting}
        >
          Next
        </Button>
      </DialogActions>
    </form>
  );
}
export default SessionCode;
