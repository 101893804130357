import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import CMSTitleInfo from '../../../CMS/components/Title';
import { PersonaContext } from '../../../v2api/PersonaContext/PersonaContext';

const PublicProjects = () => {
  const { persona } = useContext(PersonaContext);
  if (!persona?.customProjects?.length) return null;
  return (
    <React.Fragment>
      <CMSTitleInfo title="Projects" />
      {persona.customProjects.map((customProject, i) => (
        <ProjectsComponent
          title={customProject.fieldName}
          value={customProject.value}
          key={i}
        />
      ))}
    </React.Fragment>
  );
};

const ProjectsComponent = ({ title, value }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        my: 2,
        width: '100%',
        borderRadius: '1rem',
        [theme.breakpoints.down('sm')]: {
          borderRadius: '0rem',
        },
        bgcolor: 'background.paper',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        p: 3,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: theme.palette.blue.dark,
          width: 'fit-content',
          p: '0.3rem',
          px: '0.7rem',
          borderRadius: '0.5rem',
        }}
      >
        <Typography
          sx={{
            mr: 'auto',
            ml: 'auto',
            fontWeight: '700',
            color: theme.palette.blue.lighter,
            fontSize: '12px',
            textTransform: 'uppercase',
          }}
        >
          {title}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
        <Typography mt={1} variant="body1">
          {value}
        </Typography>
      </Box>
    </Box>
  );
};

export default PublicProjects;
