import { useState } from 'react';
import { Dialog } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import SessionCode from './SessionCode';
import SessionDetails from './SessionDetails';
import Success from './Success';

const initialState = { form: 'SESSION_CODE' };

function VevaCheckInDialog({ open, onClose }) {
  const [state, setState] = useState(initialState);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const handleClose = () => {
    onClose();
  };

  const clearState = () => {
    setState(initialState);
  };

  const handleSessionCode = ({ accessToken, sessionName }) => {
    setState({ form: 'DETAILS', accessToken, sessionName });
  };

  const handleDetails = () => {
    setState({ form: 'SUCCESS' });
  };

  return (
    <Dialog
      fullScreen={isSmallScreen ? true : false}
      open={open}
      onClose={handleClose}
      TransitionProps={{
        onExited: clearState,
      }}
    >
      {(function () {
        switch (state.form) {
          case 'SESSION_CODE':
            return (
              <SessionCode onNext={handleSessionCode} onCancel={handleClose} />
            );
          case 'DETAILS':
            return (
              <SessionDetails
                sessionName={state.sessionName}
                accessToken={state.accessToken}
                onNext={handleDetails}
                onCancel={handleClose}
              />
            );
          case 'SUCCESS':
            return <Success onClose={handleClose} />;
          default:
            throw new Error('Invalid form');
        }
      })()}
    </Dialog>
  );
}

export default VevaCheckInDialog;
