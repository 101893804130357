import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import CMSTitleInfo from '../../../CMS/components/Title';
import { PersonaContext } from '../../../v2api/PersonaContext/PersonaContext';

const useStyles = makeStyles((theme) => ({
  idsBox: {
    margin: theme.spacing(2, 0),
    width: '100%',
    borderRadius: '1rem',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0rem',
    },
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(3),
  },
  idsTitleBox: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.blue.dark,
    width: 'fit-content',
    padding: '0.3rem 0.7rem',
    borderRadius: '0.5rem',
  },
  idsTitle: {
    margin: 'auto',
    fontWeight: '700',
    color: theme.palette.blue.lighter,
    fontSize: '12px',
    textTransform: 'uppercase',
  },
  idsValue: {
    marginTop: theme.spacing(1),
    fontSize: '18px',
    lineHeight: '26px',
    fontWeight: '700',
  },
}));

const PublicIds = () => {
  const { persona } = useContext(PersonaContext);
  const classes = useStyles();
  if (!persona) return null;
  if (
    !persona.unverifiedIpn &&
    !persona.unverifiedIsni &&
    !persona.unverifiedIpi &&
    !persona.customIds?.length
  )
    return null;
  return (
    <React.Fragment>
      <Box mb={4}>
        <CMSTitleInfo title="IDs" />
        {persona.unverifiedIpn && (
          <IdsComponent
            title="IPN"
            classes={classes}
            value={persona.unverifiedIpn}
          />
        )}
        {persona.unverifiedIsni && (
          <IdsComponent
            title="ISNI"
            classes={classes}
            value={persona.unverifiedIsni}
          />
        )}
        {persona.unverifiedIpi && (
          <IdsComponent
            title="IPI"
            classes={classes}
            value={persona.unverifiedIpi}
          />
        )}
        {persona.customIds &&
          persona.customIds.map((customId, i) => (
            <IdsComponent
              title={customId.fieldName}
              value={customId.value}
              key={i}
              classes={classes}
            />
          ))}
      </Box>
    </React.Fragment>
  );
};

const IdsComponent = ({ title, value, classes }) => {
  return (
    <Box className={classes.idsBox}>
      <Box className={classes.idsTitleBox}>
        <Typography className={classes.idsTitle}>{title}</Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
        <Typography className={classes.idsValue}>{value}</Typography>
      </Box>
    </Box>
  );
};

export default PublicIds;
