import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 24 24">
      <title>Learn More</title>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Atoms/UI-elements/tooltip/Default" fill="#5079A1">
          <path
            d="M12,0 L20.485,3.515 L24,12.001 L20.485,20.486 L12,24 L3.516,20.486 L0,12.001 L3.516,3.515 L12,0 Z M12,2.165 L6.054,4.628 L3,12.001 L6.054,19.372 L12,21.835 L18.954,18.955 L21.835,12.001 L18.954,5.045 L12,2.165 Z M13.0003,16.0003 L13.0003,18.0003 L11.0003,18.0003 L11.0003,16.0003 L13.0003,16.0003 Z M12.3165,5.0516 L15.3165,6.0516 L16.0005,7.0006 L16.0005,10.0006 L15.7075,10.7076 L13.0005,13.4146 L13.0005,15.0006 L11.0005,15.0006 L11.0005,13.0006 L11.2935,12.2936 L14.0005,9.5866 L14.0005,7.7206 L12.0005,7.0546 L10.0005,7.7206 L10.0005,10.0006 L8.0005,10.0006 L8.0005,7.0006 L8.6835,6.0516 L11.6835,5.0516 L12.3165,5.0516 Z"
            id="Combined-Shape"
          ></path>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
