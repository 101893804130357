import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  const { color } = props;
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 24 24">
      <title>Inspirations</title>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Atoms/icons/inspirations" fill={color || '#626466'}>
          <path
            d="M11.0002,0 C12.4822,0 13.8772,0.365 15.1112,1 L15.1112,1 L11.6532,2.033 C11.4382,2.013 11.2202,2 11.0002,2 C8.4842,2 6.2812,3.338 5.0472,5.336 C5.0302,5.557 5.0002,5.774 5.0002,6 L5.0002,6 L5.0002,14 L7.0002,14 L7.0002,21 L12.0002,21 L10.0002,24 L4.0002,24 L4.0002,17 L2.0002,17 L2.0002,9 C2.0002,8.631 2.0292,8.269 2.0722,7.911 C2.0842,7.808 2.1032,7.707 2.1192,7.604 C2.1602,7.339 2.2122,7.077 2.2772,6.82 C2.3002,6.726 2.3232,6.631 2.3492,6.538 C2.4442,6.205 2.5522,5.879 2.6832,5.563 C2.6842,5.561 2.6852,5.559 2.6862,5.556 C2.8282,5.213 2.9942,4.883 3.1762,4.563 C3.1932,4.532 3.2122,4.503 3.2302,4.473 C3.3992,4.184 3.5842,3.906 3.7832,3.64 C3.8052,3.609 3.8272,3.579 3.8502,3.549 C5.4952,1.396 8.0812,0 11.0002,0 Z M19,19.5 L19,21 L13,21 L13,19.5 L19,19.5 Z M18.4852,3.0003 L22.0002,6.5143 L22.0002,11.4853 L19.0212,14.4643 L19.0212,18.7503 L13.0212,18.7503 L13.0212,14.5073 L10.0002,11.4853 L10.0002,6.5143 L13.5152,3.0003 L18.4852,3.0003 Z M17.8642,4.5003 L14.1362,4.5003 L12.2502,6.3853 L12.2502,10.7353 L15.2712,13.7573 L15.2712,16.5003 L17.5212,16.5003 L17.5212,13.8423 L17.9612,13.4033 L20.5002,10.8643 L20.5002,7.1353 L17.8642,4.5003 Z"
            id="Combined-Shape"
          ></path>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
