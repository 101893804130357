import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 24 24">
      <title>Phone Contact</title>
      <g
        id="Symbols"
        stroke="none"
        stroke-width="1"
        fill="#626466"
        fill-rule="evenodd"
      >
        <g id="Atoms/icons/phone-contact/UI" fill="#626466">
          <path
            d="M4.885,1 L1,7.42 L5.715,18.326 L16.715,23 L23,19.261 L23,16.767 L22.683,16.453 L18.208,12.016 L15.6,12.379 L14.119,14.825 L11.215,12.873 L9.245,9.994 L11.913,8.407 L12.016,5.878 L7.098,1 L4.885,1 Z M6.797,15.79 L3.245,7.573 L6.012,3 L6.272,3 L9.982,6.677 L9.959,7.242 L8.223,8.275 L6.391,9.365 L7.595,11.122 L9.564,14.002 L9.781,14.318 L10.098,14.532 L13.003,16.484 L14.743,17.655 L15.83,15.861 L16.818,14.228 L17.503,14.133 L20.907,17.51 L16.715,20.004 L6.797,15.79 Z"
            id="Fill-1"
          ></path>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
