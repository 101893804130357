import React from "react";
import Contacts from "./SubComponents/Contacts";
import PublicIds from "./SubComponents/PublicIds";
import PublicProjects from './SubComponents/PublicProjects';

const ProfileSideBarContents = () => {
  return (
    <React.Fragment>
      {/* <RelatedProfile />*/}
      {/*  <Connections />*/}
      <PublicIds />
      <Contacts />
      <PublicProjects />
      {/* <Connections />*/}
    </React.Fragment>
  );
};

export default ProfileSideBarContents;
