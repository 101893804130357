import React from 'react';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Paper from '@mui/material/Paper';
import { Grid, Link } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Logo from '../../icons/cpLogoWithTextBlack';
import HeaderPassport from '../../icons/headerPassport';
import { useTheme } from '@mui/material/styles';

const styles = (theme) => ({
  appBar: {
    position: 'static',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 72,
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  header: {
    margin: '0',
    padding: '0',
    width: '100%',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 72,
    width: '100%',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '4rem',
      paddingRight: '4rem',
    },
  },
});

const PublicNavbar = ({ classes }) => {
  const theme = useTheme();
  return (
    <AppBar className={classes.appBar}>
      <Box className={classes.header}>
        <Grid container className={classes.toolbar}>
          <Grid
            item
            sx={{
              height: '37.55px',
            }}
          >
            <Logo
              color={theme.palette.primary.dark}
              sx={{
                width: { xs: 'inherit', sm: '1em' },
                marginRight: { xs: '4px', sm: '0px' },
                fontSize: '110px',
                height: '100%',
              }}
            />
          </Grid>
          <Grid item>
            <Paper
              elevation={2}
              sx={{
                backgroundColor: theme.palette.background.paper,
                borderRadius: 28,
                [theme.breakpoints.only('xs')]: {
                  borderRadius: 28,
                },
                fontSize: '14px',
                px: 3,
                py: 2,
                width: 'fit-content',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: '400',
                boxShadow: '0.67px 0.67px 4px 0 rgba(0,0,0,0.1)',
                maxWidth: '206px',
                maxHeight: '40px',
              }}
            >
              <Link
                href="/register"
                sx={{
                  color: theme.palette.primary.dark,
                  textDecoration: 'none',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <HeaderPassport
                  sx={{
                    height: '15px',
                    mb: '1px',
                    mr: '0.25rem',
                  }}
                />
                Get Your Passport
              </Link>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </AppBar>
  );
};

export default withStyles(styles)(PublicNavbar);
