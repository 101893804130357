const styles = (theme) => ({
  root: {
    gap: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      backgroundColor: '#f6f8f9',
      padding: '1rem 1rem 1rem 1rem',
      borderRadius: '1rem',
    },
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1rem',
  },
  topForm: {
    width: '100%',
    marginBottom: '20px',
    verticalAlign: 'middle',
    display: 'flex',
    alignItems: 'center',
  },
  form: {
    color: '#898c8e',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    gap: 10,
  },
  topEdit: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    gap: 15,
  },
  top: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '1rem',
    gap: '0.5rem',
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0',
    },
    paddingLeft: '24px',
  },
  infoName: {
    textDecoration: 'underline',
    margin: 0,
    fontWeight: '700',
  },
  infoContact: {
    alignItems: 'center',
    color: '#949494',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  infoContactSelf: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.75),
    overflowWrap: 'break-word',
    fontWeight: '500',
  },
  paragraph: {
    fontSize: '13px',
    overflowWrap: 'break-word',
  },
  infoPara: {
    color: '#7e7e7e',
    margin: 0,
  },
  editField: {
    marginBottom: '0.5rem',
    width: '100%',
    fontWeight: 'bold',
    color: '#77777',
  },
  repTypeSelect: {
    width: '100%',
    height: '1rem',
    padding: '8 auto',
    fontSize: 14,
    backgroundColor: 'transparent',
    '& .MuiFilledInput-input': {
      height: '1rem',
      verticalAlign: 'middle',
      padding: 8,
      paddingLeft: 16,
    },
  },
  optionalButton: {
    marginBottom: '0.5rem',
    height: '2.5rem',
  },
  repTypeReadOnly: {
    margin: theme.spacing(1),
  },
  icon: {
    margin: `auto ${theme.spacing(1)}`,
  },
});
export default styles;
