import { Stack, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import Delete from '../../../icons/atomsIconsDelete';
import Tooltip from '../../CPHelpMessage';
import CPVisibleToggle from '../../CPVisibleToggle';

export const EditStandardID = ({
  hardcodedLabel,
  helpText,
  value,
  setValue,
  isVisible,
  setVisible,
  areIdsHidden,
}) => {
  return (
    <Box
      sx={{
        backgroundColor: '#F7F8F9',
        padding: 2,
        borderRadius: 2,
      }}
    >
      <Stack alignItems="start" direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={0.7} alignItems="center">
          <Typography
            sx={{
              color: '#626466',
              fontWeight: 'bold',
              textTransform: 'uppercase',
            }}
          >
            {hardcodedLabel}
          </Typography>
          <Tooltip
            message={helpText}
            sx={{ paddingTop: '5px', marginLeft: '5px' }}
          />
        </Stack>

        <Stack direction="row" spacing={1.5} alignItems="center">
          <CPVisibleToggle
            darker={true}
            isVisible={isVisible}
            setVisible={setVisible}
            isDisabled={areIdsHidden}
          />
          <Box
            sx={{
              backgroundColor: 'white',
              boxShadow: '2px 2px 6px 0px rgba(0,0,0,0.1)',
              height: '35px',
              width: '35px',
              borderRadius: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#D3D3D3',
              },
            }}
            onClick={() => setValue(null)} // how we delete via patches
          >
            <Delete
              sx={{
                fontSize: '40px',
              }}
            />
          </Box>
        </Stack>
      </Stack>

      <TextField
        type="text"
        variant="standard"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder={'E.g 0000 0000 0000 0000'}
        sx={{
          width: '100%',
          mt: 3,
        }}
        disabled={false}
      />
    </Box>
  );
};
