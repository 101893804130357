import React from 'react';
import { makeStyles } from '@mui/styles';
import { Card, Grid, Avatar, Button } from '@mui/material';
import IntegratePlug from '../../icons/integratePlug';
import styles from './styles';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles(styles);

const IntegrationCard = ({
  title,
  description,
  integrated,
  image,
  handleIntegrationOpen,
  handleFieldSharing,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Grid item xs={12} sm={6} md={6}>
      <Card elevation={0} className={classes.root}>
        <div className={classes.header}>
          <Avatar
            src={image}
            sx={{
              img: {
                height: 'auto',
                width: '100%',
              },
            }}
            className={classes.integrationLogo}
          />
          <Typography variant="h4">{title}</Typography>
        </div>
        <p className={classes.description}>{description}</p>

        <div className={classes.bottom}>
          <div className={classes.bottomFlex}>
            <Button
              className={classes.buttonFill}
              onClick={() => handleIntegrationOpen(title)}
            >
              <IntegratePlug
                color={theme.palette.primary.hint}
                sx={{ maxWidth: '13.66px', maxHeight: '13.33px' }}
              />
              <span>Integrate</span>
            </Button>
          </div>
        </div>
      </Card>
    </Grid>
  );
};

export default IntegrationCard;
