import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
    >
      <title>atoms/icons/users</title>
      <defs>
        <linearGradient
          x1="78.125%"
          y1="100%"
          x2="21.9264264%"
          y2="0.0914246886%"
          id="linearGradient-1"
        >
          <stop stop-color="#FF2667" offset="0.0914246886%"></stop>
          <stop stop-color="#73AEE6" offset="50.9814282%"></stop>
          <stop stop-color="#00FFCC" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Profile-|-Basic---UI"
          transform="translate(-444.000000, -28.000000)"
          fill="url(#linearGradient-1)"
        >
          <g
            id="atoms/icons/users"
            transform="translate(444.000000, 28.000000)"
          >
            <path
              d="M15,0 L13,2 L13,7 L11,7 L11,2 L9.001,0 L8.001,0 L6,2 L6,7 L3,7 L3,15.728 L8.273,21.001 L11,21.001 L11,24 L13,24 L13,21.001 L15.728,21.001 L21,15.728 L21,7 L18.001,7 L18.001,2 L16,0 L15,0 Z M15,7 L16,7 L16,2 L15,2 L15,7 Z M8.001,7 L9.001,7 L9.001,2 L8.001,2 L8.001,7 Z M4,8 L20,8 L20,15.314 L19.001,16.314 L19.001,9 L4,9 L4,8 Z"
              id="Combined-Shape"
            ></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
