import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Box } from '@mui/material';
import { toast } from 'react-hot-toast';
import { BiLoaderAlt } from 'react-icons/bi';
import styles from './styles';
import Button from '@mui/material/Button';
import { BsUpload } from 'react-icons/bs';

const useStyles = makeStyles(styles);

const ImageUpload = ({ src, alt, handleImageChange, isBusy }) => {
  const classes = useStyles();
  const [imageSrc, setImageSrc] = useState(src);
  const imageUploadElement = useRef(null);

  useEffect(() => {
    if (src && !imageSrc) {
      setImageSrc(src);
    }
  }, [src, isBusy, imageSrc]);

  const handleOnChange = (e) => {
    const file = e.target.files[0];
    if (file.size > 5242880) {
      toast.error('Maximum of 5MB');
      return;
    }
    setImageSrc(URL.createObjectURL(file));
    if (handleImageChange) {
      handleImageChange(file);
    }
  };

  if (isBusy) return <BiLoaderAlt className={classes.inputLoader} />;

  return (
    <Box
      sx={{
        '.input-helper': {
          display: 'none',
        },
        '&:hover': {
          '.input-helper': {
            display: 'flex',
          },
        },
      }}
      display={'flex'}
      flexDirection={'column'}
    >
      <label>
        <input
          className={classes.inputField}
          id="background-image-input"
          ref={imageUploadElement}
          data-testid="picture"
          type="file"
          accept="image/*"
          onChange={handleOnChange}
          style={{ display: 'none' }}
        />
        <Grid container spacing={3}>
          <Grid item xs={6}>
            {imageSrc ? (
              <img
                src={imageSrc}
                alt={alt}
                style={{
                  borderRadius: '0.5rem',
                  width: '100%',
                  height: '150px',
                  objectFit: 'cover',
                }}
              />
            ) : (
              <Box
                sx={{
                  borderRadius: '0.5rem',
                  backgroundColor: '#ebebeb',
                  width: '100%',
                  height: '150px',
                }}
              ></Box>
            )}
          </Grid>
          <Grid item xs={6}>
            <Button
              startIcon={<BsUpload />}
              sx={{ px: 2, width: '100%' }}
              onClick={() => imageUploadElement.current.click()}
            >
              Choose an image
            </Button>
            <Box mx={2} mt={2}>
              <ul>
                <li>File size should not be larger than 5mb.</li>
                <li>Use at least 1920*1080px resolution for best results.</li>
              </ul>
            </Box>
          </Grid>
        </Grid>
      </label>
    </Box>
  );
};

export default ImageUpload;
