import React from 'react';
import { SvgIcon } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

function Icon({ hasGradient, sx, ...rest }) {
  const gradientId = uuidv4();
  return (
    <SvgIcon
      sx={[
        {
          ...sx,
        },
        hasGradient
          ? {
              '&': {
                g: {
                  fill: `url(#gradient-${gradientId})`,
                },
              },
            }
          : {
              '&': {
                g: {
                  fill: '',
                },
              },
            },
      ]}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      viewBox="0 0 24 24"
    >
      {hasGradient && (
        <defs>
          <linearGradient
            id={`gradient-${gradientId}`}
            gradientTransform="rotate(10)"
          >
            <stop offset="0%" stopColor="#FF2667" />
            <stop offset="51%" stopColor="#73AEE6" />
            <stop offset="100%" stopColor="#00FFCC" />
          </linearGradient>
        </defs>
      )}
      <title>Settings</title>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Atoms/icons/settings/UI" fill="#626466">
          <path
            d="M12.893,0 L14.892,2 L14.892,3.732 L17.552,5.269 L19.052,4.402 L21.785,5.135 L22.785,6.866 L22.052,9.599 L20.553,10.464 L20.553,13.536 L22.052,14.402 L22.785,17.134 L21.785,18.867 L19.052,19.598 L17.566,18.74 L14.897,20.266 L14.892,20.268 L14.892,22.001 L12.893,24 L10.892,24 L8.892,22.001 L8.891,20.267 L6.224,18.737 L4.732,19.598 L2,18.867 L1,17.134 L1.732,14.402 L3.232,13.536 L3.232,10.464 L1.732,9.599 L1,6.866 L2,5.135 L4.732,4.402 L6.232,5.269 L6.239,5.264 L8.892,3.726 L8.892,2 L10.892,0 L12.893,0 Z M12.064,2 L11.722,2 L10.892,2.829 L10.892,4.887 L7.215,7.01 L6.234,7.579 L5.415,7.105 L4.45,6.548 L3.318,6.852 L3.232,7 L3.147,7.149 L3.45,8.281 L4.232,8.732 L5.232,9.31 L6.232,9.887 L6.232,14.114 L5.232,14.691 L4.232,15.269 L3.588,15.64 L4.539,17.285 L6.402,16.209 L10.892,18.851 L10.892,20.994 L12.893,20.994 L12.893,19.108 L13.888,18.539 L17.552,16.423 L18.203,16.799 L18.566,17.007 L19.336,17.452 L20.467,17.15 L20.638,16.852 L20.336,15.72 L18.553,14.691 L18.553,9.31 L19.553,8.732 L20.336,8.281 L20.638,7.149 L20.553,7 L20.466,6.852 L19.336,6.548 L19.123,6.671 L18.553,7 L17.552,7.578 L12.893,4.887 L12.893,2.829 L12.064,2 Z M11.8922,7 L16.2222,9.5 L16.2222,14.5 L11.8922,17 L7.5622,14.5 L7.5622,9.5 L11.8922,7 Z M11.8922,9.31 L9.5622,10.655 L9.5622,13.345 L11.8922,14.691 L14.2222,13.345 L14.2222,10.655 L11.8922,9.31 Z"
            id="Combined-Shape"
          ></path>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
