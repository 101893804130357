import * as React from 'react';
import { createContext, ReactNode, useCallback, useEffect, useReducer, useState } from 'react';
import { AuthState } from 'react-oidc-context';
import toast from 'react-hot-toast';
import { Action as PassportAction, IPersonaSummaryPatch } from '../PassportContext/types';
import { PersonaAccessProvider } from '../PersonaAccessContext/PersonaAccessContext.tsx';
import { Action, IPersona, PersonaContextType } from './types';
import { getPersona, patchPersona } from './http';
import { PersonaImageProvider } from '../PersonaImageContext/PersonaImageContext.tsx';


const initialState: IPersona | null = null;

export const PersonaContext = createContext<PersonaContextType | null>(null);

// Reducers for React context state "mirrored" state.
function reducer(state: IPersona | null, action: Action): IPersona | null {
  switch (action.method) {
    case 'LOAD':
      return action.payload;
    case 'PATCH':
      return { ...state, ...action.payload } as IPersona;
    default:
      console.warn(`Unhandled persona state action: <${JSON.stringify(action)}>`);
      return state;
  }
}

interface PersonaProviderProps {
  auth: AuthState | null;
  children: ReactNode;
  activePersonaId: string | null;
  personaJustAdded?: boolean;
  updatePassport?: (action: PassportAction) => void;
}

export const PersonaProvider: React.FC<PersonaProviderProps> = ({
                                                                  auth,
                                                                  children,
                                                                  activePersonaId,
                                                                  personaJustAdded,
                                                                  updatePassport
                                                                }) => {
  const [persona, dispatch] = useReducer(reducer, initialState);
  const [currentAction, setCurrentAction] = useState<Action | null>(null);

  const [isBusy, setIsBusy] = useState(false);


  /* All callback chains must eventually `setIsBusy(false)` !! */
  /* See PassportProvider for full docs */
  useEffect(() => {
    if (currentAction === null) return;
    const oldState = { ...persona };
    const asyncEffect = async (action: Action, token: string) => {
      if (isBusy) return console.warn(`persona action in progress, ${action.method} REJECTED`);
      setIsBusy(true);
      switch (action.method) {
        case 'SERVERLOAD':
          return await getPersona(token, action.payload).then(data => {
            dispatch({ method: 'LOAD', payload: data });
            setIsBusy(false);
          });
        case 'LOAD':
          setIsBusy(false);
          return dispatch(action);
        case 'PATCH':
          dispatch(action);
          return await patchPersona(token, activePersonaId, action.payload).then(patchSuccess => {
            if (patchSuccess) {
              const summaryPatch: IPersonaSummaryPatch = { personaId: activePersonaId };
              if ('artistName' in action.payload) summaryPatch['artistName'] = action.payload['artistName'];
              if ('personaType' in action.payload) summaryPatch['personaType'] = action.payload['personaType'];
              if (Object.keys(summaryPatch).length > 1) {
                updatePassport({ method: 'PATCH_SUMMARY', payload: summaryPatch });
              }
              toast.success('Profile updated successfully!');
            } else {
              toast.error(`Update failed`);
              setTimeout(() => setCurrentAction({ method: 'LOAD', payload: oldState }), 1000);
            }
            setIsBusy(false);
          }).catch(err => {
            console.warn(err);
            setTimeout(() => setCurrentAction({ method: 'LOAD', payload: oldState }), 1000);
            setIsBusy(false);
          });
        default:
          console.warn(`Unknown action on persona: ${action}`);
          setIsBusy(false);
      }
    };
    // Where we actually start the async execution
    const token = auth?.user?.access_token || '';
    if (token || auth === null) {
      asyncEffect(currentAction, token).catch((err: any) => {
        console.warn(err);
        setTimeout(() => setCurrentAction({ method: 'LOAD', payload: oldState }), 1000);
        setCurrentAction(null);
        setIsBusy(false);
      });
    } else {
      console.warn('Auth manager did not provide an access token');
      setIsBusy(false);
    }
    return () => setCurrentAction(null);
  }, [currentAction, persona, activePersonaId, updatePassport, isBusy]);

  useEffect(() => {
    if (activePersonaId) {
      setCurrentAction({ method: 'SERVERLOAD', payload: activePersonaId });
    }
  }, [activePersonaId]);

  const updatePersona = useCallback((action: Action) => {
    setCurrentAction(action);
  }, []);

  const isNew = personaJustAdded;

  if (auth === null) {
    return (
      <PersonaContext.Provider value={{ persona }}>
        <PersonaImageProvider auth={null} activePersonaId={activePersonaId}>
        {children}
        </PersonaImageProvider>
      </PersonaContext.Provider>
    );
  }

  return (
    <PersonaContext.Provider value={{ persona, updatePersona, isNew }}>
      <PersonaAccessProvider>
        <PersonaImageProvider auth={auth} activePersonaId={activePersonaId}>
          {children}
        </PersonaImageProvider>
      </PersonaAccessProvider>
    </PersonaContext.Provider>
  );
};
