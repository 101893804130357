const styles = (theme) => ({
  body: {
    display: 'flex',
    alignItems: 'top',
    gap: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  bodyInfo: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    gap: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  cancelButton: {
    backgroundColor: theme.palette.background.dark,
    fontSize: '13px',
    [theme.breakpoints.down('sm')]: {
      padding: '0',
    },
  },
  editPersonalSummary: {
    width: '620px',
    '& .MuiPaper-root': {
      maxWidth: '720px',
    },
    '& .MuiDialog-paper': {
      maxWidth: '720px',
    },
  },
  form: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0rem',
      paddingRight: '0rem',
    },
  },
  location: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
  },
  locationTitle: {
    textTransform: 'capitalize',
    display: 'flex',
    alignItems: 'center',
    color: '#181919',
    gap: 10,
    fontSize: '14px',
  },
  name: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: 20,
      color: '#313233',
    },
    width: '90%',
  },
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    fontSize: '15px',
    backgroundColor: '#FFFFFF',
    borderRadius: '1rem',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0',
    },
  },
  save: {
    boxShadow: '0px -15px 20px rgba(255, 255, 255, 1)',
    zIndex: '1',
    marginTop: 'auto',
    backgroundColor: theme.palette.background.dark,
    [theme.breakpoints.down('sm')]: {
      marginRight: '0.5rem',
    },
  },
  saveButton: {
    backgroundColor: theme.palette.background.paper,
    paddingLeft: '4px',
    fontSize: 12,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    gap: 6,
    [theme.breakpoints.down('sm')]: {
      padding: '0',
    },
  },
  talent: {
    fontSize: '20px',
    color: '#626466',
    fontWeight: 500,
    fontFamily: 'Roboto, sans-serif',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      margin: 0,
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
    },
  },
  talents: {
    display: 'flex',
    textTransform: 'capitalize',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontWeight: 500,
      fontSize: 16,
      gap: 4,
      textAlign: 'center',
    },
  },
  topInfo: {
    position: 'absolute',
    right: 0,
    top: 0,
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    color: '#777777',
    zIndex: 2,
  },

  autocomplete: {
    position: 'absolute',
    zIndex: 6,
    top: '100%',
    left: 0,
    width: theme.spacing(30),
    maxHeight: theme.spacing(30),
    overflowY: 'scroll',
    border: '1px solid #d3d3d2',
    boxShadow: '0px 5px 10px rgba(0,0,0,0.2)',
    backgroundColor: 'white',
  },
  autocompleteItem: {
    display: 'block',
    padding: '8px 6px',
    cursor: 'pointer',
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: '#02d1a8',
      color: 'white',
    },
  },
  displayAlias: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  eachAlias: {
    borderRadius: '8px',
    padding: '1px 10px 1px 1px',
    cursor: 'pointer',
    width: '180px',
    height: '40px',
    '&:checked': {
      backgroundColor: 'red',
    },
  },
  editArtistProject: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'top',
    gap: '1rem',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  editField: {
    margin: theme.spacing(1),
    width: '100%',
  },
  emptyPlaceholder: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  emptyPlaceholderSVG: {
    fontSize: '25px',
    color: '#d3d2d2',
  },
  emptyPlaceholderText: {
    margin: 0,
    width: '90%',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  eye: {
    fontSize: '20px',
    color: '#d5d5d4',
    height: '20px',
    width: '20px',
    cursor: 'pointer',
  },
  eyeActive: {
    fontSize: '20px',
    backgroundColor: '#dadbdb',
    height: '20px',
    width: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '16px',
    cursor: 'pointer',
  },
  eyes: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  features: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    marginTop: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '4rem',
      marginRight: '2rem',
    },
  },
  firstName: {
    width: '50%',
    display: 'inline-block',
    textAlign: 'left',
  },
  gender: {
    width: '100%',
  },
  info: {
    display: 'flex',
    alignItems: 'start',
    gap: theme.spacing(1),
    padding: theme.spacing(1),
  },
  infoLink: {
    color: '#454444',
    textDecoration: 'underline',
  },
  infoSVG: {
    fontSize: '24px',
    color: '#d2d3d4',
  },
  infoText: {
    color: '#a09fa0',
    margin: 0,
    fontSize: '12px',
  },
  inputField: {
    width: '100%',
  },
  loc: {
    padding: '4px 6px',
    border: 'solid 2px #00FFCC',
    backgroundColor: '#00FFCC30',
    borderRadius: '5px',
    color: '#181919',
    fontSize: '14px',
    display: 'flex',
    gap: 5,
    alignItems: 'center',
  },
  locationEye: {
    color: '#d3d3d3',
    cursor: 'pointer',
    fontSize: '18px',
    height: '19px',
  },
  locationEyes: {
    width: '15%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '16px',
  },
  locationInput: {
    width: '85%',
    borderBottom: '1px solid #d3d3d2',
    padding: theme.spacing(0.5),
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  locationTyping: {
    position: 'relative',
    minWidth: theme.spacing(1),
  },
  locationTypingInput: {
    border: 'none',
    outline: 'none',
    width: 'fit-content',
    maxWidth: theme.spacing(12),
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    width: '50vw',
    maxHeight: '90vh',
    overflowY: 'scroll',
    '&:focus-visible': {
      outline: 'none',
    },
  },
  modalForm: {
    width: 'calc(100% - 180px)',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  modalImage: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  musicRoles: {
    width: '100%',
  },
  myLabel: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontSize: '12px',
    fontFamily: 'Heebo, sans-serif',
    color: '#626466',
    justifyContent: 'space-between',
    width: '100%',
  },
  nameField: {
    width: '200px',
  },
  pronouns: {
    width: '100%',
  },
  radio: {
    paddingRight: '8px',
    margin: '0.25rem',
    borderRadius: '12px',
    '&:hover': {
      backgroundColor: '#F7F8F9',
    },
  },
  radioSelected: {
    backgroundColor: '#F7F8F9',
    paddingRight: '8px',
    margin: '0.25rem',
    borderRadius: '12px',
  },
  role: {
    fontFamily: 'Roboto, sans-serif',
    padding: '4px 6px',
    border: 'solid 2px #00FFCC',
    backgroundColor: '#00FFCC30',
    borderRadius: '5px',
    color: '#313233',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
  rolesInput: {
    padding: theme.spacing(0),
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  text: {
    fontSize: '12px',
    color: '#313233',
    marginBottom: theme.spacing(2),
  },
  types: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: '1px solid #00000010',
    borderRadius: '8px',
    /*transition: '0.2s',
    '&:hover': {
      border: '1px solid #949494',
    },*/
    padding: '0.25rem',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '-rem',
      alignItems: 'center',
    },
  },

  withPadding: {
    padding: theme.spacing(3),
  },
});

export default styles;
