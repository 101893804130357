import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  const { color } = props;
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 24 24">
      <title>Endorsements</title>
      <g
        id="PWA---UI"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="UI-Elements"
          transform="translate(-692.000000, -399.000000)"
          fill={color || '#626466'}
        >
          <g id="Combined-Shape" transform="translate(692.000000, 399.000000)">
            <path d="M12,0 L15.679,7.838 L14.168,9.327 L13.898,8.751 L12,4.707 L10.103,8.751 L9.649,9.716 L8.594,9.878 L5.178,10.4 L9,14.316 L8.112,19.765 L11.033,18.149 L12,17.616 L12.968,18.149 L16.78,20.257 L16.027,15.639 L15.864,14.643 L16.568,13.92 L21.569,8.797 L24.001,9.167 L18,15.317 L19.417,24 L12,19.901 L7.702,22.277 L4.584,24 L6.001,15.317 L0,9.167 L8.292,7.901 L12,0 Z M19.2686,6.2934 L20.6826,7.7074 L14.3186,14.0714 L10.7836,10.5364 L12.1976,9.1224 L14.3186,11.2434 L19.2686,6.2934 Z"></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
