import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
  Box,
  Grid,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useLoadScript from '../../../old/hooks/useLoadScript';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'space-between',
    display: 'flex',
    backgroundColor: '#FFF',
    padding: '1.5rem',
    borderRadius: '1rem',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0rem',
      width: '100%',
    },
    width: `calc(311px + 3rem)`,
  },
  yoti: {
    height: 42,
    width: 311,
    margin: 'auto',
    backgroundColor: '#2875bc',
    borderRadius: '0.5rem',
  },
  dataBox: {
    backgroundColor: theme.palette.background.dark,
    borderRadius: '0.5rem',
    // padding: '0.66rem 1rem',
    padding: '1rem',
  },
  checkBox: {
    '&.MuiCheckbox-root': {
      padding: '0px',
      marginRight: '0.5rem',
    },
  },
}));

const VerifyWithYoti = () => {
  const classes = useStyles();
  // const authManager = useAuthManager();
  const [attributes, setAttributes] = useState({
    full_name: true,
    date_of_birth: true,
    nationality: true,
    gender: true,
  });

  // FIXME
  let yotiVerified = false;

  let yotiPhrase = 'Verify selected data with YOTI';
  if (yotiVerified) {
    yotiPhrase = 'Re-verify selected data with YOTI';
  }

  const attributesRef = useRef(attributes);
  useEffect(() => {
    attributesRef.current = attributes;
  }, [attributes]);

  const getShareUrl = useCallback(async () => {
    // FIXME
    // const jwtToken = await authManager.getJwtToken();
    let attributeArray = [];
    for (const attr of Object.keys(attributesRef.current)) {
      if (attributesRef.current[attr]) {
        attributeArray.push(attr);
      }
    }
    // const { data } = await getYotiShareUrl(jwtToken, attributeArray);
    console.warn('Not implemented');
    return ''; // data.shareUrl;
  }, []); // [authManager]);

  useLoadScript('yoti', 'https://www.yoti.com/share/client', () => {
    const yotiButton = window.Yoti.Share.init({
      elements: [
        {
          domId: 'yoti',
          shareUrlProvider: getShareUrl,
          clientSdkId: process.env.REACT_APP_YOTI_SDK_ID,
          button: {
            label: yotiPhrase,
            title: 'Scan with the Yoti app',
            width: 'full',
          },
        },
      ],
    });
    return () => {
      yotiButton.destroy();
    };
  });

  const handleChange = (e) => {
    setAttributes((a) => ({ ...a, [e.target.name]: e.target.checked }));
  };

  const [verifiedData, setVerifiedData] = useState(null);

  return (
    <Box flexDirection="column" className={classes.root}>
      <Box>
        <Box>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormGroup>
              <Grid container mb={2} gap={2}>
                <Grid item xs={12} className={classes.dataBox}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={attributes.full_name}
                        name="full_name"
                        disabled
                        className={classes.checkBox}
                      />
                    }
                    label={<Typography variant="h4">Full name</Typography>}
                  />
                  {verifiedData &&
                  verifiedData.yoti &&
                  verifiedData.yoti.fullName ? (
                    <Typography variant="body1" mt={1}>
                      {verifiedData.yoti.fullName.value}
                    </Typography>
                  ) : (
                    <Typography variant="body1" mt={1}>
                      Not verified
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} className={classes.dataBox}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={attributes.date_of_birth}
                        name="date_of_birth"
                        disabled
                        className={classes.checkBox}
                      />
                    }
                    label={<Typography variant="h4">Date of birth</Typography>}
                  />
                  {verifiedData &&
                  verifiedData.yoti &&
                  verifiedData.yoti.dateOfBirth ? (
                    <Typography variant="body1" mt={1}>
                      {verifiedData.yoti.dateOfBirth.value}
                    </Typography>
                  ) : (
                    <Typography variant="body1" mt={1}>
                      Not verified
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} className={classes.dataBox}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={attributes.nationality}
                        onChange={handleChange}
                        name="nationality"
                        className={classes.checkBox}
                      />
                    }
                    label={<Typography variant="h4">Nationality</Typography>}
                  />
                  {verifiedData &&
                  verifiedData.yoti &&
                  verifiedData.yoti.nationality ? (
                    <Typography variant="body1" mt={1}>
                      {verifiedData.yoti.nationality.value}
                    </Typography>
                  ) : (
                    <Typography variant="body1" mt={1}>
                      Not verified
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} className={classes.dataBox}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={attributes.gender}
                        onChange={handleChange}
                        name="gender"
                        className={classes.checkBox}
                      />
                    }
                    label={<Typography variant="h4">Gender</Typography>}
                  />
                  {verifiedData &&
                  verifiedData.yoti &&
                  verifiedData.yoti.gender ? (
                    <Typography variant="body1" mt={1}>
                      {verifiedData.yoti.gender.value}
                    </Typography>
                  ) : (
                    <Typography variant="body1" mt={1}>
                      Not verified
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </FormGroup>
          </FormControl>
        </Box>
        <Box id="yoti" className={classes.yoti}></Box>
        <Typography body1 mt={2}>
          Please note that the data you choose to verify will override any
          existing data in your profile. Full name and date of birth are
          required.
        </Typography>
      </Box>
    </Box>
  );
};

export default VerifyWithYoti;
