import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#626466" transform="translate(-308 -100)">
          <g transform="translate(300 92)">
            <g transform="translate(8 8)">
              <path d="M13.0424152 3.33333333L5.39460365 10.9477895 2.29091816 7.85764053 1.33333333 8.81104893 5.87542772 13.3333333 14 5.24419572 14 4.28674173 13.5232392 3.81206032z"></path>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
