import React, { useContext } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { text_fields } from '../../../text_fields';
import CMSTitleInfo from '../../../CMS/components/Title';
import { PersonaContext } from '../../../v2api/PersonaContext/PersonaContext'; // FIXME: public component

const officialLinksDisplayInfo = text_fields['knownOfficialLinkTypes'];

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => (images[item.replace('./', '')] = r(item)));
  return images;
}

const logos = importAll(
  require.context('../../../shared/icons/logos', false, /\.(png|jpe?g|svg)$/),
);

const absolutifyUrl = (userUrl) => {
  if (!/^https?:\/\//i.test(userUrl)) {
    return 'http://' + userUrl;
  }
  return userUrl;
};

const OfficialLinks = () => {
  const { persona } = useContext(PersonaContext);
  const theme = useTheme();
  if (!persona || !persona.officialLinks || persona.officialLinks.length === 0)
    return null;
  return (
    <React.Fragment>
      <CMSTitleInfo title="Official Links" />
      <Box
        mb={4}
        sx={{
          [theme.breakpoints.only('xs')]: {
            margin: '0rem 1rem',
          },
        }}
      >
        <Grid container spacing={1}>
          {persona.officialLinks?.map((linkObj) => (
            <Grid item xs={6} sm={3} lg={2} key={linkObj.fieldName}>
              <Paper
                elevation={0}
                sx={{
                  height: 'auto',
                  aspectRatio: '1',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  borderRadius: '1rem',
                  justifyContent: 'center',
                }}
                onClick={() =>
                  window.open(absolutifyUrl(linkObj.value), '_blank')
                }
              >
                <Box
                  component="img"
                  src={
                    logos[`${linkObj.fieldName}.svg`] ||
                    logos[`${linkObj.fieldName}.png`] ||
                    logos['website.svg']
                  }
                  sx={{
                    width: '48px',
                    cursor: 'pointer',
                    '&:hover': {
                      filter: 'brightness(70%)',
                      WebkitFilter: 'brightness(80%)',
                      transition: 'all 0.1s ease',
                      WebkitTransition: 'all 0.1s ease',
                    },
                  }}
                />
                <Typography
                  variant="subtitle1"
                  sx={{
                    marginTop: '0.5rem',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textAlign: 'center',
                    width: 'calc(100% - 1rem)',
                  }}
                >
                  {linkObj?.fieldName &&
                  linkObj.fieldName in officialLinksDisplayInfo
                    ? officialLinksDisplayInfo[linkObj.fieldName].label
                    : linkObj?.fieldName}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default OfficialLinks;
