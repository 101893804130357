import React, { useContext, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Stack, Typography } from '@mui/material';
import styles from './styles';
import AtomsIconsRepresentative from '../../../icons/atomsIconsRepresentative';
import ReadOnlyRepresentative from './ReadOnlyRepresentative';
import IconTextButton from '../../../components/IconTextButton';
import Plus from '../../../icons/atomsIconsAdd';
import CMSTitleInfo from '../Title';
import { PersonaContext } from '../../../v2api/PersonaContext/PersonaContext';
import { PersonaAccessContext } from '../../../v2api/PersonaAccessContext/PersonaAccessContext';
import EditRepresentatives from './EditRepresentatives';

const useStyles = makeStyles(styles);

const Representatives = () => {
  const classes = useStyles();

  const { persona } = useContext(PersonaContext);
  const { personaAccess, representativeAccess, updateAccess } =
    useContext(PersonaAccessContext);
  const [editorOpen, setEditorOpen] = useState(false);

  const repsList = persona?.representatives || [];

  return (
    <div className={classes.root}>
      <EditRepresentatives
        isOpen={editorOpen}
        closeModal={() => setEditorOpen(false)}
      />
      <CMSTitleInfo
        title="Contacts/Representatives"
        isNotEmpty={repsList.some((el) => el.repName)}
        message="Edit Contacts/Representatives"
        onClick={() => setEditorOpen(true)}
        isVisible={personaAccess?.representatives}
        setVisible={(v) =>
          updateAccess({
            method: 'PATCH',
            payload: { representatives: v },
          })
        }
        isVisibilityDisabled={!personaAccess?.personaId}
      />

      {repsList.some((el) => el.repName) ? (
        <Stack className={classes.reps}>
          {repsList.map((rep) => (
            <div key={rep.uniqueKey}>
              <ReadOnlyRepresentative
                representative={rep}
                isVisible={representativeAccess?.[rep.uniqueKey]}
              />
            </div>
          ))}
        </Stack>
      ) : (
        <Box className={classes.empty}>
          <Stack
            gap={4}
            direction="column"
            alignItems="center"
            justifyContent="center"
            height="250px"
          >
            <AtomsIconsRepresentative sx={{ width: '32px' }} />
            <Typography variant="body1">
              Specify who people should contact to reach out to you
            </Typography>
            <IconTextButton
              onClick={() => setEditorOpen(true)}
              size="standard"
              icon={<Plus hasGradient={true} sx={{ fontSize: 18 }} />}
            >
              Add Contacts/Representatives
            </IconTextButton>
          </Stack>
        </Box>
      )}
    </div>
  );
};

export default Representatives;
