import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  return (
    <SvgIcon viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Features</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="UI-Elements"
          transform="translate(-1460.000000, -723.000000)"
          fill="#626466"
        >
          <g id="top-features" transform="translate(1460.000000, 723.000000)">
            <path
              d="M18,4.0002 L24,10.0002 L14,21.6662 L12,24.0002 L0,10.0002 L6,4.0002 L18,4.0002 Z M14.637,11.0002 L9.364,11.0002 L12,18.8572 L14.637,11.0002 Z M20.508,11.0002 L16.747,11.0002 L14.326,18.2132 L20.508,11.0002 Z M7.253,11.0002 L4.857,11.0002 L8.796,15.5942 L7.253,11.0002 Z M11.996,6.5942 L10.061,9.0002 L13.937,9.0002 L11.996,6.5942 Z M18.111,6.9392 L17.419,9.0002 L20.172,9.0002 L18.111,6.9392 Z M6.185,7.8152 L5,9.0002 L6.583,9.0002 L6.185,7.8152 Z M16.315,6.0002 L14.087,6.0002 L15.66,7.9512 L16.315,6.0002 Z M9.908,6.0002 L8,6.0002 L7.764,6.2352 L8.34,7.9482 L9.908,6.0002 Z M5.7311,0.1755 L6.3851,2.1215 L4.4871,2.7585 L3.8341,0.8115 L5.7311,0.1755 Z M18.2696,0.175 L20.1676,0.811 L19.5136,2.758 L17.6176,2.121 L18.2696,0.175 Z M12.9951,0 L12.9951,2 L10.9951,2 L10.9951,0 L12.9951,0 Z"
              id="Combined-Shape"
            ></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
