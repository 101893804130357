import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  const { color } = props;
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 20 20">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill={color || '#626466'} transform="translate(-1170 -26)">
          <g transform="translate(1160 16)">
            <g transform="translate(10 10)">
              <path d="M14.526 13.333l1.056 3.018-1.97 1.982H8.18l-3.194-3.215.624-1.785h8.916zM11.472 2.065a2.983 2.983 0 011.488 2.576v2.935l-2.052 1.849L7.83 6.65V2.974c0-.14.022-.277.034-.416a2.978 2.978 0 013.608-.493zm4.237 9.602H10.91l3.715-3.35V4.64a4.64 4.64 0 00-8.66-2.32 4.63 4.63 0 00-.56 1.605c-.037.235-.061.473-.061.715v3.676l3.715 3.35H4.291L2.5 16.785 5.695 20h8.61l3.195-3.215-1.791-5.118z"></path>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
