import React from 'react';
import BandIcon from '../../icons/atomDrum';
import SoloIcon from '../../icons/atomSoloProject';
import OtherIcon from '../../icons/atomPuzzle';
import CollectiveIcon from '../../icons/atomGroup';

const iconStyle = {
  marginRight: '6px',
  height: '18px',
};

const ProfileTypesExampleSets = [
  {
    name: 'Beyoncé Knowles-Carter (Beyoncé)',
    bands: [{ icon: <BandIcon style={iconStyle} />, text: "Destiny's Child" }],
  },
  {
    name: 'Sir James Paul McCartney (Paul McCartney)',
    bands: [
      { icon: <BandIcon style={iconStyle} />, text: 'The Beatles' },
      { icon: <BandIcon style={iconStyle} />, text: 'Wings' },
    ],
  },
  {
    name: 'Imogen Jennifer Jane Heap (Imogen Heap)',
    bands: [{ icon: <BandIcon style={iconStyle} />, text: 'Frou Frou' }],
  },
  {
    name: 'Björk Guðmundsdóttir (Björk)',
    bands: [{ icon: <BandIcon style={iconStyle} />, text: 'The Sugarcubes' }],
  },
  {
    name: 'Christopher Francis Ocean (Frank Ocean)',
    bands: [{ icon: <BandIcon style={iconStyle} />, text: 'Odd Future' }],
  },
  {
    name: 'Caroline Elizabeth Polachek (Caroline Polachek)',
    bands: [
      { icon: <BandIcon style={iconStyle} />, text: 'Chairlift' },
      { icon: <SoloIcon style={iconStyle} />, text: 'Ramona Lisa' },
      { icon: <OtherIcon style={iconStyle} />, text: 'CEP' },
    ],
  },
  {
    name: 'Freddie Mercury',
    bands: [{ icon: <BandIcon style={iconStyle} />, text: 'Queen' }],
  },
  {
    name: 'Will Wiesenfeld',
    bands: [
      { icon: <SoloIcon style={iconStyle} />, text: 'Baths' },
      { icon: <SoloIcon style={iconStyle} />, text: 'Geotic' },
    ],
  },
  {
    name: 'Sir Michael Philip Jagger (Mick Jagger)',
    bands: [
      { icon: <BandIcon style={iconStyle} />, text: 'The Rolling Stones' },
    ],
  },
  {
    name: 'Annie Clark',
    bands: [
      { icon: <SoloIcon style={iconStyle} />, text: 'St. Vincent' },
      { icon: <BandIcon style={iconStyle} />, text: 'The Polyphonic Spree' },
    ],
  },
  {
    name: 'Gwen Stefani',
    bands: [{ icon: <BandIcon style={iconStyle} />, text: 'No Doubt' }],
  },
  {
    name: 'Benjamin Reichwald',
    bands: [
      { icon: <SoloIcon style={iconStyle} />, text: 'Bladee' },
      { icon: <CollectiveIcon style={iconStyle} />, text: 'Drain Gang' },
    ],
  },
  {
    name: 'Taylor Swift',
    bands: [{}],
  },
  {
    name: 'Jonna Lee',
    bands: [
      { icon: <SoloIcon style={iconStyle} />, text: 'Jonna Lee' },
      { icon: <SoloIcon style={iconStyle} />, text: 'ionnalee' },
      { icon: <CollectiveIcon style={iconStyle} />, text: 'iamamiwhoami' },
    ],
  },
  {
    name: 'Dave Grohl',
    bands: [
      { icon: <BandIcon style={iconStyle} />, text: 'Nirvana' },
      { icon: <BandIcon style={iconStyle} />, text: 'Foo Fighters' },
    ],
  },
  {
    name: 'Chris Martin',
    bands: [{ icon: <BandIcon style={iconStyle} />, text: 'Coldplay' }],
  },
  {
    name: 'Stefani Joanne Angelina Germanotta (Lady Gaga)',
    bands: [{ icon: <SoloIcon style={iconStyle} />, text: 'Lady Gaga' }],
  },
  {
    name: 'Damon Albarn',
    bands: [
      { icon: <BandIcon style={iconStyle} />, text: 'Blur' },
      { icon: <BandIcon style={iconStyle} />, text: 'Gorillaz' },
    ],
  },
  {
    name: 'Donald Glover',
    bands: [{ icon: <SoloIcon style={iconStyle} />, text: 'Childish Gambino' }],
  },
  {
    name: 'Alexander Guy Cook (A. G. Cook)',
    bands: [
      { icon: <SoloIcon style={iconStyle} />, text: 'Life Sim' },
      { icon: <OtherIcon style={iconStyle} />, text: 'QT' },
      { icon: <BandIcon style={iconStyle} />, text: 'Thy Slaughter' },
    ],
  },
  {
    name: 'Adele Adkins (Adele)',
    bands: [{}],
  },
  {
    name: 'Jack White',
    bands: [
      { icon: <BandIcon style={iconStyle} />, text: 'The White Stripes' },
      { icon: <BandIcon style={iconStyle} />, text: 'The Raconteurs' },
      { icon: <BandIcon style={iconStyle} />, text: 'The Dead Weather' },
    ],
  },
  {
    name: 'Thomas Edward Yorke (Thom Yorke)',
    bands: [
      { icon: <BandIcon style={iconStyle} />, text: 'Radiohead' },
      { icon: <BandIcon style={iconStyle} />, text: 'The Smile' },
    ],
  },
  {
    name: 'Brandon Flowers',
    bands: [{ icon: <BandIcon style={iconStyle} />, text: 'The Killers' }],
  },
  {
    name: 'Trent Reznor',
    bands: [
      { icon: <BandIcon style={iconStyle} />, text: 'Nine Inch Nails' },
      { icon: <BandIcon style={iconStyle} />, text: 'How to Destroy Angels' },
    ],
  },
  {
    name: 'James Thomas Smith (Jamie xx)',
    bands: [{ icon: <BandIcon style={iconStyle} />, text: 'The xx' }],
  },
  {
    name: 'Lauren Mayberry',
    bands: [{ icon: <BandIcon style={iconStyle} />, text: 'Chvrches' }],
  },
  {
    name: 'Anthony Gonzalez',
    bands: [{ icon: <BandIcon style={iconStyle} />, text: 'M83' }],
  },
  {
    name: 'Karin Dreijer',
    bands: [
      { icon: <SoloIcon style={iconStyle} />, text: 'Fever Ray' },
      { icon: <BandIcon style={iconStyle} />, text: 'The Knife' },
    ],
  },
  {
    name: 'Christopher Stephen Clark (Clark)',
    bands: [{}],
  },
];

export default ProfileTypesExampleSets;
