import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  const { color } = props;
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <title>Location</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="UI-Elements"
          transform="translate(-980.000000, -568.000000)"
          fill={color || '#626466'}
        >
          <g id="location" transform="translate(980.000000, 568.000000)">
            <path
              d="M12.005,0 L21.005,4.619 L21.005,12.857 L11.59,24 L11.588,23.998 L11.586,24 L3,12.857 L3,4.619 L4.931,3.628 L4.931,3.631 L12.005,0 Z M12.005,2.248 L5.005,5.841 L5.005,10.952 L12.155,20.233 L19.005,12.125 L19.005,5.841 L12.005,2.248 Z M16.936,7.0003 L11.936,14.6603 L6.936,7.0003 L16.936,7.0003 Z M13.242,9.0003 L10.63,9.0003 L11.936,11.0013 L13.242,9.0003 Z"
              id="Combined-Shape"
            ></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
