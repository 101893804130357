import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  Typography,
  Box,
  IconButton,
  Paper,
  Menu,
  MenuItem,
} from '@mui/material';
import { HiOutlineChevronDown } from 'react-icons/hi';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderRadius: '16px',
    backgroundColor: theme.palette.background.paper,
    padding: '1rem',
    marginBottom: '0.7rem',
  },
  progress: {
    width: '100%',
    backgroundImage: 'linear-gradient(to right, #FF2667, #73AEE6, #00FFCC)',
    padding: '0.3rem',
    borderRadius: '100rem',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: '0.6rem',
    borderRadius: '20rem',
    flexGrow: '0.8',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '1rem',
    cursor: 'pointer',
    boxShadow: '2px 2px 6px 0px rgba(0,0,0,0.1)',
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '0.7rem',
  },
  textTitle: {
    fontSize: '14px',
  },
  textNumber: {
    paddingRight: '15px',
    paddingLeft: '10px',
  },
  menu: {
    borderRadius: '0.75rem',
    width: '320px',
    marginTop: '0.2rem',
    // backgroundColor: 'black'
  },
  menuList: {
    width: '210px',
  },
}));

const PublicPageVisualization = ({ personaId }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [period, setPeriod] = React.useState('thisWeek');
  const handleClick = (period) => {
    setPeriod(period);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Performance killer, re-writing
  // const { publicPageViews } = usePublicPageViews(personaId);

  return (
    <>
      <Box className={classes.root}>
        <Typography variant="h5">Public Page Visualizations</Typography>
        <Box className={classes.textContainer}>
          <Paper
            onClick={(e) => setAnchorEl(e.currentTarget)}
            elevation={2}
            className={classes.paper}
          >
            <Typography sx={{ fontWeight: 500 }}>
              {period === 'thisWeek'
                ? 'This Week'
                : period === 'thisMonth'
                  ? 'This Month'
                  : 'This Year'}
            </Typography>
            <IconButton size="small">
              <HiOutlineChevronDown />
            </IconButton>
          </Paper>
          <Typography className={classes.textNumber} variant="h1">
            {0}
          </Typography>
        </Box>
        <Menu
          className={classes.menu}
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            className={classes.menuList}
            onClick={() => handleClick('thisWeek')}
          >
            This Week
          </MenuItem>
          <MenuItem
            className={classes.menuList}
            onClick={() => handleClick('thisMonth')}
          >
            This Month
          </MenuItem>
          <MenuItem
            className={classes.menuList}
            onClick={() => handleClick('thisYear')}
          >
            This Year
          </MenuItem>
        </Menu>
      </Box>
    </>
  );
};

export default PublicPageVisualization;
