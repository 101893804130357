import React from 'react';
import { SvgIcon } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function Icon(props) {
  const theme = useTheme();
  const { primary } = theme.palette;
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-738 -28)">
          <g transform="translate(734 24)">
            <g transform="translate(4 4)">
              <path
                d="M3.514 0L.667 2.847 0 3.514v4.97l3.515 3.513h4.97l.573-.572 4.573 4.571L15.3 16l.578-.583.121-.123-5.407-5.405L12 8.484V3.514L8.485 0H3.514zm-2.18 7.15V4.067l2.733-2.734h3.866l2.734 2.734V7.93L8.599 9.997H4.181L1.333 7.151zm9.26 2.74l-.123.121.122-.122z"
                fill={primary.hint}
              ></path>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
