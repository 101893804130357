const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0.5rem',
      marginRight: '1rem',
    },
  },
  editBio: {
    border: 'solid 1px #666565',
    color: '#666565',
    backgroundColor: '#FDFDFD',
    padding: '10px',
    width: '100%',
    fontFamily: 'inherit',
  },
  textarea: {
    width: '100%',
    padding: '10px',
    border: 'none',
    resize: 'none',
    borderRadius: '1rem',
    fontFamily: 'inherit',
    marginBottom: '16px',
    '&:focus-visible': {
      outline: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0',
    },
  },
  empty: {
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    padding: '1rem',
    borderRadius: '1rem',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0',
    },
  },

  tabs: {
    width: '100%',
    minHeight: '100%',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: 'hidden !important',
  },
  tab: {
    marginBottom: '20px',
    flexGrow: 1,
    height: '50px',
    paddingTop: '15px',
    textTransform: 'capitalize',
  },
  tabPanel: {
    backgroundColor: '#fff',
    width: '100%',
    padding: '10px',
    border: 'none',
    resize: 'none',
    borderRadius: '1rem',
    fontFamily: 'inherit',
    '&:focus-visible': {
      outline: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0',
    },
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
});

export default styles;
