import React from 'react';
import { SvgIcon } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function Icon(props) {
  const theme = useTheme();
  const { primary } = theme.palette;
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      {...props}
    >
      <title>Users</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Integrations/Default"
          transform="translate(-444.000000, -28.000000)"
        >
          <g
            id="atoms/icons/users"
            transform="translate(444.000000, 28.000000)"
          >
            <path
              d="M12.6666667,0.0004 L14,1.33373333 L14,14.6670667 L12.6673333,15.9997333 L3.33333333,15.9997333 L2,14.6670667 L2,1.33373333 L3.334,0.0004 L12.6666667,0.0004 Z M12.6673333,1.33373333 L4.00066667,1.33373333 L4.00066667,14.0004 L12.6673333,14.0004 L12.6673333,1.33373333 Z M11.3335333,10.6666667 L11.3335333,12 L5.33353333,12 L5.33353333,10.6666667 L11.3335333,10.6666667 Z M8.3338,2.667 C9.99046667,2.667 11.3338,4.01033333 11.3338,5.667 C11.3338,7.323 9.99046667,8.667 8.3338,8.667 C6.67713333,8.667 5.3338,7.323 5.3338,5.667 C5.3338,4.01033333 6.67713333,2.667 8.3338,2.667 Z M8.3338,4.00033333 C7.41446667,4.00033333 6.66713333,4.74766667 6.66713333,5.667 C6.66713333,6.58566667 7.41446667,7.33366667 8.3338,7.33366667 C9.25246667,7.33366667 10.0004667,6.58566667 10.0004667,5.667 C10.0004667,4.74766667 9.25246667,4.00033333 8.3338,4.00033333 Z"
              id="Combined-Shape"
              fill={primary.hint}
            ></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
