import Modal from '../../CMSModal';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import CPAddButton from '../../CPAddButton';
import React, { useContext, useState } from 'react';
import { PersonaContext } from '../../../../v2api/PersonaContext/PersonaContext';
import DraggableRepresentativeItem from '../DraggableRepresentativeItem';
import { v4 as uuidv4 } from 'uuid';
import { PersonaAccessContext } from '../../../../v2api/PersonaAccessContext/PersonaAccessContext';
import { areRepsUnchanged } from '../../../../v2api/extraTypes';

const EditRepresentatives = ({ isOpen, closeModal }) => {
  const { persona, updatePersona } = useContext(PersonaContext);
  const { personaAccess, representativeAccess, updateAccess } =
    useContext(PersonaAccessContext);
  const [localRepresentatives, setLocalRepresentatives] = useState(
    persona?.representatives || [],
  );

  const [visibilityPatch, setVisibilityPatch] = useState({});

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10, // Adjust this value as needed
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = localRepresentatives.findIndex(
        (rep) => rep.uniqueKey === active.id,
      );
      const newIndex = localRepresentatives.findIndex(
        (rep) => rep.uniqueKey === over.id,
      );
      setLocalRepresentatives((items) => arrayMove(items, oldIndex, newIndex));
    }
  };

  const handleAdd = () => {
    setLocalRepresentatives((state) => [
      ...state,
      {
        uniqueKey: uuidv4(), // XXX: should all uuids be created server-side?
        representativeType: 'general',
        repName: '',
        repPassportId: null,
        contactName: null,
        contactEmail: null,
        contactNote: null,
        contactPhone: null,
      },
    ]);
  };

  const handleChange = (patch) =>
    setLocalRepresentatives((state) =>
      state.map((rep) => {
        if (rep.uniqueKey === patch.uniqueKey) {
          return { ...rep, ...patch };
        }
        return rep;
      }),
    );

  const handleDelete = (uniqueKey) =>
    setLocalRepresentatives((state) =>
      state.filter((rep) => rep.uniqueKey !== uniqueKey),
    );

  const handleCancel = () => {
    setLocalRepresentatives(persona.representatives || []);
    setVisibilityPatch({});
    closeModal();
  };

  const handleSave = () => {
    if (Object.keys(visibilityPatch).length > 0) {
      updateAccess({
        method: 'ATTR_PATCH',
        attrsType: 'representatives',
        payload: visibilityPatch,
      });
    }
    if (
      !areRepsUnchanged(persona.representatives || [], localRepresentatives)
    ) {
      updatePersona({
        method: 'PATCH',
        payload: { representatives: localRepresentatives },
      });
    }

    setVisibilityPatch({});
    closeModal();
  };

  const patchedVisibility = { ...representativeAccess, ...visibilityPatch };
  const isHidden = !personaAccess?.representatives;

  return (
    <Modal open={isOpen} onClose={handleCancel} onSave={handleSave}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={localRepresentatives?.map((el) => el.uniqueKey)}
          strategy={verticalListSortingStrategy}
        >
          {localRepresentatives.map((el) => (
            <DraggableRepresentativeItem
              key={el.uniqueKey}
              id={el.uniqueKey}
              repData={el}
              handleChange={handleChange}
              handleDelete={handleDelete}
              isVisible={patchedVisibility[el.uniqueKey]}
              setVisible={(v) =>
                setVisibilityPatch((ps) => ({ ...ps, [el.uniqueKey]: v }))
              }
              areRepsHidden={isHidden}
            />
          ))}
        </SortableContext>
      </DndContext>
      <CPAddButton onClick={() => handleAdd()}>Add a new contact</CPAddButton>
    </Modal>
  );
};
export default EditRepresentatives;
