import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import styles from './styles';
import { TextField, Typography, Box } from '@mui/material';
import CPVisibleToggle from '../../CPVisibleToggle';
import CPDeleteButton from '../../CPDeleteButton';
import { text_fields } from '../../../../text_fields';

const useStyles = makeStyles(styles);

const specialLinks = text_fields['knownOfficialLinkTypes'];

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => (images[item.replace('./', '')] = r(item)));
  return images;
}

const logos_links = importAll(
  require.context(
    '../../../../shared/icons/logos',
    false,
    /\.(png|jpe?g|svg)$/,
  ),
);

const absolutifyUrl = (userUrl) => {
  if (!/^https?:\/\//i.test(userUrl)) {
    return 'http://' + userUrl;
  }
  return userUrl;
};

const EditOfficialLink = ({
  fieldName,
  value,
  handleChange,
  onDelete,
  isVisible,
  setVisible,
  areLinksHidden,
}) => {
  const classes = useStyles();
  const [isDragging, setIsDragging] = useState(false);

  const isSpecial = fieldName in specialLinks && fieldName !== 'website'; // FIXME: prepend with OFFICIAL::?

  const imgFile = isSpecial
    ? logos_links[`${fieldName}.svg`] || logos_links[`${fieldName}.png`]
    : logos_links['website.svg'];

  if (!imgFile) console.warn(`No img file found for ${fieldName}`);

  const logoIcon = (
    <img
      src={imgFile}
      className={classes.icon}
      alt={fieldName}
      draggable="false"
    />
  );

  if (isSpecial)
    return (
      <>
        <Box className={classes.editRoot}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexGrow: 1,
              justifyContent: 'start',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            {logoIcon}
            <Typography className={classes.editName} variant="h4">
              {specialLinks[fieldName].label}
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'start',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <CPVisibleToggle
              darker={true}
              isVisible={isVisible}
              setVisible={setVisible}
              isDisabled={areLinksHidden}
            />
            <CPDeleteButton onClick={onDelete} />
          </Box>
        </Box>
        <TextField
          label="Profile URL"
          fullWidth
          variant="standard"
          className={classes.editField}
          value={value}
          name={fieldName}
          placeholder="example.com/@thecreativepassport"
          onKeyDown={(e) => e.stopPropagation()} // fixes a dnd-kit bug
          onChange={(e) => handleChange({ value: e.target.value })}
        />
      </>
    );

  return (
    <>
      <Box>
        <Box className={classes.editRoot}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexGrow: 1,
              justifyContent: 'start',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <Typography className={classes.editName} variant="h6">
              Custom link
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'start',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <CPVisibleToggle
              darker={true}
              isVisible={isVisible}
              setVisible={setVisible}
              isDisabled={areLinksHidden}
            />
            <CPDeleteButton onClick={() => onDelete()} />
          </Box>
        </Box>
        <TextField
          label="Website Label"
          fullWidth
          variant="standard"
          className={classes.editField}
          value={fieldName}
          name={fieldName}
          placeholder="E.g. Portfolio, Personal, Webstore"
          onKeyDown={(e) => e.stopPropagation()} // fixes a dnd-kit bug
          onChange={(e) => handleChange({ fieldName: e.target.value })}
        />
        <TextField
          label="Website URL"
          fullWidth
          variant="standard"
          className={classes.editField}
          value={value}
          name={fieldName}
          placeholder="example.com"
          onKeyDown={(e) => e.stopPropagation()} // fixes a dnd-kit bug
          onChange={(e) => handleChange({ value: e.target.value })}
        />
      </Box>
    </>
  );
};

export default EditOfficialLink;
