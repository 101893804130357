import React from 'react';
import {
  Dialog,
  Grid,
  Typography,
  IconButton,
  DialogContent,
  Link,
} from '@mui/material';
import { CgClose } from 'react-icons/cg';
import { makeStyles } from '@mui/styles';
import CollectiveIcon from '../../icons/atomGroup';
import PassportIcon from '../../icons/headerPassport';
import SearchIcon from '../../icons/searchIcon.js';
import PlugIcon from '../../icons/headerPlug';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    top: 8,
    right: 8,
  },
  proPanel: {
    backgroundColor: '#E3EEFA',
    padding: '1rem',
    borderRadius: '1rem',
  },
  artPanel: {
    backgroundColor: '#F7F8F9',
    padding: '1rem',
    borderRadius: '1rem',
  },
  panelTitle: {
    marginBottom: '1rem',
  },
  typeIcon: {
    marginRight: '6px',
    height: '16px',
  },
  refreshButton: {
    width: '25px',
    height: '25px',
    marginLeft: '3px',
  },
  refreshIcon: {
    width: '20px',
    height: '20px',
  },
  textWithIconStyle: {
    paddingRight: '30px',
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    width: '18px',
  },
  soon: {
    backgroundColor: '#FFD3E0',
    color: '#F0376E',
    borderRadius: '1rem',
    textAlign: 'center',
    float: 'left',
    position: 'relative',
    padding: '0rem 0.3rem',
  },
  new: {
    backgroundColor: '#D9F4ED',
    color: '#00A483',
    borderRadius: '1rem',
    textAlign: 'center',
    float: 'left',
    position: 'relative',
    padding: '0rem 0.3rem',
  },
}));

const AboutCreativePassport = ({ isOpen, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h2">About The Project</Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography>
              The Creative Passport is a digital identity management tool
              created by music makers, for music makers. Here's what you can do
              with your passport:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} className={classes.heading}>
            <CollectiveIcon className={classes.icon} />
            <Typography variant="h4" ml={1}>
              Create profiles
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography>
              Create a profile linked to your legal identity and add information
              related to your career as a music professional. Now you can also
              build profiles for your band, collective, orchestra, or any other
              project you're a part of.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} className={classes.heading}>
            <SearchIcon className={classes.icon} />
            <Typography variant="h4" ml={1}>
              Discover others
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography>
              Become searchable in our portal of music makers, and find your
              next collaborator. You can search via name, project, skill, or any
              other attribute. Opt into our interactive map and discover people
              where you live.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} className={classes.heading}>
            <PassportIcon className={classes.icon} />
            <Typography variant="h4" ml={1}>
              Share your public page
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography>
              Display all of your verified data in one standardised place, and
              enable a smoother interaction between you, music services, and the
              broader industry. Decide what to keep private, and customise how
              your public page looks.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} className={classes.heading}>
            <PlugIcon className={classes.icon} />
            <Typography variant="h4" ml={1}>
              Integrate with services
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography>
              Through our integration with industry partners you can ensure that
              every place your work shows up, so do you. Enhance your passport
              functionality by connecting with PPL, VEVA Collect, Good Splits,
              and more.
            </Typography>
          </Grid>
        </Grid>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CgClose />
        </IconButton>
      </DialogContent>
      <Link
        mb={3}
        sx={{
          color: '#5079A1',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          transition: '0.3s',
          textDecorationColor: '#5078a130)!important',
          '&:hover': {
            textDecorationColor: '#5079A1)!important',
          },
          textDecoration: 'underline',
        }}
        href="https://www.creativepassport.net/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn more
      </Link>
    </Dialog>
  );
};

export default AboutCreativePassport;
