import { Box, Link as MuiLink, SvgIcon, Typography } from '@mui/material';
import Logo from '../../icons/cpLogoWithTextWhite';
import useDisclosure from '../../old/hooks/useDisclosure';
import AboutCreativePassport from '../modals/AboutCreativePassport.js';
import { useAuth } from 'react-oidc-context';
import React from 'react';
import { Navigate } from 'react-router-dom';
import IconTextButton from '../../components/IconTextButton';
import Person from '../../icons/atomsIconsRepresentative';
import Plus from '../../icons/atomsIconsAdd';

// This is generally not supported by Keycloak, and not at all by react-oidc-provider, hence this hardcode.
const directRegistrationLink =
  `${process.env.REACT_APP_KEYCLOAK_REALM_URI}/protocol/openid-connect/registrations?client_id=creative-passport&response_type=code&scope=openid&redirect_uri=https%3A%2F%2Fcreativepassport.net`;

const WelcomePage = () => {
  const auth = useAuth();
  const {
    isOpen: isAboutCreativePassportOpen,
    onOpen: onAboutCreativePassportOpen,
    onClose: onAboutCreativePassportClose,
  } = useDisclosure(false);

  if (auth.isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <Box
      sx={{
        height: '100vh',
        minHeight: { xs: '', sm: '', md: '768px', lg: '768px' },
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <AboutCreativePassport
        isOpen={isAboutCreativePassportOpen}
        onClose={onAboutCreativePassportClose}
      />
      <Box
        sx={{
          height: '100%',
          width: '100%',
          maxHeight: { xs: '100%', sm: '100%', md: '100%', lg: '100%' },
          borderRadius: { xs: '0px', sm: '0px', md: '0px', lg: '0px' },
          padding: {
            xs: '0px',
            sm: '20px',
            md: '20px',
            lg: '20px',
          },
          backgroundImage:
            'linear-gradient(328deg, rgba(255, 38, 103, 1) 0%, rgba(102, 154, 203, 1) 51%, rgba(0, 255, 204, 1) 100%)',
          boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
          display: 'flex',
          flexDirection: {
            xs: 'column',
            sm: 'column',
            md: 'row',
            lg: 'row',
          },
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '15px',
          '& > div': {
            height: {
              xs: 'auto',
              sm: 'auto',
              md: '100%',
              lg: '100%',
            },
            width: {
              xs: '100%',
              sm: '512px',
              md: '100%',
              lg: '100%',
            },
          },
        }}
      >
        {/* Content Section */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: {
              xs: '30%',
              sm: '30%',
              md: '100%',
              lg: '100%',
            },
            padding: '10px',
          }}
        >
          <Logo
            sx={{
              fontSize: '180px',
              width: '256px',
              margin: {
                xs: '32px',
                sm: '32px',
              },
              height: 'auto{',
            }}
          />
          <Typography
            marginTop={{ md: '70px', lg: '100px' }}
            marginBottom={{ md: '70px', lg: '100px' }}
            height="120px"
            color="white"
            textAlign="center"
            variant="h2"
            display={{ xs: 'block', sm: 'block', md: 'none', lg: 'none' }}
          >
            <br />
          </Typography>
          <Typography
            marginTop={{ md: '70px', lg: '100px' }}
            marginBottom={{ md: '70px', lg: '100px' }}
            color="white"
            textAlign="center"
            variant="h2"
            display={{ xs: 'none', sm: 'none', md: 'block', lg: 'block' }}
          >
            Empowering music makers,<br />Empowered by Auracles
          </Typography>
          <Box
            sx={{
              backgroundColor: '#fff2',
              borderRadius: { xs: '0px', sm: '13px', md: '13px', lg: '13px' },
              padding: '32px',
              paddingX: { xs: '24px', sm: '24px', md: '30px', lg: '50px' },
              marginY: { xs: '24px', sm: '24px', md: '100px', lg: '100px' },
              boxShadow: '0px 0px 24px rgba(0,0,0, 0.1)',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '30px',
            }}
          >
            <Box display="flex" flexDirection="row">
              <Typography color="white" maxWidth="320px" align="center">
                <DarkInfo
                  sx={{
                    fontSize: '14px',
                    marginRight: '8px',
                    marginTop: '0px',
                  }}
                />
                The Creative Passport will soon be known as Auracles,
                as Imogen and the team expand the suite of services for creatives.
              </Typography>
            </Box>
            <IconTextButton
              size="standard"
              onClick={() => auth.signinRedirect()}
              sx={{ width: '100%', minWidth: '300px' }}
              icon={
                <Person
                  hasGradient={true}
                  sx={{ fontSize: 18, margin: '2px' }}
                />
              }
            >
              Sign in
            </IconTextButton>
            <IconTextButton
              size="standard"
              onClick={() => window.location.replace(directRegistrationLink)}
              sx={{ width: '100%', minWidth: '300px' }}
              icon={<Plus hasGradient={true} sx={{ fontSize: 18 }} />}
            >
              Register
            </IconTextButton>
          </Box>
          {/* Footer Link Desktop */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <MuiLink
              sx={{
                transition: '0.3s',
                color: 'white',
                fontSize: '14px',
                textDecorationColor: 'rgba(255,255,255,0.5)!important',
                '&:hover': {
                  textDecorationColor: 'rgba(255,255,255,1)!important',
                },
                textDecoration: 'underline',
                display: { xs: 'none', sm: 'none', md: 'inline', lg: 'inline' },
              }}
              onClick={onAboutCreativePassportOpen}
            >
              About the project
            </MuiLink>
            <Typography
              sx={{
                color: 'white',
                fontSize: '14px',
                margin: '0 5px',
                opacity: '0.5',
                display: {
                  xs: 'none',
                  sm: 'none',
                  md: 'inline',
                  lg: 'inline',
                },
              }}
            >
              |
            </Typography>
            <a href="mailto:support@creativepassport.net">
              <MuiLink
                sx={{
                  transition: '0.3s',
                  color: 'white',
                  fontSize: '14px',
                  textDecorationColor: 'rgba(255,255,255,0.5)!important',
                  '&:hover': {
                    textDecorationColor: 'rgba(255,255,255,1)!important',
                  },
                  textDecoration: 'underline',
                  display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'inline',
                    lg: 'inline',
                  },
                }}
              >
                Trouble logging in?
              </MuiLink>
            </a>
          </Box>
        </Box>
        {/* Footer Link Desktop */}
        <MuiLink
          sx={{
            position: 'absolute',
            bottom: '40px',
            width: '100%',
            display: {
              xs: 'flex',
              sm: 'flex',
              md: 'none',
              lg: 'none',
            },
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            height: '10vh !important',
            textDecorationColor: 'rgba(255,255,255,0.5)!important',
            '&:hover': {
              textDecorationColor: 'rgba(255,255,255,1)!important',
            },
            transition: '0.3s',
            color: 'white',
            fontSize: '14px',
            textDecoration: 'underline',
          }}
          onClick={onAboutCreativePassportOpen}
        >
          About the project
        </MuiLink>
      </Box>
    </Box>
  );
};
export default WelcomePage;

const DarkInfo = (props) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" {...props}>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="#333" transform="translate(-316 -559)">
        <g transform="translate(300 543)">
          <g transform="translate(16 16)">
            <path d="M12 0l8.486 3.515 3.515 8.486-3.515 8.485L12 24l-8.485-3.514L0 12.001l3.515-8.486L12 0zm0 2.165L6.054 4.628 3 12.001l3.054 7.371L12 21.835l6.955-2.88 2.881-6.954-2.881-6.956L12 2.165zM13.023 9v8h-2V9h2zm0-3v2h-2V6h2z"></path>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);
