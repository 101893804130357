import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 24 24">
      <title>Your Information is private. Click to make public.</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="UI-Elements"
          transform="translate(-895.000000, -615.000000)"
          fill="#ACAFB2"
        >
          <g id="public-default" transform="translate(896.000000, 615.000000)">
            <path
              d="M11.9997,4 L19.4857,6.514 L23.9997,12 L19.4857,17.485 L11.9997,20 L4.5147,17.485 L1.7347,14.107 L-0.0003,12 L4.5147,6.514 L11.9997,4 Z M16.6657,8.231 C17.4987,9.261 17.9997,10.571 17.9997,12 C17.9997,13.844 17.1647,15.494 15.8547,16.594 L15.8547,16.594 L18.3047,15.771 L21.4087,12 L18.9357,8.994 Z M6.7477,9.099 L5.5147,9.514 L3.0297,12.533 L5.6947,15.771 L8.1457,16.594 C6.8347,15.494 5.9997,13.844 5.9997,12 C5.9997,10.947 6.2727,9.959 6.7477,9.099 L6.7477,9.099 Z M11.9997,8 C9.7937,8 7.9997,9.793 7.9997,12 C7.9997,14.205 9.7937,16 11.9997,16 C14.2047,16 15.9997,14.205 15.9997,12 C15.9997,9.793 14.2047,8 11.9997,8 Z M11.9971,9.9995 C13.1011,9.9995 13.9971,10.8955 13.9971,11.9995 C13.9971,13.1035 13.1011,13.9995 11.9971,13.9995 C10.8931,13.9995 9.9971,13.1035 9.9971,11.9995 C9.9971,10.8955 10.8931,9.9995 11.9971,9.9995 Z"
              id="Combined-Shape"
            ></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
