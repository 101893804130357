import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  return (
    <SvgIcon width="40px" height="40px" viewBox="0 0 40 40" {...props}>
      <title>Representatives</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Profile-|-Basic---UI"
          transform="translate(-432.000000, -2304.000000)"
          fill="#313233"
        >
          <g
            id="Components/Profile-info-Copy-4"
            transform="translate(92.000000, 2208.000000)"
          >
            <g
              id="Atoms/icons/Skills/UI"
              transform="translate(340.000000, 96.000000)"
            >
              <path
                d="M31.4183333,23.333 L35,33.5696667 L28.6116667,39.9996667 L21.75,39.9996667 L22.9916667,36.6663333 L27.2233333,36.6663333 L31.165,32.6996667 L29.0533333,26.6663333 L26.1266667,26.6663333 L23.84,23.333 L31.4183333,23.333 Z M16.1,23.3326667 L13.8166667,26.666 L11.22,26.666 L9.97166667,30.236 L18.1933333,39.9993333 L11.39,39.9993333 L5,33.5693333 L8.58166667,23.3326667 L16.1,23.3326667 Z M21.8205,23.3326667 L24.6438333,27.4526667 L19.9705,39.9993333 L15.2971667,27.4526667 L18.1221667,23.3326667 L21.8205,23.3326667 Z M19.9701667,-9.47390314e-14 C21.5735,-9.47390314e-14 23.1768333,0.413333333 24.6118333,1.24333333 C27.4835,2.90166667 29.2535,5.965 29.2535,9.28166667 L29.2535,9.28166667 L29.2535,16.635 L23.0685,22.2083333 L16.8735,22.2066667 L10.6885,16.635 L10.6885,9.28166667 C10.6885,8.45166667 10.7985,7.64 11.0101667,6.86 C11.1151667,6.47 11.2468333,6.08833333 11.4001667,5.71833333 C12.1701667,3.865 13.5351667,2.27833333 15.3301667,1.24333333 C15.6851667,1.03833333 16.0501667,0.858333333 16.4235,0.705 C17.5585,0.235 18.7651667,-9.47390314e-14 19.9701667,-9.47390314e-14 Z M19.9701667,3.33333333 C18.9285,3.33333333 17.9001667,3.60833333 16.9968333,4.13 C16.5251667,4.40166667 16.1018333,4.73666667 15.7285,5.11666667 C15.7035,5.39333333 15.6601667,5.66833333 15.6601667,5.94833333 L15.6601667,5.94833333 L15.6601667,13.3016667 L21.8168333,18.85 L25.9201667,15.15 L25.9201667,9.28166667 C25.9201667,7.16333333 24.7801667,5.18833333 22.9451667,4.13 C22.0435,3.60833333 21.0135,3.33333333 19.9701667,3.33333333 Z"
                id="Combined-Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
