const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  topInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#777777',
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0.5rem',
      marginRight: '1rem',
    },
  },
  empty: {
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    padding: '1rem',
    borderRadius: '1rem',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0',
    },
  },
  emptyIcons: {
    display: 'flex',
    fontSize: '32px',
    alignItems: 'center',
    gap: '1rem',
    color: '#313233',
  },
  main: {
    padding: theme.spacing(2),
    display: 'flex',
    borderRadius: '1rem',
    backgroundColor: '#FFFFFF',
    flexDirection: 'column',
    gap: '1rem',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0',
    },
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
});

export default styles;
