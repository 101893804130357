const styles = (theme) => ({
  withPadding: {
    [theme.breakpoints.down('sm')]: {
      padding: '1.5rem',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '1.5rem',
      width: '512px',
    },
    display: 'flex',
    flexDirection: 'column',
  },
  saveButton: {
    width: '160px',
    backgroundColor: theme.palette.background.paper,
    fontSize: 12,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    gap: 9,
    [theme.breakpoints.down('sm')]: {
      padding: '0',
    },
  },
  cancelButton: {
    width: '120px',
    backgroundColor: theme.palette.background.dark,
    fontSize: 12,
    [theme.breakpoints.down('sm')]: {
      padding: '0',
    },
  },
  save: {
    boxShadow: '0px -15px 20px rgba(255, 255, 255, 1)',
    zIndex: '1',
    height: '64px',
    marginTop: theme.spacing(0),
    backgroundColor: theme.palette.background.dark,
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
      width: '100%',
    },
  },
});

export default styles;
