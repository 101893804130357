import { AttrsType, IPersonaAccess, IPersonaAccessPatch, PersonaId } from './types.ts';
import { personaKeys } from '../api.ts';
import { ICustomAccess } from '../extraTypes.ts';

const v2ApiBase = process.env.REACT_APP_V2_API_BASE;

export async function getPersonaAccess(jwtToken: string, personaId: string): Promise<IPersonaAccess> {
  if (!jwtToken) {
    throw new Error('No token provided');
  }
  const url = `${v2ApiBase}/persona/${personaId}/access/public_page/`;
  const headers = {
    Accept: 'application/json',
    Authorization: 'Bearer ' + jwtToken
  };

  const response = await fetch(`${url}?q=${Object.values(personaKeys).join(',')}`, { headers: headers });
  if (!response.ok) {
    if (response.status === 404) {
      // @ts-ignore
      return Object.fromEntries(Object.keys(personaKeys).map((k) => [k, false]));
    } else {
      throw new Error(response.statusText);
    }
  }
  const responseJson = await response.json();
  // Convert response from snake case to camelCase
  return Object.fromEntries(Object.entries(personaKeys).map(([key, fk]) => [key, responseJson[fk]])) as IPersonaAccess;
}

export async function getAttrsAccess(jwtToken: string, personaId: PersonaId, attrsType: AttrsType, attrUniqueKeys: string[]): Promise<ICustomAccess> {
  if (!jwtToken) throw new Error('No token provided');
  const attrs_type = {
    customIds: 'custom_ids',
    customProjects: 'custom_projects',
    officialLinks: 'official_links',
    representatives: 'representatives',
  }[attrsType];
  const url = `${v2ApiBase}/persona/${personaId}/${attrs_type}/access/public_page/`;
  const headers = {
    Accept: 'application/json',
    Authorization: 'Bearer ' + jwtToken
  };

  const response = await fetch(`${url}?q=${attrUniqueKeys.join(',')}`, { headers: headers });
  if (!response.ok) {
    if (response.status === 404) {
      return Object.fromEntries(attrUniqueKeys.map((k) => [k, false])) as ICustomAccess;
    } else {
      throw new Error(response.statusText);
    }
  }
  const responseJson = await response.json();
  return Object.fromEntries(attrUniqueKeys.map((key) => [key, responseJson[key]])) as ICustomAccess;
}

export async function patchPersonaAccess(jwtToken: string, personaId: PersonaId, patch: IPersonaAccessPatch): Promise<boolean> {
  if (!jwtToken) throw new Error('No token provided');
  const url = `${v2ApiBase}/persona/${personaId}/access/public_page/`;
  const headers = { Authorization: 'Bearer ' + jwtToken, 'Content-Type': 'application/json' };
  const patchJson = Object.fromEntries(Object.entries(patch).map(([key, val]) => [personaKeys[key], val]));
  const response = await fetch(url, { method: 'PATCH', headers: headers, body: JSON.stringify(patchJson) });
  return response.ok;
}

export async function postPersonaAccess(jwtToken: string, personaId: PersonaId, isPublic: boolean): Promise<boolean> {
  if (!jwtToken) throw new Error('No token provided');
  const url = `${v2ApiBase}/persona/${personaId}/access/`;
  const headers = { Authorization: 'Bearer ' + jwtToken, 'Content-Type': 'application/json' };
  const postJson = { public_page: isPublic };
  const response = await fetch(url, { method: 'POST', headers: headers, body: JSON.stringify(postJson) });
  return response.ok;
}


export async function patchAttrAccess(jwtToken: string, personaId: PersonaId, attrsType: AttrsType, patch: ICustomAccess): Promise<boolean> {
  if (!jwtToken) throw new Error('No token provided');
  const attrs_type = {
    customIds: 'custom_ids',
    customProjects: 'custom_projects',
    officialLinks: 'official_links',
    representatives: 'representatives'
  }[attrsType];
  const url = `${v2ApiBase}/persona/${personaId}/${attrs_type}/access/public_page/`;
  const headers = { Authorization: 'Bearer ' + jwtToken, 'Content-Type': 'application/json' };
  const response = await fetch(url, { method: 'PATCH', headers: headers, body: JSON.stringify(patch) });
  return response.ok;
}
