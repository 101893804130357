import React from 'react';
import { SvgIcon } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function Icon(props) {
  const theme = useTheme();
  const { primary } = theme.palette;
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-738 -28)">
          <g transform="translate(734 24)">
            <g transform="translate(4 4)">
              <path
                d="M5.748 1.992l3.038 3.042a1.73 1.73 0 01-.668.132c-.468 0-.907-.181-1.238-.512L5.438 3.21l-.95.949.466.466L2.06 7.52l-.05-.037-.02-3.891L3.567 2.01l2.18-.02zm4.37 8.47l-.951-.95-2.982 2.984-.441-.441L8.726 9.07l-.95-.95-2.983 2.984-.44-.441 2.982-2.985-.95-.95-2.983 2.985-.441-.442.337-.338.005.005 3.019-3.02c.536.387 1.155.59 1.796.59.826 0 1.602-.321 2.186-.905a.672.672 0 000-.95l-1.36-1.36 1.254-1.284 2.21-.02 1.582 1.557.02 3.897L7.956 13.5l-.043.051-.337.338-.442-.442 2.983-2.984zm5.196-7.464L12.956.666l-3.308.02-1.652 1.659-1.7-1.677-3.306.02L.667 3.045l.019 4.987.831.811.02.903 4.174 4.176 1.437 1.41.848.001.054-.02 3.411-3.411.043-.051 3.83-3.858-.02-4.995z"
                fill={primary.hint}
              ></path>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
