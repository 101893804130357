const styles = (theme) => ({
  main: {
    [theme.breakpoints.up('xl')]: {
      maxWidth: '1536px',
    },
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
  },
  root: {
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: 'calc(67% - 4rem)',
    marginLeft: '4rem',
    [theme.breakpoints.down('md')]: {
      width: '100vw',
      marginLeft: '1rem',
      marginRight: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      marginLeft: '0rem',
      marginRight: '0rem',
    },
  },
  tab: {
    flexGrow: 1,
    height: '50px',
    padding: '15px',
    textTransform: 'none',
  },
  tabs: {
    width: '100%',
    minHeight: '100%',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: 'hidden !important',
    marginRight: '1rem',
  },
  container: {
    [theme.breakpoints.down('md')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    [theme.breakpoints.up('md')]: {
      // width: '85vw',
    },
  },
  topRow: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  tabIcon: {
    marginRight: '10px',
    color: '#626466',
  },
  typeIcon: {
    width: '16px',
    marginRight: '1px',
    marginLeft: '-8px',
    marginTop: '2px',
    backgroundColor: 'transparent',
  },
  selectedMenuIcon: {
    height: '16px',
    width: '16px',
    marginRight: '16px',
    marginLeft: '-8px',
    backgroundColor: 'transparent',
  },
  menuIcon: {
    height: '16px',
    width: '16px',
    backgroundColor: 'transparent',
  },
  tabAddSingle: {
    height: '48px',
    width: '48px',
    cursor: 'pointer',
    color: '#626466',
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      backgroundColor: '#D3D3D3',
    },
    boxShadow: '2px 2px 6px 0px rgb(0 0 0 / 10%)',
  },
  tabAdd: {
    height: '48px',
    width: '48px',
    fontSize: '14px',
    cursor: 'pointer',
    borderRadius: '50%',
    color: '#626466',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#f7f7f7',
    },
  },
  tabAddIcon: {
    color: '#626466',
    fontSize: 28,
  },
  mobileTab: {
    height: '75px',
    display: 'flex',
    position: 'sticky',
    width: '100vw',
    backgroundColor: theme.palette.background.dark,
    zIndex: 1000,
    top: 0,
    left: 0,
    padding: '10px',
    marginBottom: '1rem',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  dropdown: {
    backgroundColor: '#fff',
    border: 'none',
    borderRadius: '1rem',
    width: 'calc(100vw - 10rem)',
    marginRight: '10px',
    borderBottom: 'none',
    fontFamily: 'Roboto, Sans-serif',
    color: '#313233',
    fontSize: '15px',
    fontWeight: '500',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
    },
    '& .MuiSelect-select': {
      border: 'none !important',
    },
  },
  tabUnselected: {
    height: '48px',
    width: '48px',
    cursor: 'pointer',
    color: '#626466',
    backgroundColor: theme.palette.background.dark,
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
    },
    boxShadow: '2px 2px 6px 0px rgb(0 0 0 / 10%)',
  },
  leftSecTabs: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  leftSecTab: {
    width: 'calc(50% - 0.75rem)',
    marginLeft: '0px',
    justifyContent: 'center',
    borderRadius: '0.75rem',
    display: 'inline',
    height: '50px',
    fontWeight: '400',
    padding: '15px',
    gap: 10,
    textTransform: 'none',
    '&.MuiTab-root': {
      minHeight: 0,
      verticalAlign: 'middle',
    },
    '&.Mui-selected': {
      backgroundColor: '#FFF',
      borderRadius: '0.75rem',
      fontFamily: 'Roboto, Sans-serif',
      color: '#313233',
      paddingTop: '15px',
      textStroke: '0.3px black',
      WebkitTextStroke: '0.3px black',
      strokeLinejoin: 'round',
      '&:hover': {
        backgroundColor: '#FFF',
      },
    },
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  actionsIcon: {
    height: '20px',
    width: '20px',
  },
  notificationsIcon: {
    height: '18px',
    width: '18px',
  },
});

export default styles;
