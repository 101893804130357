import React, { useContext } from 'react';
import {
  Dialog,
  Grid,
  Typography,
  Input,
  Button,
  IconButton,
  DialogContent,
} from '@mui/material';
import { CgClose } from 'react-icons/cg';
import { makeStyles } from '@mui/styles';
import { PassportContext } from '../../v2api/PassportContext/PassportContext';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    top: 8,
    right: 8,
  },
  button: {
    marginTop: '1rem',
  },
  buttonText: {
    cursor: 'pointer',
    fontSize: '12px',
    fontFamily: 'Roboto,sans-serif',
    fontWeight: '500',
    color: '#626466',
    padding: '0.5rem 2rem',
    backgroundColor: 'transparent',
  },
  inputField: {
    width: '100%',
    marginBottom: '1rem',
  },
  inputFieldHidden: {
    display: 'none',
  },
}));

const IntegrationSuggestion = ({ isOpen, onClose }) => {
  const classes = useStyles();
  const { passport } = useContext(PassportContext);
  if (!passport) {
    return null;
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h2">Suggest an Integration</Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <div class="row">
              <form
                action="https://docs.google.com/forms/d/e/1FAIpQLSev5ZzsMsMPPgzXwTvy6J318tEzOmZ0YwYsZxYWqgmVyQoPwQ/formResponse"
                method="post"
                id="application-form"
                target="_blank"
              >
                <div class="row">
                  <div>
                    <div class="form-group">
                      <Input
                        type="text"
                        name="entry.656097730"
                        defaultValue={`${passport.unverifiedFirstname} ${passport.unverifiedLastname}`}
                        className={classes.inputField}
                        id="entry_656097730"
                        dir="auto"
                        aria-label="Name  "
                        aria-required="false"
                        placeholder="Name"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div>
                    <div class="form-group">
                      <Input
                        type="text"
                        name="entry.1571229401"
                        defaultValue={passport.email}
                        className={classes.inputField}
                        id="entry_1571229401"
                        dir="auto"
                        aria-label="Email  "
                        aria-required="false"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div>
                    <div class="form-group">
                      <Input
                        type="text"
                        name="entry.954022513"
                        className={classes.inputField}
                        id="entry_954022513"
                        dir="auto"
                        aria-label="Suggestion"
                        aria-required="true"
                        placeholder="Suggestion"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div class="form-group">
                    <Button className={classes.button}>
                      <input
                        type="submit"
                        id="submit"
                        className={classes.buttonText}
                        name="submit"
                        value="SUBMIT"
                      />
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </Grid>
        </Grid>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CgClose />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};

export default IntegrationSuggestion;
