import React, { useState, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, Box, Stack } from '@mui/material';
import SkillsIcon from '../../../icons/atomsSkills';
import LanguagesIcon from '../../../icons/languages';
import RolesIcon from '../../../icons/atomsRoles';
import FeaturesIcon from '../../../icons/atomsFeatures';
import InstrumentsIcon from '../../../icons/instruments';
import ToolsAndGearIcon from '../../../icons/toolsAndGear';
import InspirationsIcon from '../../../icons/inspirations';
import FavoriteArtistsIcon from '../../../icons/favoriteArtist';
import EndorsementIcon from '../../../icons/endorsement';
import GenresIcon from '../../../icons/genres';
import SupportedCauses from '../../../icons/supportedCauses';
import QuotesIcon from '../../../icons/quotes';
import Plus from '../../../icons/atomsIconsAdd';
import InfoField from './InfoField';
import styles from './styles';
import IconTextButton from '../../../components/IconTextButton';
import CMSTitleInfo from '../Title';
import PersonalInfoEdit from './PersonalInfoEdit';
import { PersonaContext } from '../../../v2api/PersonaContext/PersonaContext';
import { PersonaAccessContext } from '../../../v2api/PersonaAccessContext/PersonaAccessContext';

const useStyles = makeStyles(styles);

const PersonalInfo = () => {
  const { persona } = useContext(PersonaContext);
  const { personaAccess } = useContext(PersonaAccessContext);
  const [editorOpen, setEditorOpen] = useState(false);

  const hasContent =
    persona?.features?.length ||
    persona?.toolsUsed?.length ||
    persona?.toolsOwned?.length ||
    persona?.instrumentsPlayed?.length ||
    persona?.instrumentsOwned?.length ||
    persona?.musicRoles?.length ||
    persona?.otherSkills?.length ||
    persona?.genres?.length ||
    persona?.favouriteMusicMakers?.length ||
    persona?.endorsements?.length ||
    persona?.interestsInspiration?.length ||
    persona?.charitiesSupported?.length ||
    persona?.favouriteQuote?.length ||
    persona?.spokenLanguages?.length;
  const isEmpty = !hasContent;

  const classes = useStyles();

  return (
    <>
      <PersonalInfoEdit
        isOpen={editorOpen}
        closeModal={() => setEditorOpen(false)}
      />
      {/* Profile InfoField Display */}
      <div className={classes.root}>
        <CMSTitleInfo
          title="Profile Info"
          isNotEmpty={!isEmpty}
          message="Edit profile information"
          onClick={() => setEditorOpen(true)}
        />

        {isEmpty ? (
          <Box
            className={classes.empty}
            data-testid={`empty-${persona?.personaId}-display`}
          >
            <Stack
              gap={4}
              direction="column"
              alignItems="center"
              justifyContent="center"
              height="250px"
            >
              <div className={classes.emptyIcons}>
                <SkillsIcon />
                <InstrumentsIcon />
                <ToolsAndGearIcon />
              </div>
              <Typography variant="body1">
                Add Skills, Instruments, Tools, Gear and other info that define
                your uniqueness
              </Typography>
              <IconTextButton
                data-testid="add-data-button"
                onClick={() => {
                  setEditorOpen(true);
                }}
                size="standard"
                icon={<Plus hasGradient={true} sx={{ fontSize: 18 }} />}
              >
                Add your profile info
              </IconTextButton>
            </Stack>
          </Box>
        ) : (
          <div className={classes.main}>
            {!persona.isProfessionalProfile && persona.features?.length ? (
              <InfoField
                Icon={FeaturesIcon}
                title="keywords"
                values={persona.features}
                isVisible={personaAccess?.features}
              />
            ) : null}
            {persona.musicRoles?.length ? (
              <InfoField
                Icon={RolesIcon}
                title="roles"
                values={persona.musicRoles}
                isVisible={personaAccess?.musicRoles}
              />
            ) : null}
            {persona.otherSkills?.length ? (
              <InfoField
                Icon={SkillsIcon}
                title="skills"
                values={persona.otherSkills}
                isVisible={personaAccess?.otherSkills}
              />
            ) : null}
            {persona.instrumentsPlayed?.length ? (
              <InfoField
                Icon={InstrumentsIcon}
                title="instruments used"
                values={persona.instrumentsPlayed}
                isVisible={personaAccess?.instrumentsPlayed}
              />
            ) : null}
            {persona.instrumentsOwned?.length ? (
              <InfoField
                Icon={InstrumentsIcon}
                title="instruments owned"
                values={persona.instrumentsOwned}
                isVisible={personaAccess?.instrumentsOwned}
              />
            ) : null}
            {persona.toolsUsed?.length ? (
              <InfoField
                Icon={ToolsAndGearIcon}
                title="tools and gear used"
                values={persona.toolsUsed}
                isVisible={personaAccess?.toolsUsed}
              />
            ) : null}
            {persona.toolsOwned?.length ? (
              <InfoField
                Icon={ToolsAndGearIcon}
                title="tools and gear owned"
                values={persona.toolsOwned}
                isVisible={personaAccess?.toolsOwned}
              />
            ) : null}
            {persona?.favouriteMusicMakers?.length ? (
              <InfoField
                Icon={FavoriteArtistsIcon}
                title="favorite artists"
                values={persona.favouriteMusicMakers}
                isVisible={personaAccess?.favouriteMusicMakers}
              />
            ) : null}
            {persona.genres?.length ? (
              <InfoField
                Icon={GenresIcon}
                title="genres"
                values={persona.genres}
                isVisible={personaAccess?.genres}
              />
            ) : null}
            {persona.spokenLanguages?.length ? (
              <InfoField
                Icon={LanguagesIcon}
                title="spoken languages"
                values={persona.spokenLanguages}
                isVisible={personaAccess?.spokenLanguages}
              />
            ) : null}
            {persona.endorsements?.length ? (
              <InfoField
                Icon={EndorsementIcon}
                title="endorsements"
                values={persona.endorsements}
                isVisible={personaAccess?.endorsements}
              />
            ) : null}
            {persona.interestsInspiration?.length ? (
              <InfoField
                Icon={InspirationsIcon}
                title="interests / inspirations"
                values={persona.interestsInspiration}
                isVisible={personaAccess?.interestsInspiration}
              />
            ) : null}
            {persona.charitiesSupported?.length ? (
              <InfoField
                Icon={SupportedCauses}
                title="supported causes"
                values={persona.charitiesSupported}
                isVisible={personaAccess?.charitiesSupported}
              />
            ) : null}
            {persona.favouriteQuote?.length ? (
              <InfoField
                Icon={QuotesIcon}
                title="quote"
                values={persona.favouriteQuote}
                isVisible={personaAccess?.favouriteQuote}
              />
            ) : null}
          </div>
        )}
      </div>
    </>
  );
};

export default PersonalInfo;
