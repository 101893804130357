import { Typography } from '@mui/material';
import React from 'react';
import { useAuth, hasAuthParams } from 'react-oidc-context';
import { Navigate } from 'react-router-dom';


const SigninCallback = () => {
  const auth = useAuth();
  const [hasTriedSignin, setHasTriedSignin] = React.useState(false);

  // automatically sign-in
  React.useEffect(() => {
    if (!hasAuthParams() &&
      !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading &&
      !hasTriedSignin
    ) {
      auth.signinRedirect();
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin]);

  if (auth.isLoading) {
    return (
      <div>
        <Typography variant="h2">Processing Signin</Typography>
      </div>
    );
  }

  if (auth.isAuthenticated) {
    return <Navigate to="/" />;
  }

  return "unknown-state";
};

export default SigninCallback;
