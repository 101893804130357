import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <linearGradient id="Gradient1">
        <stop class="stop1" offset="0%" stopColor="#FF2667" />
        <stop class="stop2" offset="51%" stopColor="#73AEE6" />
        <stop class="stop3" offset="100%" stopColor="#00FFCC" />
      </linearGradient>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="url(#Gradient1)">
          <path d="M11 2L11 11 2 11 2 13 11 13 11 22 13 22 13 13 22 13 22 11 13 11 13 2z"></path>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
