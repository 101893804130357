import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 24 24">
      <title>Your Information is private. Click to make public.</title>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Atoms/icons/private/Active" fill="#626466">
          <path
            d="M20.2926,2 L21.7066,3.414 L3.7066,21.414 L2.2926,20 L20.2926,2 Z M19.8609,6.6738 L24.0039,11.7068 L19.4889,17.1928 L12.0039,19.7068 L8.1299,18.4048 L9.4199,17.1148 C10.2029,17.4898 11.0769,17.7068 12.0039,17.7068 C15.3169,17.7068 18.0039,15.0208 18.0039,11.7068 C18.0039,10.7798 17.7909,9.9028 17.4149,9.1198 L17.4149,9.1198 L19.8609,6.6738 Z M12.0038,3.7069 L15.8718,5.0069 L13.6198,7.2589 L11.9998,6.7149 L7.0028,8.3939 C6.3728,9.3429 6.0038,10.4809 6.0038,11.7069 C6.0038,12.6319 6.2188,13.5039 6.5928,14.2859 L6.5928,14.2859 L4.1418,16.7359 L1.2398,13.2089 L1.2378,13.2109 L-0.0002,11.7069 L4.5138,6.2219 L11.9998,3.7069 L12.0038,3.7069 Z"
            id="Combined-Shape"
          ></path>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
