import { alpha } from '@mui/system';

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 72,
    width: '100%',
    [theme.breakpoints.only('xl')]: {
      paddingRight: '4rem',
      paddingLeft: '4rem',
    },
    [theme.breakpoints.only('lg')]: {
      paddingRight: '4rem',
      paddingLeft: '4rem',
    },
    [theme.breakpoints.only('md')]: {
      paddingRight: '4rem',
      paddingLeft: '4rem',
    },
    [theme.breakpoints.only('sm')]: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
    [theme.breakpoints.only('xs')]: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
  },
  linkIcon: {
    backgroundColor: 'transparent',
    height: '24px',
    width: '24px',
    //backgroundColor: '#FFFFFF',
    boxShadow: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0.5rem',
    [theme.breakpoints.down('md')]: {
      borderRadius: '0.75rem',
    },
  },
  links: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      gap: '2rem',
      justifyContent: 'space-between',
    },
    [theme.breakpoints.only('sm')]: {
      width: '50%',
    },
    [theme.breakpoints.only('xs')]: {
      gap: '1.5rem',
    },
    '@media (max-width: 370px)': {
      gap: '0.5rem',
    },
  },
  link: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: '40px',
    color: theme.palette.primary.hint,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.hint, 0.05),
      border: `2px solid ${alpha(theme.palette.primary.hint, 0.1)}`,
    },
    border: '2px solid transparent',
    borderRadius: '0.75rem',
    fontSize: '14px',
    fontWeight: '400',
    cursor: 'pointer',
    zIndex: 5,
    gap: 5,
    paddingRight: '0.8rem',
    paddingLeft: '0.4rem',
    [theme.breakpoints.down('md')]: {
      paddingRight: '0.4rem',
      paddingLeft: '0.4rem',
    },
  },

  active: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    height: '40px',
    color: '#313233',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '0.75rem',
    fontSize: '14px',
    textStroke: '0.3px',
    WebkitTextStroke: '0.3px',
    strokeLinejoin: 'round',
    zIndex: 5,
    cursor: 'pointer',
    paddingRight: '0.8rem',
    paddingLeft: '0.4rem',
    [theme.breakpoints.down('md')]: {
      paddingRight: '0.4rem',
      paddingLeft: '0.4rem',
    },
    border: '2px solid transparent',
    [theme.breakpoints.only('xs')]: {
      background:
        'linear-gradient(315deg, #FF2667 0%, #73AEE6 49%, #00FFCC 100%) border-box',
    },

    [theme.breakpoints.only('sm')]: {
      background:
        'linear-gradient(315deg, #FF2667 0%, #73AEE6 49%, #00FFCC 100%) border-box',
    },

    [theme.breakpoints.up('md')]: {
      backgroundSize: '280px 1px',
      background:
        'linear-gradient(315deg, #00FFCC 0%, #73AEE6 25%, #FF2667 50%, #73AEE6 75%, #00FFCC 100%) border-box',
      transition: 'background 0.3s ease',
      '&:hover': { backgroundPosition: '-140px' },
    },

    //'-webkit-mask':
    //  ' linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)',
    //'-webkit-mask-composite': 'destination-out',
    //'mask-composite': 'exclude',
    '&::after': {
      content: "''",
      position: 'absolute',
      height: '100%',
      width: '100%',
      zIndex: -1,
      top: 0,
      left: 0,
      backgroundColor: theme.palette.background.paper,
      borderRadius: '11px',
    },
  },

  linkText: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },

  searchBox: {
    backgroundColor: '#fff',
    height: '40px',
    width: '114px',
    borderRadius: '2rem',
    boxShadow: '0.67px 0.67px 4px 0 rgba(0,0,0,0.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 5,
    padding: '12px 46px 12px 24px',
    marginRight: '1rem',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },

  mobileSearchIcon: {
    marginRight: '1rem',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '3rem',
    height: '40px',
    width: '40px',
    boxShadow: '2px 2px 6px 0px rgba(0,0,0,0.1)',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  searchInput: {
    border: 'none',
    '&::after, &::before, &:hover, &:focus': {
      border: 'none',
    },
  },

  accountMenu: {
    boxShadow: '2px 2px 6px 0px rgba(0,0,0,0.1)',
    backgroundColor: theme.palette.background.paper,
    height: '42px',
    width: '42px',
    borderRadius: '999px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuitem: {
    justifyContent: 'right',
    paddingRight: '2px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  menugeneral: {
    paddingTop: '50px',
  },
  menuitemicon: {
    height: '49px',
  },
});

export default styles;
