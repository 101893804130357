import { DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Button from '../Button';
import Checkmark from '../../icons/tick';

const useStyles = makeStyles((theme) =>
  createStyles({
    icon: {
      textAlign: 'center',
      width: '100%',
    },
    saveButton: {
      backgroundColor: '#FFFFFF',
      paddingLeft: '4px',
      fontSize: 12,
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      gap: 6,
      [theme.breakpoints.down('sm')]: {
        padding: '0',
      },
    },
  }),
);

function Success({ onClose }) {
  const classes = useStyles();
  return (
    <>
      <DialogContent>
        <div>
          <CheckCircleIcon htmlColor="#4BB543" className={classes.icon} />
        </div>
        <DialogContentText>
          You have successfully checked into the session.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button size="large" onClick={onClose}>
          <Checkmark sx={{ fontSize: 12 }} />
          Close
        </Button>
      </DialogActions>
    </>
  );
}
export default Success;
