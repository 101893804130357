import React, { useState, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, Box, Stack } from '@mui/material';
import styles from './styles';
import IconTextButton from '../../../components/IconTextButton';
import Plus from '../../../icons/atomsIconsAdd';
import ReadOnlyOfficialLink from './ReadOnlyOfficialLink';
import OfficialLinkIcon from '../../../icons/officialLink';
import CMSTitleInfo from '../Title';

import { PersonaContext } from '../../../v2api/PersonaContext/PersonaContext';
import { PersonaAccessContext } from '../../../v2api/PersonaAccessContext/PersonaAccessContext';
import EditOfficialLinks from './EditOfficialLinks';

const useStyles = makeStyles(styles);

const OfficialLinks = () => {
  const classes = useStyles();

  const { persona } = useContext(PersonaContext);
  const { personaAccess, linkAttrAccess, updateAccess } =
    useContext(PersonaAccessContext);
  const [editorOpen, setEditorOpen] = useState(false);

  const linkList = persona?.officialLinks || [];

  return (
    <div className={classes.root}>
      <EditOfficialLinks
        isOpen={editorOpen}
        closeModal={() => setEditorOpen(false)}
      />
      <CMSTitleInfo
        title="Official Links"
        isNotEmpty={linkList.some((el) => el.fieldName)}
        message="Edit Official Links"
        onClick={() => setEditorOpen(true)}
        isVisible={personaAccess?.officialLinks}
        setVisible={(v) =>
          updateAccess({
            method: 'PATCH',
            payload: { officialLinks: v },
          })
        }
        isVisibilityDisabled={!personaAccess?.personaId}
      />
      {linkList.some((el) => el.fieldName) ? (
        <Box className={classes.items}>
          {linkList.map((oLink) => (
            <div key={oLink.uniqueKey}>
              <ReadOnlyOfficialLink
                key={oLink.uniqueKey}
                customAttr={oLink}
                isVisible={linkAttrAccess?.[oLink.uniqueKey]}
              />
            </div>
          ))}
        </Box>
      ) : (
        <Box className={classes.empty}>
          <Stack
            gap={4}
            direction="column"
            alignItems="center"
            justifyContent="center"
            height="250px"
          >
            <OfficialLinkIcon sx={{ width: '32px' }} />
            <Typography variant="body1">
              Make sure your official links are always available and up to date
            </Typography>
            <IconTextButton
              onClick={() => setEditorOpen(true)}
              size="standard"
              icon={<Plus hasGradient={true} sx={{ fontSize: 18 }} />}
            >
              Add your official links
            </IconTextButton>
          </Stack>
        </Box>
      )}
    </div>
  );
};

export default OfficialLinks;
