import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, Box, IconButton } from '@mui/material';
import { AiOutlineArrowsAlt, AiOutlineArrowRight } from 'react-icons/ai';
import { TbBrandDiscord, TbIdBadge, TbPencil } from 'react-icons/tb';
import ProfileTypes from '../../../pages/modals/ProfileTypes';
import useDisclosure from '../../../old/hooks/useDisclosure';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1.5rem',
    marginTop: '1.5rem',
  },
  iconUp: {
    cursor: 'default',
    backgroundColor: '#FFD4E1',
    alignSelf: 'flex-start',
    '&:hover': {
      backgroundColor: '#FFD4E1',
    },
  },
  iconDown: {
    backgroundColor: '#FFF',
    alignSelf: 'flex-end',
    boxShadow: '2px 2px 6px 0px rgba(0,0,0,0.1)',
  },
  iconDownLink: {
    color: 'currentColor',
    alignSelf: 'flex-end',
  },
  card: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '0.9rem',
    backgroundColor: theme.palette.background.dark,
    borderRadius: '0.75rem',
  },
  textContent: {
    flexGrow: 1,
    padding: '10px',
  },
}));

const WhatElseCanYouDo = (props) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Typography variant="h4">What else can you do?</Typography>
      <Card2 />
      <Card1 />
      <Card3 />
    </Box>
  );
};

export default WhatElseCanYouDo;

const Card1 = () => {
  const classes = useStyles();
  return (
    <Box className={classes.card}>
      <IconButton disableRipple size="small" className={classes.iconUp}>
        <TbBrandDiscord color="#FF3575" />
      </IconButton>
      <Box className={classes.textContent}>
        <Typography variant="h5">Join our Discord</Typography>
        <Typography variant="subtitle1">
          Learn about new features, follow developement, access resources, and
          connect with fellow creatives.
        </Typography>
      </Box>
      <a
        className={classes.iconDownLink}
        target="_blank"
        rel="noopener noreferrer"
        href="https://discord.gg/ZJABRw4yYe"
      >
        <IconButton size="small" className={classes.iconDown}>
          <AiOutlineArrowRight />
        </IconButton>
      </a>
    </Box>
  );
};

const Card2 = () => {
  const classes = useStyles();
  const { isOpen, onOpen, onClose } = useDisclosure(false);
  return (
    <Box className={classes.card}>
      <IconButton disableRipple size="small" className={classes.iconUp}>
        <TbIdBadge color="#FF3575" />
      </IconButton>
      <Box className={classes.textContent}>
        <Typography variant="h5">Add an Artistic Profile</Typography>
        <Typography variant="subtitle1">
          Now you can setup artistic profiles for your solo projects, bands,
          collectives, and more.
        </Typography>
      </Box>
      <Box className={classes.iconDownLink} onClick={onOpen}>
        <IconButton size="small" className={classes.iconDown}>
          <AiOutlineArrowsAlt />
        </IconButton>
      </Box>
      <ProfileTypes isOpen={isOpen} onClose={onClose} />
    </Box>
  );
};

const Card3 = () => {
  const classes = useStyles();
  return (
    <Box className={classes.card}>
      <IconButton disableRipple size="small" className={classes.iconUp}>
        <TbPencil color="#FF3575" />
      </IconButton>
      <Box className={classes.textContent}>
        <Typography variant="h5">Add More Details About Yourself</Typography>
        <Typography variant="subtitle1">
          Check out the new fields in your profile info and personal summary.
          You can add even more details about yourself or your artistic
          profiles.
        </Typography>
      </Box>
    </Box>
  );
};
