import React, { useState, useContext } from 'react';
import { Grid, Box, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PassportIcon from '../../../icons/idIcon';
import IconTextButton from '../../../components/IconTextButton';
import Plus from '../../../icons/atomsIconsAdd';
import { IndustryIdReadOnly } from './IndustryIdReadOnly';
import CMSTitleInfo from '../Title';
import EditIDs from './EditIDs';
import { PersonaContext } from '../../../v2api/PersonaContext/PersonaContext';
import { PersonaAccessContext } from '../../../v2api/PersonaAccessContext/PersonaAccessContext';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0.5rem',
      marginRight: '1rem',
    },
  },
  main: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: '1rem',
    borderRadius: '18px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0',
    },
  },
}));

const IDs = () => {
  const classes = useStyles();
  const { persona } = useContext(PersonaContext);
  const { personaAccess, idAttrAccess, updateAccess } =
    useContext(PersonaAccessContext);
  const [editorOpen, setEditorOpen] = useState(false);

  const nIds =
    !!persona?.unverifiedIpi +
    !!persona?.unverifiedIpn +
    !!persona?.unverifiedIsni +
    persona?.customIds?.length;

  return (
    <>
      <EditIDs isOpen={editorOpen} closeModal={() => setEditorOpen(false)} />
      <Box className={classes.root}>
        <CMSTitleInfo
          title="IDs"
          isNotEmpty={nIds > 0}
          message="Edit IDs"
          onClick={() => setEditorOpen(true)}
          isVisible={
            personaAccess?.customIds ||
            personaAccess?.unverifiedIpi ||
            personaAccess?.unverifiedIpn ||
            personaAccess?.unverifiedIpn
          }
          setVisible={(v) => {
            if (v === true) {
              updateAccess({ method: 'PATCH', payload: { customIds: true } });
            } else {
              updateAccess({
                method: 'PATCH',
                payload: {
                  customIds: false,
                  unverifiedIpn: false,
                  unverifiedIpi: false,
                  unverifiedIsni: false,
                },
              });
            }
          }}
          isVisibilityDisabled={!personaAccess?.personaId}
        />
        {nIds ? (
          <Grid container spacing={2}>
            {persona?.unverifiedIsni && (
              <Grid item xs={12} sm={nIds <= 2 ? 12 : 6}>
                <IndustryIdReadOnly
                  fieldName={'isni'}
                  value={persona.unverifiedIsni}
                  isVisible={personaAccess?.unverifiedIsni || false}
                />
              </Grid>
            )}
            {persona?.unverifiedIpn && (
              <Grid item xs={12} sm={nIds <= 2 ? 12 : 6}>
                <IndustryIdReadOnly
                  fieldName={'ipn'}
                  value={persona.unverifiedIpn}
                  isVisible={personaAccess?.unverifiedIpn || false}
                />
              </Grid>
            )}
            {persona?.unverifiedIpi && (
              <Grid item xs={12} sm={nIds <= 2 ? 12 : 6}>
                <IndustryIdReadOnly
                  fieldName={'ipi'}
                  value={persona.unverifiedIpi}
                  isVisible={personaAccess?.unverifiedIpi || false}
                />
              </Grid>
            )}
            {persona.customIds?.length
              ? persona.customIds.map((cid) => (
                  <Grid item xs={12} sm={nIds <= 2 ? 12 : 6}>
                    <IndustryIdReadOnly
                      fieldName={cid.fieldName}
                      value={cid.value}
                      isVisible={idAttrAccess?.[cid.uniqueKey]}
                    />
                  </Grid>
                ))
              : null}
          </Grid>
        ) : (
          <Box className={classes.main}>
            <Stack
              gap={4}
              direction="column"
              alignItems="center"
              justifyContent="center"
              height="250px"
            >
              <PassportIcon sx={{ width: '32px' }} />
              <Typography variant="body1">
                If you have any music IDs such as ISNI or IPN you can list them
                here
              </Typography>
              <IconTextButton
                onClick={() => setEditorOpen(true)}
                size="standard"
                icon={<Plus hasGradient={true} sx={{ fontSize: 18 }} />}
              >
                Add your ids
              </IconTextButton>
            </Stack>
          </Box>
        )}
      </Box>
    </>
  );
};

export default IDs;
