import React, { Suspense, useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import * as pages from '../pages';
import AuthedPagesWrapper from './AuthedPagesWrapper'; // @deprecate
import GetInvolved from '../pages/GetInvolved';
import { PassportProvider } from '../v2api/PassportContext/PassportContext.tsx';
import CMSPrivateView from '../pages/CMS';
import IntegrationsPage from '../pages/integrations';
import { useAuth } from 'react-oidc-context';
import NotFoundPage from '../pages/pageNotFound';
import { useLocation } from 'react-router';

// FIXME
// const privateRoutes = [
//   { path: '/yoti/callback', component: pages.yotiCallback },
//   { path: '/verifications/ipn', component: pages.pplCallback },
//   { path: '/settings', component: pages.settings },
//   { path: '/integrations', component: pages.integrations },
//   { path: '/get_involved', component: GetInvolved },
//   { path: '/services', component: ServicesPage },
//   { path: '/support', component: SupportPage },
//   { path: '/deleted', component: AccountToBeDeleted, allowDeleted: true },
//   { path: '/account_restored', component: AccountRestored },
// ];
const SignedInRoutes = () => {
  const auth = useAuth();
  const location = useLocation();
  if (auth.isLoading) {
    return 'Loading';
  }

  if (!auth.isAuthenticated) {
    return <Navigate to="/welcome" state={{ from: location }} replace />;
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <PassportProvider auth={auth}>
        <AuthedPagesWrapper>
          <Routes>
            <Route path="/" element={<CMSPrivateView />} />
            <Route exact path="/integrations" element={<IntegrationsPage />} />
            <Route exact path="/get_involved" element={<GetInvolved />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </AuthedPagesWrapper>
      </PassportProvider>
    </Suspense>
  );
};

export default SignedInRoutes;
