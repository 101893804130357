import { Stack, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import Delete from '../../../icons/atomsIconsDelete';
import CPVisibleToggle from '../../CPVisibleToggle';

export const EditProject = ({
  fieldName,
  value,
  handleChange,
  onDelete,
  isVisible,
  setVisible,
  areProjectsHidden,
}) => {
  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <TextField
          variant="standard"
          label={'Project Name'}
          type="text"
          value={fieldName}
          onKeyDown={(e) => e.stopPropagation()} // fixes a dnd-kit bug
          onChange={(e) => handleChange({ fieldName: e.target.value })}
          placeholder={''}
          sx={{
            width: '100%',
          }}
        />

        <Stack direction="row" spacing={1.5} alignItems="center">
          <CPVisibleToggle
            darker={true}
            isVisible={isVisible}
            setVisible={setVisible}
            isDisabled={areProjectsHidden}
          />
          <Box
            sx={{
              backgroundColor: 'white',
              boxShadow: '2px 2px 6px 0px rgba(0,0,0,0.1)',
              height: '35px',
              width: '35px',
              borderRadius: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              '&:hover': {
                backgroundColor: '#D3D3D3',
              },
            }}
            onClick={() => onDelete()}
          >
            <Delete
              sx={{
                fontSize: '40px',
                cursor: 'pointer',
              }}
            />
          </Box>
        </Stack>
      </Stack>

      <TextField
        type="text"
        label={'Project Description'}
        variant="standard"
        multiline
        value={value}
        onKeyDown={(e) => e.stopPropagation()} // fixes a dnd-kit bug
        onChange={(e) => handleChange({ value: e.target.value })}
        placeholder={''}
        sx={{
          width: '100%',
          mt: 3,
        }}
      />
    </>
  );
};

export default EditProject;
