import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import EditProject from './EditProject';
import { useState } from 'react';
import { Box } from '@mui/system';

const DraggableProjectItem = ({
  id,
  projectData,
  handleChange,
  handleDelete,
  isVisible,
  setVisible,
  areProjectsHidden,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const [isDragging, setIsDragging] = useState(false);

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Box
        onMouseDown={() => setIsDragging(true)}
        onMouseUp={() => setIsDragging(false)}
        sx={[
          {
            backgroundColor: '#F7F8F9',
            padding: 2,
            borderRadius: 2,
            marginBottom: '1rem',
          },
          isDragging
            ? {
                cursor: 'grabbing',
              }
            : {
                cursor: 'grab',
              },
        ]}
      >
        <EditProject
          fieldName={projectData.fieldName}
          value={projectData.value}
          handleChange={(patch) =>
            handleChange({ ...patch, uniqueKey: projectData.uniqueKey })
          }
          onDelete={() => handleDelete(projectData.uniqueKey)}
          isVisible={isVisible}
          setVisible={setVisible}
          areProjectsHidden={areProjectsHidden}
        />
      </Box>
    </div>
  );
};

export default DraggableProjectItem;
