import React from 'react';
import { SvgIcon } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

function Icon({ hasGradient, sx, ...rest }) {
  const gradientId = uuidv4();
  return (
    <SvgIcon
      sx={[
        {
          ...sx,
        },
        hasGradient
          ? {
              '&': {
                g: {
                  fill: `url(#gradient-${gradientId})`,
                },
              },
            }
          : {
              '&': {
                g: {
                  fill: '',
                },
              },
            },
      ]}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      viewBox="0 0 24 24"
    >
      {hasGradient && (
        <defs>
          <linearGradient
            id={`gradient-${gradientId}`}
            gradientTransform="rotate(10)"
          >
            <stop offset="0%" stopColor="#FF2667" />
            <stop offset="51%" stopColor="#73AEE6" />
            <stop offset="100%" stopColor="#00FFCC" />
          </linearGradient>
        </defs>
      )}
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#626466">
          <path d="M10.617 1L8.361 3.259l-.687.687-.688.687.235.238 1.274 1.271.435.437v1.61L7.79 9.327H6.181l-.436-.435-1.27-1.272-.236-.236-.688.688-.687.687L1 10.625v2.75l2.06 2.062-1.825 1.827v3.222l2.276 2.278H6.748l1.817-1.817L10.617 23h2.766l1.865-1.867.687-.687.686-.687-.236-.238-1.536-1.536-.17-.171-.008.009-.01-.009v-.954l.018-.018v-.639l1.137-1.14h1.593l.172.172 1.536 1.538.235.235.009-.008.008.008.687-.687.687-.688L23 13.376v-2.75L21.057 8.68l.004.021-.138-.139 1.825-1.827V3.514L20.47 1.236h-3.219l-1.825 1.827L13.365 1h-2.748zM8.596 11.272l2.277-2.278v-3.22l-1.139-1.14 2.257-2.258 2.748 2.75v-.002l.688.688.687-.687.236-.236.993-.995.713-.713h1.61l1.138 1.14v1.61l-.713.714-.995.994-.235.236-.687.686.688.688 2.157 2.16-1.761 1.765-.496.494-.548-.549-2.695-.48-1.395-.236-2.041 2.042.635 4.174.357.359-1.91 1.82-1.234-1.236-.437-.436-1.41-1.414-2.286 2.411H4.774l-1.596-.442v-1.612l.57-.57 1.138-1.138.235-.236.688-.69-1.374-1.374-.002.001-2.06-2.061 1.866-1.868 1.137 1.139h3.22z"></path>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
