import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 24 24">
      <title>Search</title>
      <defs>
        <linearGradient
          x1="78.125%"
          y1="100%"
          x2="21.9264264%"
          y2="0.0914246886%"
          id="linearGradient-1"
        >
          <stop stop-color="#FF2667" offset="0.0914246886%"></stop>
          <stop stop-color="#73AEE6" offset="50.9814282%"></stop>
          <stop stop-color="#00FFCC" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="UI@3x"
          transform="translate(0.000000, -0.000000)"
          fill="url(#linearGradient-1)"
          fillRule="nonzero"
        >
          <path
            d="M5.271,0 L1,4.27011039 L0,5.2709019 L0,12.7253489 L5.272,17.995251 L12.727,17.995251 L13.587,17.1374297 L20.446,23.9940012 L22.952,24 L23.818,23.1251823 L24,22.9412206 L15.89,14.8339096 L18,12.7253489 L18,11.7255572 L18,10.7257655 L18,5.2709019 L12.728,0 L5.271,0 Z M2,10.7257655 L2,6.09872943 L6.101,1.99858363 L11.899,1.99858363 L16,6.09872943 L16,11.8965216 L12.899,14.9958759 L6.272,14.9958759 L2,10.7257655 Z M11.2304646,3.49722977 L16,8.21543649 L16,11.9 L12.9,15 L12,13.5 L6.9,13.5 L3.61,10.21 L2,10.73 L2,6.1 L4.60466789,3.49722977 L11.2304646,3.49722977 Z M15.89,14.8339096 L15.707,15.0168715 L15.89,14.8339096 Z"
            id="Fill-1"
          ></path>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
