const styles = (theme) => ({
  root: {
    border: 'none',
    borderRadius: '16px',
    backgroundColor: '#F7F8F9',
    padding: theme.spacing(1.5),
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#626466',
    fontWeight: '500',
    fontFamily: 'Roboto, Sans-serif',
    marginBottom: theme.spacing(1.5),
  },
  headingText: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '22px',
    gap: theme.spacing(1),
  },
  headingTextIcon: {
    fontSize: '20px',
  },
  headingTextP: {
    fontSize: '16px',
    textTransform: 'capitalize',
    margin: 0,
  },
  chip: {
    '& .MuiChip-root': {
      padding: '4px 6px',
      border: 'solid 2px #00FFCC',
      backgroundColor: '#00FFCC30',
      borderRadius: '4px',
      color: '#181919',
      fontSize: '14px',
      fontWeight: '500',
    },
    '& .MuiChip-deleteIcon': {
      border: 'none',
    },
  },
  description: {
    margin: 0,
    fontSize: '13px',
    color: '#999999',
    marginBottom: theme.spacing(1),
  },
  titles: {
    marginTop: theme.spacing(2),
    borderBottom: 'solid 1px #8c8c8c',
    paddingBottom: '5px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  title: {
    margin: 0,
    color: '#fff',
    backgroundColor: '#8c8c8b',
    padding: '2px 7px',
    borderRadius: '6px',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  titleP: {
    margin: 0,
    textTransform: 'capitalize',
    fontSize: '14px',
  },
  suggestions: {
    marginBottom: theme.spacing(3),
  },
  suggestion: {
    margin: 0,
    color: '#a4a4a3',
    backgroundColor: '#f5f4f6',
    border: '1px solid #73AEE6',
    padding: '2px 7px',
    borderRadius: '6px',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    cursor: 'pointer',
  },
  suggestionP: {
    margin: 0,
    textTransform: 'capitalize',
    color: '#5079A1',
    fontSize: '14px',
  },
  suggestionsTitle: {
    color: '#5079A1',
    display: 'flex',
    textTransform: 'capitalize',
    fontWeight: '700',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  suggestionsFlex: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  subHeading: {
    margin: 0,
    color: '#d3d2d2',
    textTransform: 'uppercase',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
});
export default styles;
