import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import { useEffect, useState } from 'react';
import {useNavigate} from 'react-router-dom';


const ProgressTimeout = ({ timeoutMs, redirect }) => {
  const navigate = useNavigate();
  if (!redirect) {
    redirect = "/error";
  }

  const [timedOut, setTimedOut] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setTimedOut(true);
    }, timeoutMs);
  }, []);

  if (timedOut) {
    navigate(redirect);
  }
  return (
    <CircularProgress />
  );

}

export default ProgressTimeout;
