import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  return (
    <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#5079A1">
          <path d="M12 0l8.485 3.515L24 12.001l-3.515 8.485L12 24l-8.484-3.514L0 12.001l3.516-8.486L12 0zm0 2.165L6.054 4.628 3 12.001l3.054 7.371L12 21.835l6.954-2.88 2.881-6.954-2.881-6.956L12 2.165zM13 16v2h-2v-2h2zm-.684-10.948l3 1L16 7v3l-.293.707L13 13.415V15h-2v-2l.293-.707 2.708-2.707V7.72l-2-.666-2 .666V10H8V7l.683-.95 3-1h.633z"></path>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
