import React, { useState, useContext, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import styles from './styles';
import PersonaPrivateView from '../../CMS/components/PersonaPrivateView';
import Connections from '../../CMS/components/Connections';
import ProgressTimeout from '../../components/ProgressTimeout';

import BandIcon from '../../icons/atomDrum';
import SoloIcon from '../../icons/atomSoloProject';
import OrchestraIcon from '../../icons/atomOrchestra';
import OtherIcon from '../../icons/atomPuzzle';
import CollectiveIcon from '../../icons/atomGroup';
import ProfileIcon from '../../icons/atomProfile';

import IconTextButton from '../../components/IconTextButton';
import { MdBolt } from 'react-icons/md';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import ProfileCompletion from '../../CMS/components/ProfileCompletion';
import PublicPageVisualization from '../../CMS/components/PublicPageVisualization';
import WhatElseCanYouDo from '../../CMS/components/WhatElseCanYouDo';
import GuidanceAndSupport from '../../components/GuidanceAndSupport';
import AtomsNotification from '../../icons/atomsNotification';
import AtomsNotificationSelected from '../../icons/atomsNotificationSelected';
import AtomsThunderSelected from '../../icons/atomsThunderSelected';
import AtomsThunder from '../../icons/atomsThunder';

import { PersonaProvider } from '../../v2api/PersonaContext/PersonaContext.tsx';
import { PassportContext } from '../../v2api/PassportContext/PassportContext.tsx';
import { useAuth, withAuthenticationRequired } from 'react-oidc-context';

const useStyles = makeStyles(styles);

const TabContent1 = () => {
  // Content for tab 1
  return (
    <Box mb={4} sx={{ width: '100vw', px: 2 }}>
      <ProfileCompletion />
      <PublicPageVisualization />
      <WhatElseCanYouDo />
      <GuidanceAndSupport />
    </Box>
  );
};

const TabContent2 = () => {
  // Content for tab 2
  return (
    <Box mb={4} sx={{ width: '100vw', px: 2 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'background.paper',
          p: 3,
          borderRadius: '16px',
          width: '100%',
        }}
      >
        <Box
          component="img"
          src="/images/icon/gear.svg"
          sx={{ width: 70, height: 70 }}
        />
        <Typography sx={{ fontWeight: 800, color: '#000', fontSize: 18 }}>
          Work in progress
        </Typography>
        <Typography sx={{ fontSize: 14, textAlign: 'center' }}>
          This functionality is still under construction
        </Typography>
      </Box>
    </Box>
  );
};

const CMSPrivateView = () => {
  const auth = useAuth();
  const {
    passport,
    updatePassport,
    activePersonaId,
    switchActivePersona,
    personaJustAdded,
    personaJustDeleted,
  } = useContext(PassportContext);
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0); // FIXME: use activePersonaId directly
  const [activeMobileSection2Tab, setActiveMobileSection2Tab] = useState(0);
  const [mobileSection, setMobileSection] = useState(1);

  useEffect(() => {
    if (personaJustAdded) {
      setActiveTab(passport?.personaIds?.length - 1);
    }
    if (personaJustDeleted) {
      setActiveTab(0);
    }
  }, [personaJustAdded, personaJustDeleted, passport?.personaIds]);

  useEffect(() => {
    if (passport?.personaIds && !personaJustAdded && !personaJustDeleted) {
      switchActivePersona(passport.personaIds[activeTab]);
    }
  }, [activeTab, passport?.personaIds, switchActivePersona]);

  const handleSection2Tab = (e, newValue) => {
    setActiveMobileSection2Tab(newValue);
  };

  const handleCreateNewTab = () => {
    updatePassport({
      method: 'ADD_PERSONA',
      payload: {
        personaId: null,
        artistName: 'New Project',
        personaType: 'band',
        isProfessionalProfile: false,
      },
    });
  };

  // FIXME useCallback
  const handleTabDelete = async (personaId) => {
    setActiveTab(0);
    updatePassport({ method: 'DELETE_PERSONA', payload: personaId });
  };

  const getAtomIcon = (artisticType, isActive, className) => {
    switch (artisticType) {
      case 'professional':
        return (
          <ProfileIcon
            hasGradient={isActive}
            className={className ?? classes.typeIcon}
          />
        );
      case 'solo':
        return (
          <SoloIcon
            hasGradient={isActive}
            className={className ?? classes.typeIcon}
          />
        );
      case 'band':
        return (
          <BandIcon
            hasGradient={isActive}
            className={className ?? classes.typeIcon}
          />
        );
      case 'orchestra':
        return (
          <OrchestraIcon
            hasGradient={isActive}
            className={className ?? classes.typeIcon}
          />
        );
      case 'collective':
        return (
          <CollectiveIcon
            hasGradient={isActive}
            className={className ?? classes.typeIcon}
          />
        );
      default:
        return (
          <OtherIcon hasGradient={isActive} className={classes.typeIcon} />
        );
    }
  };
  if (!passport?.personaIds) {
    return (
      // TODO: passport.error ? ... :
      <ProgressTimeout timeoutMs={2000} />
    );
  }

  return (
    <PersonaProvider
      auth={auth}
      activePersonaId={activePersonaId}
      personaJustAdded={personaJustAdded}
      updatePassport={updatePassport}
    >
      {mobileSection === 2 ? (
        <React.Fragment>
          <Box className={classes.mobileTab}>
            <Box
              sx={{
                px: 1,
                mr: 1,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <IconButton
                onClick={() => setMobileSection(mobileSection === 1 ? 2 : 1)}
                className={classes.tabUnselected}
                disableRipple
                disableFocusRipple
              >
                <AiOutlineArrowLeft />
              </IconButton>
            </Box>
            <Tabs
              TabIndicatorProps={{ style: { backgroundColor: 'transparent' } }}
              value={activeMobileSection2Tab}
              onChange={handleSection2Tab}
              className={classes.leftSecTabs}
              variant="scrollable"
              scrollButtons={false}
              aria-label="artist profile tabs"
            >
              <Tab
                className={classes.leftSecTab}
                label={
                  <IconButton
                    className={classes.icon}
                    disableRipple
                    disableFocusRipple
                  >
                    {activeMobileSection2Tab === 0 ? (
                      <AtomsThunderSelected
                        className={classes.actionsIcon}
                        fill={!Boolean(activeMobileSection2Tab)}
                      />
                    ) : (
                      <AtomsThunder className={classes.actionsIcon} />
                    )}
                  </IconButton>
                }
              />
              <Tab
                className={classes.leftSecTab}
                label={
                  <IconButton
                    className={classes.icon}
                    disableRipple
                    disableFocusRipple
                  >
                    {activeMobileSection2Tab === 0 ? (
                      <AtomsNotification
                        className={classes.notificationsIcon}
                      />
                    ) : (
                      <AtomsNotificationSelected
                        className={classes.notificationsIcon}
                        fill={Boolean(activeMobileSection2Tab)}
                      />
                    )}
                  </IconButton>
                }
              />
            </Tabs>
          </Box>
          {/* ... Existing code */}
          {activeMobileSection2Tab === 0 && <TabContent1 />}
          {activeMobileSection2Tab === 1 && <TabContent2 />}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box className={classes.mobileTab}>
            <Box>
              {passport.personaIds?.length && (
                <Select
                  sx={{ px: 1, ml: 1 }}
                  disableUnderline
                  className={classes.dropdown}
                  value={activeTab}
                  renderValue={(selected) => (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {getAtomIcon(
                        passport.personaSummaries[selected]
                          ?.isProfessionalProfile
                          ? 'professional'
                          : passport.personaSummaries[selected]?.personaType,
                        true,
                        classes.selectedMenuIcon,
                      )}
                      {passport.personaSummaries[selected]
                        ?.isProfessionalProfile
                        ? 'Professional Profile'
                        : passport.personaSummaries[selected]?.artistName}
                    </div>
                  )}
                  label="try"
                  variant="outlined"
                >
                  {passport.personaSummaries?.map((summary, index) => (
                    <MenuItem
                      onClick={() => setActiveTab(index)}
                      key={index}
                      value={`${index}`} // TODO: separate out professional profile as unique object
                    >
                      <ListItemIcon>
                        {getAtomIcon(
                          summary.isProfessionalProfile
                            ? 'professional'
                            : summary.personaType,
                          activeTab === index,
                          classes.menuIcon,
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          summary.isProfessionalProfile
                            ? 'Professional Profile'
                            : summary.artistName
                        }
                      />
                    </MenuItem>
                  ))}
                </Select>
              )}
              {!passport.personaIds?.length ? (
                <IconTextButton
                  size="large"
                  onClick={handleCreateNewTab}
                  icon={<AddIcon size="small" />}
                >
                  Artistic Profile
                </IconTextButton>
              ) : (
                <IconButton
                  onClick={handleCreateNewTab}
                  className={classes.tabAddSingle}
                >
                  <AddIcon size="small" className={classes.tabAddIcon} />
                </IconButton>
              )}
            </Box>
            <Box
              sx={{
                px: 1,
                ml: 1,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <IconButton
                onClick={() => setMobileSection(mobileSection === 1 ? 2 : 1)}
                className={classes.tabUnselected}
                disableRipple
                disableFocusRipple
              >
                <MdBolt />
              </IconButton>
            </Box>
          </Box>
          <Box className={classes.main}>
            <Box className={classes.root}>
              <Box className={classes.container}>
                <Box className={classes.topRow}>
                  {/* Persona Tabs (Desktop)*/}
                  <Tabs
                    TabIndicatorProps={{
                      style: { backgroundColor: 'transparent' },
                    }}
                    value={activeTab}
                    onChange={(_, i) => setActiveTab(i)}
                    className={classes.tabs}
                    variant="scrollable"
                    scrollButtons={false}
                    aria-label="artist profile tabs"
                  >
                    {passport.personaSummaries?.map((summary, index) => (
                      <Tooltip
                        key={summary.personaId}
                        title={
                          summary.isProfessionalProfile
                            ? 'Professional Profile'
                            : summary.artistName
                        }
                        disableHoverListener
                      >
                        <Tab
                          icon={
                            <IconButton
                              disableRipple
                              style={{ height: '100%' }}
                            >
                              {getAtomIcon(
                                summary.isProfessionalProfile
                                  ? 'professional'
                                  : summary.personaType,
                                activeTab === index,
                              )}
                            </IconButton>
                          }
                          className={classes.tab}
                          aria-label={
                            summary.isProfessionalProfile
                              ? 'Professional Profile'
                              : summary.artistName
                          }
                          label={
                            summary.isProfessionalProfile
                              ? 'Professional Profile'
                              : summary.artistName
                          }
                        />
                      </Tooltip>
                    ))}
                  </Tabs>
                  {/* Additional profile buttons*/}
                  {passport.personaIds?.length === 1 ? (
                    <IconTextButton
                      size="large"
                      onClick={handleCreateNewTab}
                      icon={<AddIcon size="small" />}
                    >
                      Artistic Profile
                    </IconTextButton>
                  ) : (
                    <IconButton
                      onClick={handleCreateNewTab}
                      className={classes.tabAddSingle}
                    >
                      <AddIcon size="small" className={classes.tabAddIcon} />
                    </IconButton>
                  )}
                </Box>
                <Box
                  sx={
                    {
                      // bgcolor: 'red',
                    }
                  }
                >
                  {passport.personaSummaries?.map(
                    (summary, index) =>
                      index === activeTab && (
                        <div key={summary.personaId}>
                          <PersonaPrivateView
                            onDelete={() => handleTabDelete(summary.personaId)}
                            isDeleting={false}
                          />
                        </div>
                      ),
                  )}
                </Box>
              </Box>
            </Box>
            <Connections personaId={activePersonaId} />
          </Box>
        </React.Fragment>
      )}
    </PersonaProvider>
  );
};

//export default withAuthenticationRequired(CMSPrivateView);
export default CMSPrivateView;
