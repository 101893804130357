import React from 'react';
import { SvgIcon } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

function Icon({ hasGradient, sx, ...rest }) {
  const gradientId = uuidv4();
  return (
    <SvgIcon
      sx={[
        {
          ...sx,
        },
        hasGradient
          ? {
              '&': {
                g: {
                  fill: `url(#gradient-${gradientId})`,
                },
              },
            }
          : {
              '&': {
                g: {
                  fill: '',
                },
              },
            },
      ]}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      viewBox="0 0 20 20"
    >
      {hasGradient && (
        <defs>
          <linearGradient
            id={`gradient-${gradientId}`}
            gradientTransform="rotate(10)"
          >
            <stop offset="0%" stopColor="#FF2667" />
            <stop offset="51%" stopColor="#73AEE6" />
            <stop offset="100%" stopColor="#00FFCC" />
          </linearGradient>
        </defs>
      )}
      <title>Link</title>
      <g
        id="PWA---UI"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="UI-Elements"
          transform="translate(-886.000000, -509.000000)"
          fill="#626466"
        >
          <g id="Combined-Shape" transform="translate(884.000000, 507.000000)">
            <path d="M6.27,9.1572 L7.699,10.5872 L4.143,14.1432 L9.142,19.1422 L12.699,15.5862 L13.413,16.3012 L14.842,17.7292 L10.572,22.0002 L7.714,22.0002 L2,16.2852 L2,13.4282 L6.27,9.1572 Z M14.5003,6.6425 L15.2153,7.3575 L15.2153,7.3565 L16.6423,8.7855 L8.7853,16.6425 L7.3573,15.2145 L6.6433,14.4995 L14.5003,6.6425 Z M16.2861,2 L22.0001,7.714 L22.0001,10.571 L17.6991,14.873 L16.2701,13.443 L15.5571,12.729 L19.8581,8.429 L14.8571,3.428 L10.5561,7.729 L9.1281,6.3 L13.4291,2 L16.2861,2 Z"></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
