export interface ICustomAccess {
  [uniqueKey: string]: boolean;
}

// Custom Attributes
export interface ICustomAttribute {
  uniqueKey: string;
  fieldName: string;
  value: string;
}

export const areAttrsUnchanged = (oldAttrList: ICustomAttribute[], newAttrList: ICustomAttribute[]): boolean => {
  if (oldAttrList.length !== newAttrList.length) {
    return false;
  }
  for (let i = 0; i < oldAttrList.length; i++) {
    const oldAttr = oldAttrList[i];
    const newAttr = newAttrList[i];
    if (oldAttr.uniqueKey !== newAttr.uniqueKey) return false;
    if (oldAttr.fieldName !== newAttr.fieldName) return false;
    if (oldAttr.value !== newAttr.value) return false;
  }
  return true;
};

// Representatives

type representativeType =
  | 'agent'
  | 'collection_society'
  | 'distributor'
  | 'label'
  | 'legal'
  | 'pr_agent'
  | 'publisher'
  | 'sync_agency'
  | 'management'
  | 'general';

export interface IRepresentative {
  uniqueKey: string;
  representativeType: representativeType;
  repName: string;
  repPassportId: string | null;
  contactName: string | null;
  contactEmail: string | null;
  contactNote: string | null;
  contactPhone: string | null;
}

export const areRepsUnchanged = (oldRepList: IRepresentative[], newRepList: IRepresentative[]): boolean => {
  if (oldRepList.length !== newRepList.length) {
    return false;
  }
  for (let i = 0; i < oldRepList.length; i++) {
    const oldRep = oldRepList[i];
    const newRep = newRepList[i];
    if (oldRep.uniqueKey !== newRep.uniqueKey) return false;
    if (oldRep.representativeType !== newRep.representativeType) return false;
    if (oldRep.repName !== newRep.repName) return false;
    if (oldRep.repPassportId !== newRep.repPassportId) return false;
    if (oldRep.contactName !== newRep.contactName) return false;
    if (oldRep.contactEmail !== newRep.contactEmail) return false;
    if (oldRep.contactNote !== newRep.contactNote) return false;
    if (oldRep.contactPhone !== newRep.contactPhone) return false;
  }
  return true;
};
