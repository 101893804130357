const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    padding: theme.spacing(2),
    fontSize: '15px',
    borderRadius: '1rem',
    width: '120px',
    height: '120px',
  },
  icon: {
    width: '24px',
  },
  dashIcon: {
    '&:hover': {
      filter: 'brightness(70%)',
      WebkitFilter: 'brightness(80%)',
      transition: 'all 0.1s ease',
      WebkitTransition: 'all 0.1s ease',
    },
  },
  topInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#777777',
    marginBottom: theme.spacing(1),
  },
  editRoot: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  editName: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#626466',
  },
  editField: {
    margin: '16px 0',
    gap: theme.spacing(3),
    alignItems: 'center',
    justifyContent: 'center',
    color: '#777777',
  },
  emptyText: {
    fontSize: '18px',
    margin: '0',
  },
  infoLabel: {
    textTransform: 'Capitalize',
    fontSize: '12px',
    fontWeight: '500',
    color: '#313233',
    fontFamily: 'Roboto',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.only('xs')]: {
      maxWidth: 'calc((100vw - 6rem) / 2)',
    },
    [theme.breakpoints.only('sm')]: {
      maxWidth: 'calc((100vw - 18rem) / 5)',
    },
    [theme.breakpoints.only('md')]: {
      maxWidth: 'calc((67vw - 18rem) / 5)',
    },
    [theme.breakpoints.only('lg')]: {
      maxWidth: 'calc((67vw - 18rem) / 6)',
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: 'calc((965px - 18rem) / 7)',
    },
  },
  officialinktile: {
    [theme.breakpoints.only('xs')]: {
      width: 'calc((100vw - 3rem) / 2)',
      height: 'calc((100vw - 3rem) / 2)',
      gap: '0.5rem',
    },
    [theme.breakpoints.only('sm')]: {
      width: 'calc((100vw - 6rem) / 5)',
      height: 'calc((100vw - 6rem) / 5)',
      gap: '0.25rem',
    },
    [theme.breakpoints.only('md')]: {
      width: 'calc((67vw - 8rem) / 5)',
      height: 'calc((67vw - 8rem) / 5)',
      gap: '0.14rem',
    },
    [theme.breakpoints.only('lg')]: {
      width: 'calc((67vw - 9rem) / 6)',
      height: 'calc((67vw - 9rem) / 6)',
      gap: '0.25rem',
    },
    [theme.breakpoints.up('xl')]: {
      width: 'calc((965px - 6rem) / 7)',
      height: 'calc((965px - 6rem) / 7)',
      gap: '0.25rem',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '1rem',
    justifyContent: 'center',
  },
});

export default styles;
