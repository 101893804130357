import * as React from 'react';
import { createContext, ReactNode, useCallback, useEffect, useState } from 'react';
import { Action, objectURL, PersonaImageContextType } from './types';
import { AuthState } from 'react-oidc-context';
import { deletePersonaImage, getPersonaImage, uploadPersonaImage } from './http.ts';

const initialState: objectURL | null = null;

export const PersonaImageContext = createContext<PersonaImageContextType | null>(null);

interface PersonaImageProviderProps {
  auth: AuthState | null;
  children: ReactNode;
  activePersonaId: string | null;
}

export const PersonaImageProvider: React.FC<PersonaImageProviderProps> = ({ auth, children, activePersonaId }) => {
  const [imgObjectURL, setImgObjectURL] = useState<objectURL | null>(null);
  const [currentAction, setCurrentAction] = useState<Action | null>(null);
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    if (currentAction === null) return;
    const oldState = imgObjectURL;
    const asyncEffect = async (action: Action, token: string) => {
      if (isBusy) return console.warn(`persona action in progress, ${action.method} REJECTED`);
      setIsBusy(true);
      switch (action.method) {
        case 'SERVERLOAD':
          return await getPersonaImage(token, action.payload).then((img) => {
            setImgObjectURL(img);
            return setIsBusy(false);
          }).catch((err) => {
            console.warn(err);
            setImgObjectURL(oldState);
            return setIsBusy(false);
          });

        case 'LOAD':
          setImgObjectURL(action.payload);
          return setIsBusy(false);

        case 'UPLOAD':
          setImgObjectURL(URL.createObjectURL(action.payload));
          return uploadPersonaImage(token, activePersonaId, action.payload).then((didUpload) => {
            if (!didUpload) {
              console.warn('failed to upload');
              setImgObjectURL(oldState);
            }
            return setIsBusy(false);
          }).catch((err) => {
            console.warn(err);
            setImgObjectURL(oldState);
            return setIsBusy(false);
          });


        case 'DELETE':
          setImgObjectURL(null);
          return await deletePersonaImage(token, activePersonaId).then((didUpdate) => {
            if (!didUpdate) {
              console.warn('Image delete failed');
              setImgObjectURL(oldState);
            }
            return setIsBusy(false);
          }).catch((err) => {
            console.warn(err);
            setImgObjectURL(oldState);
            return setIsBusy(false);
          });
        default:
          console.warn(`Unknown action on image: ${action.method}`);
          setIsBusy(false);
      }
    };
    // Where we actually start the async execution
    const token = auth?.user?.access_token || '';
    if (token || auth === null) {
      asyncEffect(currentAction, token).catch((err: any) => {
        console.warn(err);
        setTimeout(() => setCurrentAction({ method: 'LOAD', payload: oldState }), 1000);
        setCurrentAction(null);
        setIsBusy(false);
      });
    } else {
      console.warn('Auth manager did not provide an access token');
      setIsBusy(false);
    }
    return () => setCurrentAction(null);
  }, [auth, currentAction, imgObjectURL, isBusy]);

  useEffect(() => {
    if (activePersonaId) {
      setCurrentAction({ method: 'SERVERLOAD', payload: activePersonaId });
    }
  }, [activePersonaId]);


  const updateImage = useCallback((action: Action) => {
    setCurrentAction(action);
  }, []);

  if (auth === null) {
    return (
      <PersonaImageContext.Provider value={{ imgObjectURL }}>
        {children}
      </PersonaImageContext.Provider>
    );
  }

  return (
    <PersonaImageContext.Provider value={{ imgObjectURL, updateImage }}>
      {children}
    </PersonaImageContext.Provider>
  );

};
