import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import { Switch } from 'antd';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    //marginLeft: theme.spacing(1),
    //marginRight: theme.spacing(1),
    '& .ant-switch-checked': {
      background:
        'linear-gradient(270deg, #FF2667 0%, #669ACB 51%, #00FFCC 100%)',
    },
  },
  switch: {
    width: '40px',
    pointerEvents: ({ disabled }) => (disabled ? 'none' : undefined),
    '& .ant-switch-handle': {
      margin: '2px',
      width: '14.5px',
      height: '14.5px',
    },
  },
  wrapDiv: {
    cursor: ({ disabled }) => (disabled ? 'not-allowed' : undefined),
  },
}));

const CPSwitch = ({ disabled, ...props }) => {
  const classes = useStyles({ disabled });
  const switchComponent = (
    <Switch
      className={classes.switch}
      // unCheckedChildren={ }
      disabled={disabled}
      {...props}
    />
  );
  return (
    <div className={classes.root}>
      {props.message ? (
        <Tooltip title={props.message ? props.message : ''} arrow>
          {switchComponent}
        </Tooltip>
      ) : (
        switchComponent
      )}
    </div>
  );
};

export default CPSwitch;
