import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  Box,
  Grid,
  Icon,
  IconButton,
  Link as MuiLink,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import NavListItem from '../NavListItem';
import Logout from '../Logout';
import Logo from '../../icons/cpLogoWithTextBlack';
import HeaderPlug from '../../icons/headerPlug';
import HeaderPlugActive from '../../icons/headerPlugActive';
import HeaderShake from '../../icons/headerShake';
import HeaderShakeActive from '../../icons/headerShakeActive';
import HeaderUser from '../../icons/headerUser';
import HeaderPassport from '../../icons/headerPassport';
import HeaderPassportActive from '../../icons/headerPassportActive';
import SearchIconActive from '../../icons/searchIconActive.js';
import SearchIcon from '../../icons/searchIcon.js';
import styles from './styles';
import { useTheme } from '@mui/material/styles';
import { useAuth } from 'react-oidc-context';

const links = [
  {
    name: 'Your passport',
    url: '/',
    icon: HeaderPassport,
    icon_active: HeaderPassportActive,
  },
  {
    name: 'Integrations',
    url: '/integrations',
    icon: HeaderPlug,
    icon_active: HeaderPlugActive,
  },
  {
    name: 'Get involved',
    url: '/get_involved',
    icon: HeaderShake,
    icon_active: HeaderShakeActive,
  },
  {
    name: 'Search',
    url: '/search',
    icon: SearchIcon,
    icon_active: SearchIconActive,
  },
];

const useStyles = makeStyles(styles);

const SignedInHeader = () => {
  const auth = useAuth();
  const location = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleLogout = () => {
    auth.signoutRedirect({
      post_logout_redirect_uri: `${window.location.origin}/welcome`,
    }); // .then(() => navigate('/welcome')).catch((err) => console.warn(err));
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const accountMenu = (
    <div>
      <Tooltip title="Account settings">
        <IconButton
          onClick={handleClick}
          size="large"
          data-testid="accountMenu"
        >
          <HeaderUser
            color={theme.palette.primary.hint}
            sx={{ fontSize: '16px' }}
          />
        </IconButton>
      </Tooltip>
      <Menu
        className={classes.menugeneral}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        <MenuItem>
          <NavListItem
            disableRipple
            className={classes.menuitem}
            button
            key="Account"
            to="/settings"
          >
            Account
          </NavListItem>
        </MenuItem>
        <MenuItem className={classes.menuitemicon} onClick={handleLogout}>
          <ListItemIcon>
            <Logout color={theme.palette.primary.hint} />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </div>
  );

  return (
    <Grid container className={classes.root}>
      <Grid
        item
        sx={{
          height: '37.55px',
        }}
      >
        <Logo
          color={theme.palette.primary.dark}
          sx={{
            width: { xs: 'inherit', sm: '1em' },
            marginRight: { xs: '4px', sm: '0px' },
            fontSize: '110px',
            height: '100%',
          }}
        />
      </Grid>
      <Grid item className={classes.links}>
        {links.map((el) => (
          <MuiLink
            underline="none"
            className={
              location.pathname === el.url ? classes.active : classes.link
            }
            component={Link}
            key={el.url}
            to={el.url}
          >
            <Icon
              className={classes.linkIcon}
              sx={{
                boxShadow:
                  location.pathname !== el.url &&
                  '0.67px 0.67px 4px 0 rgba(0,0,0,0.1)',
              }}
            >
              {location.pathname === el.url ? (
                <el.icon_active
                  sx={{
                    fontSize: '16px',
                  }}
                />
              ) : (
                <el.icon
                  sx={{
                    fontSize: '16px',
                  }}
                />
              )}
            </Icon>
            <span className={classes.linkText}>{el.name}</span>
          </MuiLink>
        ))}
      </Grid>
      <Grid item>
        <Box className={classes.accountMenu}>{accountMenu}</Box>
      </Grid>
    </Grid>
  );
};

export default SignedInHeader;
