
export const personaKeys: {[key: string]: string} = {
  personaId: 'persona_id',
  isProfessionalProfile: 'is_professional_profile',
  artistName: 'artist_name',
  personaType: 'persona_type',
  aliasOverwritesName: 'alias_overwrites_name',
  alias: 'alias',
  biographyLong: 'biography_long',
  biographyShort: 'biography_short',
  biographyHeadline: 'biography_headline',
  charitiesSupported: 'charities_supported',
  city: 'city',
  countryAlpha2: 'country_alpha2',
  customProjects: 'custom_projects',
  customIds: 'custom_ids',
  dateOfBirth: 'date_of_birth',
  endorsements: 'endorsements',
  favouriteMusicMakers: 'favourite_music_makers',
  favouriteQuote: 'favourite_quote',
  features: 'features',
  gender: 'gender',
  genres: 'genres',
  instrumentsOwned: 'instruments_owned',
  instrumentsPlayed: 'instruments_played',
  interestsInspiration: 'interests_inspiration',
  unverifiedIpi: 'unverified_ipi',
  unverifiedIpn: 'unverified_ipn',
  unverifiedIsni: 'unverified_isni',
  musicRoles: 'music_roles',
  otherSkills: 'other_skills',
  officialLinks: 'official_links',
  pronouns: 'pronouns',
  publicPageStyling: 'public_page_styling',
  representatives: 'representatives',
  spokenLanguages: 'spoken_languages',
  toolsOwned: 'tools_owned',
  toolsUsed: 'tools_used',
}
