import clone from 'lodash/clone';
import setWith from 'lodash/setWith';
import get from 'lodash/get';
import unset from 'lodash/unset';

export function setIn(state, path, value) {
  return setWith({ ...state }, path, value, clone);
}

export function deleteIn(state, path) {
  const newState = setWith({ ...state }, path, get(state, path), clone);
  unset(newState, path);
  return newState;
}

let idCounter = 0;

export const uniqueId = (prefix) => {
  var id = idCounter++;
  return prefix + id;
};

export const loadScript = function (name, src, onLoaded) {
  const id = `script-${name}`;
  const existingScript = document.getElementById(id);

  if (!existingScript) {
    var tag = document.createElement('script');
    tag.id = id;
    tag.async = false;
    tag.src = src;
    document.getElementsByTagName('body')[0].appendChild(tag);

    tag.onload = () => {
      if (onLoaded) onLoaded();
    };
  } else if (onLoaded) {
    onLoaded();
  }
};

export function stringToBool(value) {
  if (!value) {
    return false;
  }
  value = String(value).trim();
  if (/^(?:1|y|yes|true|t|on)$/i.test(value)) {
    return true;
  }
  return false;
}
