import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  const { color } = props;
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 24 24">
      <title>Genres</title>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Atoms/icons/genres" fill={color || '#626466'}>
          <path
            d="M22.858,3.55271368e-15 L8.859,2.00083368 L8,2.99024594 L8,13.924802 L7.071,12.9954148 L2.928,12.9954148 L0,15.9246353 L0,20.0693622 L2.928,22.9995832 L3.928,24 L8.071,24 L11,21.0697791 L11,16.9250521 L11,9.71804919 L20.999,8.28845352 L20.999,11.9239683 L20.071,10.9935807 L15.928,10.9935807 L12.999,13.924802 L12.999,18.0675281 L15.928,20.9977491 L16.929,21.9991663 L21.071,21.9991663 L24,19.067945 L24,0.989412255 L22.858,3.55271368e-15 Z M11,3.71454773 L20.999,2.28595248 L20.999,5.26719466 L11,6.69578991 L11,3.71454773 Z M15.706,16.9470613 L15.706,15.0452689 L17.049,13.7007086 L18.95,13.7007086 L20.294,15.0452689 L20.294,16.9470613 L18.95,18.2916215 L17.049,18.2916215 L15.706,16.9470613 Z M2.706,18.947895 L2.706,17.0461025 L4.05,15.7015423 L5.95,15.7015423 L7.293,17.0461025 L7.293,18.947895 L5.95,20.2914548 L4.05,20.2914548 L2.706,18.947895 Z"
            id="Fill-1"
          ></path>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
