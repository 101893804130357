import React from 'react';
import { Dialog, IconButton, DialogContent } from '@mui/material';
import { CgClose } from 'react-icons/cg';
import { makeStyles } from '@mui/styles';
import ChangePasswordPage from '../changePasswordPage';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    top: 8,
    right: 8,
  },
}));

const ChangePassword = ({ isOpen, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent>
        <ChangePasswordPage />
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CgClose />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};

export default ChangePassword;
