import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 24 24">
      <title>Notifications</title>
      <defs>
        <linearGradient
          x1="78.125%"
          y1="100%"
          x2="21.9264264%"
          y2="0.0914246886%"
          id="linearGradient-1"
        >
          <stop stop-color="#FF2667" offset="0.0914246886%"></stop>
          <stop stop-color="#73AEE6" offset="50.9814282%"></stop>
          <stop stop-color="#00FFCC" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g
        id="Symbols"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Atoms/icons/Notifications/Default"
          fill={props.fill ? 'url(#linearGradient-1)' : '#626466'}
        >
          <path
            d="M8.686,0 L6.999,1.687 L4,4.687 L4,8 L4,13.001 L2,15 L2,19 L7.999,19 L7.999,21.691 L12,24 L15.999,21.691 L15.999,19 L22,19 L22,15 L20,13.001 L20,8 L20,4.687 L15.314,0 L8.686,0 Z M5,14.828 L5.415,14.414 L6,13.829 L6,13.822 L6.999,12.822 L6.999,5 L6.999,4.515 L9.515,2.001 L14.485,2.001 L18,5.515 L18,8 L18,13.001 L18,13.829 L18.586,14.414 L20,15.829 L20,16 L5,16 L5,14.828 Z M11,21.114 L11,20.001 L14,20.001 L14,20.536 L12,21.691 L11,21.114 Z"
            id="Fill-1"
          ></path>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
