const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
  dialog_text: {
    textTransform: 'none',
    maxWidth: '300px',
    fontFamily: 'Roboto, Sans-serif',
    color: '#626466',
    lineHeight: ' 20px',
    fontSize: ' 12px',
    paddingLeft: '0.75rem',
    textAlign: 'justify',
    paddingTop: '2px',
    paddingBottom: '0.75rem',
    paddingRight: '0.2rem',
    margin: '0',
  },
  title: {
    transition: '0.3s',
    display: 'block',
    textDecoration: 'underline',
    color: '#5079A1',
    textDecorationColor: '#5078A150',
    '&:hover': {
      textDecorationColor: '#5079A1',
    },
  },
});

export default styles;
