import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 15" {...props}>
      <title>Check</title>
      <g
        id="PWA---UI"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="UI-Elements"
          transform="translate(-982.000000, -728.000000)"
          fill="#626466"
        >
          <g id="Fill-1" transform="translate(980.000000, 723.000000)">
            <polygon points="19.5636228 5 8.09190547 16.4216843 3.43637725 11.7864608 2 13.2165734 8.81314157 20 21 7.86629357 21 6.4301126 20.2848589 5.71809049"></polygon>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
