import { useState } from 'react';

const useCPHelpMessage = (props) => {
  const [getAnchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return {
    getAnchorEl,
    handleClick,
    handleClose,
  };
};

export default useCPHelpMessage;
