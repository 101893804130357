import React, { useState, useContext, useEffect } from 'react';
import { Input, Box, Typography, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import styles from '../styles';
import CMSModal from '../../CMSModal';
import Delete from '../../../icons/atomsIconsDelete';
import CPVisibleToggle from '../../CPVisibleToggle';
import CPAddButton from '../../CPAddButton';
import { PersonaContext } from '../../../../v2api/PersonaContext/PersonaContext';
import { PersonaAccessContext } from '../../../../v2api/PersonaAccessContext/PersonaAccessContext';

const useStyles = makeStyles(styles);

export const EditBio = ({ isOpen, closeModal }) => {
  const classes = useStyles();
  const { persona, updatePersona } = useContext(PersonaContext);
  const { personaAccess, updateAccess } = useContext(PersonaAccessContext);
  const [personaPatch, setPersonaPatch] = useState({});
  const [personaVisibilityPatch, setPersonaVisibilityPatch] = useState({});

  const [state, setState] = useState({});

  useEffect(() => {
    if (!persona) return;
    const personaKeys = [
      'biographyHeadline',
      'biographyShort',
      'biographyLong',
    ];
    const patchedState = Object.fromEntries(
      personaKeys.map((key) => [
        key,
        key in personaPatch ? personaPatch[key] : persona[key] || null,
      ]),
    );
    setState(patchedState);
  }, [personaPatch, persona]);

  const patchedVisibility = {
    ...personaAccess,
    ...personaVisibilityPatch,
  };

  const handleSave = () => {
    const personaPatchCopy = { ...personaPatch };
    Object.keys(personaPatch).forEach((key) => {
      if (personaPatch[key] === '') {
        delete personaPatchCopy[key]; // undeleted blank entries are cancelled
      } else if (personaPatch[key] === null && !persona[key]) {
        delete personaPatchCopy[key]; // remove no-op deletes
      } else if (personaPatch[key] === persona[key]) {
        delete personaPatchCopy[key]; // remove no-op updates
      }
    });
    if (Object.keys(personaPatchCopy).length >= 1) {
      updatePersona({ method: 'PATCH', payload: personaPatchCopy });
    }

    const personaVisibilityPatchCopy = { ...personaVisibilityPatch };
    Object.keys(personaVisibilityPatch).forEach((key) => {
      if (personaVisibilityPatch[key] === personaAccess[key]) {
        delete personaVisibilityPatchCopy[key];
      }
    });
    if (Object.keys(personaVisibilityPatchCopy).length >= 1) {
      updateAccess({
        method: 'PATCH',
        payload: personaVisibilityPatchCopy,
      });
    }
    setPersonaVisibilityPatch({});
    setPersonaPatch({});
    closeModal();
  };

  const handleCancel = () => {
    setPersonaPatch({});
    setPersonaVisibilityPatch({});
    closeModal();
  };

  return (
    <CMSModal onSave={handleSave} onClose={handleCancel} open={isOpen}>
      <div className={classes.modal}>
        {state.biographyHeadline === null ? (
          <CPAddButton
            size="small"
            onClick={() =>
              setPersonaPatch((p) => ({ ...p, biographyHeadline: '' }))
            }
          >
            Add a headline bio
          </CPAddButton>
        ) : (
          <Box
            sx={{
              backgroundColor: '#f4f5f6',
              padding: 2,
              borderRadius: 2,
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row" spacing={0.7} alignItems="center">
                <Typography
                  sx={{
                    color: '#626466',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                  }}
                >
                  headline
                </Typography>
              </Stack>

              <Stack direction="row" spacing={1.5} alignItems="center">
                <CPVisibleToggle
                  darker={true}
                  isVisible={patchedVisibility.biographyHeadline}
                  setVisible={(v) =>
                    setPersonaVisibilityPatch((p) => ({
                      ...p,
                      biographyHeadline: v,
                    }))
                  }
                  isDisabled={!personaAccess?.personaId}
                />
                <Box
                  sx={{
                    backgroundColor: 'white',
                    boxShadow: '2px 2px 6px 0px rgba(0,0,0,0.1)',
                    height: '35px',
                    width: '35px',
                    borderRadius: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#D3D3D3',
                    },
                  }}
                  onClick={() =>
                    setPersonaPatch((p) => ({ ...p, biographyHeadline: null }))
                  }
                >
                  <Delete sx={{ fontSize: '40px', cursor: 'pointer' }} />
                </Box>
              </Stack>
            </Stack>

            <Input
              value={state.biographyHeadline}
              onChange={(e) =>
                setPersonaPatch((p) => ({
                  ...p,
                  biographyHeadline: e.target.value,
                }))
              }
              multiline
              placeholder={'Add a headline bio'}
              sx={{ width: '100%', mt: 3 }}
              inputProps={{ maxLength: 100 }}
            />

            <Typography
              sx={{ float: 'right', fontWeight: 'bold', color: '#313233' }}
            >
              {state.biographyHeadline?.length > 0
                ? `${state.biographyHeadline?.length}/100`
                : ''}
            </Typography>
          </Box>
        )}
        {state.biographyShort === null ? (
          <CPAddButton
            size="small"
            onClick={() =>
              setPersonaPatch((p) => ({ ...p, biographyShort: '' }))
            }
          >
            Add a short bio
          </CPAddButton>
        ) : (
          <Box
            sx={{
              backgroundColor: '#f4f5f6',
              padding: 2,
              borderRadius: 2,
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row" spacing={0.7} alignItems="center">
                <Typography
                  sx={{
                    color: '#626466',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                  }}
                >
                  short
                </Typography>
              </Stack>

              <Stack direction="row" spacing={1.5} alignItems="center">
                <CPVisibleToggle
                  darker={true}
                  isVisible={patchedVisibility.biographyShort}
                  setVisible={(v) =>
                    setPersonaVisibilityPatch((p) => ({
                      ...p,
                      biographyShort: v,
                    }))
                  }
                  isDisabled={!personaAccess?.personaId}
                />
                <Box
                  sx={{
                    backgroundColor: 'white',
                    boxShadow: '2px 2px 6px 0px rgba(0,0,0,0.1)',
                    height: '35px',
                    width: '35px',
                    borderRadius: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#D3D3D3',
                    },
                  }}
                  onClick={() =>
                    setPersonaPatch((p) => ({ ...p, biographyShort: null }))
                  }
                >
                  <Delete sx={{ fontSize: '40px', cursor: 'pointer' }} />
                </Box>
              </Stack>
            </Stack>

            <Input
              value={state.biographyShort}
              onChange={(e) =>
                setPersonaPatch((p) => ({
                  ...p,
                  biographyShort: e.target.value,
                }))
              }
              multiline
              placeholder={'Add a short bio'}
              sx={{ width: '100%', mt: 3 }}
              inputProps={{ maxLength: 500 }}
            />

            <Typography
              sx={{ float: 'right', fontWeight: 'bold', color: '#313233' }}
            >
              {state.biographyShort?.length > 0
                ? `${state.biographyShort?.length}/500`
                : ''}
            </Typography>
          </Box>
        )}
        {state.biographyLong === null ? (
          <CPAddButton
            size="small"
            onClick={() =>
              setPersonaPatch((p) => ({ ...p, biographyLong: '' }))
            }
          >
            Add a long bio
          </CPAddButton>
        ) : (
          <Box
            sx={{
              backgroundColor: '#f4f5f6',
              padding: 2,
              borderRadius: 2,
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row" spacing={0.7} alignItems="center">
                <Typography
                  sx={{
                    color: '#626466',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                  }}
                >
                  long
                </Typography>
              </Stack>

              <Stack direction="row" spacing={1.5} alignItems="center">
                <CPVisibleToggle
                  darker={true}
                  isVisible={patchedVisibility.biographyLong}
                  setVisible={(v) =>
                    setPersonaVisibilityPatch((p) => ({
                      ...p,
                      biographyLong: v,
                    }))
                  }
                  isDisabled={!personaAccess?.personaId}
                />
                <Box
                  sx={{
                    backgroundColor: 'white',
                    boxShadow: '2px 2px 6px 0px rgba(0,0,0,0.1)',
                    height: '35px',
                    width: '35px',
                    borderRadius: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#D3D3D3',
                    },
                  }}
                  onClick={() =>
                    setPersonaPatch((p) => ({ ...p, biographyLong: null }))
                  }
                >
                  <Delete sx={{ fontSize: '40px', cursor: 'pointer' }} />
                </Box>
              </Stack>
            </Stack>

            <Input
              value={state.biographyLong}
              onChange={(e) =>
                setPersonaPatch((p) => ({
                  ...p,
                  biographyLong: e.target.value,
                }))
              }
              multiline
              placeholder={'Add a long bio'}
              sx={{ width: '100%', mt: 3 }}
              inputProps={{ maxLength: 500 }}
            />
          </Box>
        )}
      </div>
    </CMSModal>
  );
};
