import React from 'react';
import Grid from '@mui/material/Grid';
import ProfileSideBarContents from './ProfileSideBarContents';
import ProfileMainContents from './ProfileMainContents';

const ProfileInfo = () => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={4}>
        <ProfileSideBarContents />
      </Grid>
      <Grid item xs={12} md={8}>
        <ProfileMainContents />
      </Grid>
    </Grid>
  );
};

export default ProfileInfo;

// paddingLeft: '40px',
