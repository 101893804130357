import * as React from 'react';
import toast from 'react-hot-toast';
import { createContext, ReactNode, useReducer, useEffect, useState, useCallback } from 'react';
import { AuthState } from 'react-oidc-context';
import { IPassport, Action } from './types';
import { getPassport, patchPassport, postNewPersona, deletePersona } from './http';


type PassportContextType = {
  passport: IPassport | null;
  updatePassport: (action: Action) => void;
  activePersonaId: string | null;
  switchActivePersona: (personaId: string) => void;
  personaJustAdded: boolean;
  personaJustDeleted: boolean;
}

const initialState: IPassport | null = null;


export const PassportContext = createContext<PassportContextType | null>(null);

/* For internal state management of the context provider. roughly mirrors server state, with computed summaries */
function reducer(state: IPassport | null, action: Action): IPassport | null {
  switch (action.method) {
    case 'LOAD':
      return action.payload;
    case 'PATCH':
      return { ...state, ...action.payload };
    case 'PATCH_SUMMARY':
      const patchedSummaries = state.personaSummaries
        .map((oldSummary) => oldSummary.personaId === action.payload.personaId ? { ...oldSummary, ...action.payload } : oldSummary);
      return { ...state, personaSummaries: patchedSummaries };
    case 'ADD_PERSONA':
      return {
        ...state,
        personaSummaries: [...state.personaSummaries, action.payload],
        personaIds: [...state.personaIds, null]
      };
    case 'CONFIRM_PERSONA':
      if (state.personaSummaries[state.personaSummaries.length - 1].personaId !== null) {
        console.warn('No persona to confirm');
        return state;
      }
      if (state.personaIds[state.personaIds.length - 1] !== null) {
        console.warn('Invalid state');
        return state;
      }
      const summariesCopy = [...state.personaSummaries];
      summariesCopy[summariesCopy.length - 1].personaId = action.payload;
      const idsCopy = state.personaIds.slice(0, -1);
      return { ...state, personaSummaries: summariesCopy, personaIds: [...idsCopy, action.payload] };
    case 'DELETE_PERSONA':
      if (!state.personaIds.includes(action.payload)) {
        console.warn('Cannot delete persona summary or persona_id from passport');
        return state;
      }
      return {
        ...state,
        personaIds: state.personaIds.filter(pid => pid !== action.payload),
        personaSummaries: state.personaSummaries.filter(pSumm => pSumm.personaId !== action.payload)
      };
    default:
      console.warn(`Unhandled passport state action: <${JSON.stringify(action)}>`);
      return state;
  }
}

interface PassportProviderProps {
  auth: AuthState | null;
  children: ReactNode;
}

export const PassportProvider: React.FC<PassportProviderProps> = ({ auth, children }) => {
  const [passport, dispatch] = useReducer(reducer, initialState);
  const [currentAction, setCurrentAction] = useState<Action | null>(null);
  const [activePersonaId, setActivePersonaId] = useState<string | null>(null);
  const [isBusy, setIsBusy] = useState(false);
  const [personaJustAdded, setPersonaJustAdded] = useState<boolean>(false);
  const [personaJustDeleted, setPersonaJustDeleted] = useState<boolean>(false);


  /* To use: set the currentAction via the provided function. This effect handles debouncing and async execution of
  * the requested action. It optimistically updates the reducer state. Fires off an HTTP call that on callback confirms
  * the update, and exits the busy state. On failure, (after a "react cooling off" period) it reverts to it's state
  * at the time of the original call, notifies, and also exits the busy state.
  *
  * All callback chains must eventually `setIsBusy(false)` !! */
  useEffect(() => {
      if (currentAction === null) return;
      const oldState = { ...passport };
      const asyncEffect = async (action: Action, token: string) => {
        if (isBusy) return console.warn(`passport action in progress, ${action.method} REJECTED`);
        setIsBusy(true);
        switch (action.method) {
          case 'SERVERLOAD':
            if (passport === null) {
              return await getPassport(token).then(data => {
                dispatch({ method: 'LOAD', payload: data });
                setIsBusy(false);
              }).catch(err => {
                if (err?.message) {
                  const info = JSON.parse(err.message);
                  if (info['detail'] === 'no_passport') {
                    console.warn("Passport creation failed")
                    return setIsBusy(false);
                  }
                }
                return setIsBusy(false);
              });
            } else {
              setIsBusy(false);
              return console.warn('Refusing to SERVERLOAD since passport is not null');
            }
          case 'LOAD':
            setIsBusy(false);
            return dispatch(action);
          case 'PATCH':
            setPersonaJustAdded(false);
            dispatch(action);
            return await patchPassport(token, action.payload).then(patchSuccess => {
              if (patchSuccess) {
                toast.success('Personal data updated successfully');
              } else {
                toast.error(`Update failed`);
                setTimeout(() => setCurrentAction({ method: 'LOAD', payload: oldState }), 1000);
              }
              setIsBusy(false);
            }).catch(err => {
              console.warn(err);
              setTimeout(() => setCurrentAction({ method: 'LOAD', payload: oldState }), 1000);
              setIsBusy(false);
            });
          case 'PATCH_SUMMARY':
            dispatch(action);
            setIsBusy(false);
            return;
          case 'ADD_PERSONA':
            if (personaJustAdded) {
              console.warn('Refusing to add more personas');
              setIsBusy(false);
              return;
            }
            dispatch(action);
            return await postNewPersona(token, action.payload).then((newPersonaId) => {
                if (newPersonaId) {
                  dispatch({ method: 'CONFIRM_PERSONA', payload: newPersonaId });
                  toast.success('New project added');
                  setActivePersonaId(newPersonaId);
                  setPersonaJustAdded(true);
                  setTimeout(() => setPersonaJustAdded(false), 2000);
                  setIsBusy(false);
                } else {
                  setPersonaJustAdded(false);
                  toast.error('Could not add new project');
                  setTimeout(() => setCurrentAction({ method: 'LOAD', payload: oldState }), 1000);
                  setIsBusy(false);
                }
              }
            );
          case 'DELETE_PERSONA':
            if (personaJustAdded || personaJustDeleted) {
              console.warn('Refusing to delete persona after recent creation or deletion');
              setIsBusy(false);
              return;
            }
            setActivePersonaId(passport.personaIds[0]);
            setPersonaJustDeleted(true);
            dispatch(action);
            return await deletePersona(token, action.payload).then(deleteSuccess => {
              if (deleteSuccess) {
                toast.success('Profile deleted successfully');
                setTimeout(() => setPersonaJustDeleted(false), 2000);
              } else {
                toast.error(`Update failed`);
                setTimeout(() => setCurrentAction({ method: 'LOAD', payload: oldState }), 1000);
                setTimeout(() => setPersonaJustDeleted(false), 1002);
              }
              setIsBusy(false);
            }).catch(err => {
              console.warn(err);
              setTimeout(() => setCurrentAction({ method: 'LOAD', payload: oldState }), 1000);
              setTimeout(() => setPersonaJustDeleted(false), 1002);
              setIsBusy(false);
            });
          default:
            console.warn(`Unknown action on passport: ${action.method}: ${JSON.stringify(action.payload)}`);
            setIsBusy(false);
        }
      };
      // Where we actually start the async execution
      const token = auth?.user?.access_token || "";
      if (token || auth === null) {
        asyncEffect(currentAction, token).catch((err: any) => {
          console.warn(err);
          dispatch({ method: 'LOAD', payload: oldState });
          setCurrentAction(null);
          setIsBusy(false);
        });
      } else {
        console.warn('Auth manager did not provide an access token');
        setIsBusy(false);
      }

      // Cleanup. isBusy is managed only in the asyncEffect "strands"
      return () => {
        setCurrentAction(null);
      };
    }, [auth?.user, passport, currentAction, isBusy, personaJustAdded, personaJustDeleted]
  );

  useEffect(() => {
    if (!activePersonaId && passport?.personaIds) {
      setActivePersonaId(passport.personaIds[0]);
    }
  }, [passport, activePersonaId]);

  // onLoad: SERVERLOAD
  useEffect(() => {
    setCurrentAction({ method: 'SERVERLOAD' });
  }, []);

  const updatePassport = useCallback((action: Action) => {
    // TODO: queueing?
    setCurrentAction(action);
  }, []);

  const switchActivePersona = useCallback((personaId: string) => {
    setPersonaJustAdded(false);
    setActivePersonaId(personaId);
  }, []);

  return (
    <PassportContext.Provider
      value={{
        passport,
        updatePassport,
        activePersonaId,
        switchActivePersona,
        personaJustAdded,
        personaJustDeleted,
      }}>
      {children}
    </PassportContext.Provider>
  );
};
