import Modal from '../../CMSModal';
import { v4 as uuidv4 } from 'uuid';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Stack } from '@mui/material';
import { EditStandardID } from '../EditStandardID';
import CPAddButton from '../../CPAddButton';
import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { PersonaContext } from '../../../../v2api/PersonaContext/PersonaContext';
import { PersonaAccessContext } from '../../../../v2api/PersonaAccessContext/PersonaAccessContext';
import { areAttrsUnchanged } from '../../../../v2api/extraTypes';
import { EditCustomID } from '../EditCustomID';

const useStyles = makeStyles((theme) => ({
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0.5rem',
      marginRight: '1rem',
    },
  },
  edit: {
    padding: '0.2rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: '10px',
    gap: '0.75rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

const EditIDs = ({ isOpen, closeModal }) => {
  const classes = useStyles();
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const { persona, updatePersona } = useContext(PersonaContext);
  const { personaAccess, idAttrAccess, updateAccess } =
    useContext(PersonaAccessContext);
  const [personaPatch, setPersonaPatch] = useState({});
  const [personaVisibilityPatch, setPersonaVisibilityPatch] = useState({});
  const [attrVisibilityPatch, setAttrVisibilityPatch] = useState({});

  const [localCustomIds, setLocalCustomIds] = useState(
    persona?.customIds || [],
  );

  const [state, setState] = useState({});

  useEffect(() => {
    if (!persona) return;
    const personaKeys = ['unverifiedIpn', 'unverifiedIpi', 'unverifiedIsni'];

    const patchedState = Object.fromEntries(
      personaKeys.map((key) => [
        key,
        key in personaPatch ? personaPatch[key] : persona[key] || null,
      ]),
    );
    setState(patchedState);
  }, [personaPatch, persona]);

  useEffect(() => {
    if (isOpen) {
      setLocalCustomIds(persona?.customIds || []);
    } else {
      setLocalCustomIds([]);
    }
  }, [isOpen]);

  const handleSave = () => {
    const personaPatchCopy = { ...personaPatch };
    Object.keys(personaPatch).forEach((key) => {
      personaPatch[key] = personaPatch[key] || null;
      if (personaPatch[key] === null && !persona[key]) {
        delete personaPatchCopy[key];
      }
    });

    const personaVisibilityPatchCopy = { ...personaVisibilityPatch };
    Object.keys(personaVisibilityPatch).forEach((key) => {
      if (personaVisibilityPatch[key] === personaAccess[key]) {
        delete personaVisibilityPatchCopy[key];
      }
    });
    if (Object.keys(personaVisibilityPatchCopy).length >= 1) {
      updateAccess({
        method: 'PATCH',
        payload: personaVisibilityPatchCopy,
      });
    }
    if (Object.keys(attrVisibilityPatch).length > 0) {
      updateAccess({
        method: 'ATTR_PATCH',
        attrsType: 'customIds',
        payload: attrVisibilityPatch,
      });
    }
    if (!areAttrsUnchanged(persona.customIds || [], localCustomIds)) {
      personaPatchCopy['customIds'] = localCustomIds;
    }

    if (Object.keys(personaPatchCopy).length >= 1) {
      updatePersona({ method: 'PATCH', payload: personaPatchCopy });
    }

    setAttrVisibilityPatch({});
    setPersonaVisibilityPatch({});
    setPersonaPatch({});
    closeModal();
  };

  const handleCancel = () => {
    setPersonaPatch({});
    setPersonaVisibilityPatch({});
    setAttrVisibilityPatch({});
    closeModal();
  };

  const appendNewCustomId = () => {
    setLocalCustomIds((prev) => [
      ...prev,
      { uniqueKey: uuidv4(), fieldName: '', value: '' },
    ]);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setLocalCustomIds((oldIdObjs) => {
        const oldIndex = oldIdObjs.findIndex(
          (obj) => obj.uniqueKey === active.id,
        );
        const newIndex = oldIdObjs.findIndex(
          (obj) => obj.uniqueKey === over.id,
        );

        return arrayMove(oldIdObjs, oldIndex, newIndex);
      });
    }
  };

  const patchedPersonaVisibility = {
    ...personaAccess,
    ...personaVisibilityPatch,
  };
  const patchedAttrVisibility = { ...idAttrAccess, ...attrVisibilityPatch };

  const customIDKeys = localCustomIds.map((idObj) => idObj.uniqueKey);

  const isHidden = !personaAccess?.customIds;

  return (
    <Modal
      open={isOpen}
      onClose={handleCancel}
      onSave={handleSave}
      aria-labelledby="edit/create ID"
      aria-describedby="editing and creating IDs"
    >
      <Stack direction="column" className={classes.edit}>
        {state.unverifiedIsni === null ? (
          <CPAddButton
            size="small"
            onClick={() =>
              setPersonaPatch((p) => ({ ...p, unverifiedIsni: '' }))
            }
          >
            ADD AN ISNI
          </CPAddButton>
        ) : (
          <EditStandardID
            hardcodedLabel="isni"
            helpText="ISNI is a global standard number for identifying the millions of contributors to creative works. It is not used exclusively by the music industry, like IPNs and IPIs. Not all music makers have an ISNI… or maybe you have one and do not even know. To check whether you have an ISNI you can search their public database at isni.org"
            value={state.unverifiedIsni}
            setValue={(val) =>
              setPersonaPatch((p) => ({ ...p, unverifiedIsni: val }))
            }
            isVisible={patchedPersonaVisibility.unverifiedIsni}
            setVisible={(v) =>
              setPersonaVisibilityPatch((p) => ({ ...p, unverifiedIsni: v }))
            }
            areIdsHidden={isHidden}
          />
        )}
        {state.unverifiedIpn === null ? (
          <CPAddButton
            size="small"
            onClick={() =>
              setPersonaPatch((p) => ({ ...p, unverifiedIpn: '' }))
            }
          >
            ADD AN IPN
          </CPAddButton>
        ) : (
          <EditStandardID
            hardcodedLabel="ipn"
            value={state.unverifiedIpn}
            helpText="The IPN is the International Performer Number. It is an identifier that the music industry uses to identify and pay you if you are a performer and/or producer. If you are a PPL member, the IPN will be your PPL membership number, for instance. You can find your IPN in your royalties statement or online portal of the Performing Rights Society you are a member of as a performer/producer. Our first IPN verification partner is PPL. If you are a PPL member, click “verify my Industry Identifiers” to receive your verified IPN."
            setValue={(val) =>
              setPersonaPatch((p) => ({ ...p, unverifiedIpn: val }))
            }
            isVisible={patchedPersonaVisibility.unverifiedIpn}
            setVisible={(v) =>
              setPersonaVisibilityPatch((p) => ({ ...p, unverifiedIpn: v }))
            }
            areIdsHidden={isHidden}
          />
        )}
        {state.unverifiedIpi === null ? (
          <CPAddButton
            size="small"
            onClick={() =>
              setPersonaPatch((p) => ({ ...p, unverifiedIpi: '' }))
            }
          >
            ADD AN IPI
          </CPAddButton>
        ) : (
          <EditStandardID
            hardcodedLabel="ipi"
            value={state.unverifiedIpi}
            setValue={(val) =>
              setPersonaPatch((p) => ({ ...p, unverifiedIpi: val }))
            }
            helpText="The IPI is the Interested Party Information. It is an identifier that the music industry uses to identify and pay you if you are a composer/lyricist/songwriter. If you are a PRS member, the IPI will be your PRS membership number, or former CAE, for instance. You can find your IPI in your royalties statement or online portal of the Performing Rights Society you are a member of as a songwriter/composer."
            isVisible={patchedPersonaVisibility.unverifiedIpi}
            setVisible={(v) =>
              setPersonaVisibilityPatch((p) => ({ ...p, unverifiedIpi: v }))
            }
            areIdsHidden={isHidden}
          />
        )}
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={customIDKeys}
            strategy={verticalListSortingStrategy}
          >
            {localCustomIds.map((idObj) => (
              <EditCustomID
                key={idObj.uniqueKey}
                id={idObj.uniqueKey}
                uniqueKey={idObj.uniqueKey}
                label={idObj.fieldName}
                setLabel={(val) =>
                  setLocalCustomIds((oldCidList) =>
                    oldCidList.map((oldObj) =>
                      oldObj.uniqueKey === idObj.uniqueKey
                        ? { ...oldObj, fieldName: val }
                        : oldObj,
                    ),
                  )
                }
                value={idObj.value}
                setValue={(val) =>
                  setLocalCustomIds((oldCidList) =>
                    oldCidList.map((oldObj) =>
                      oldObj.uniqueKey === idObj.uniqueKey
                        ? { ...oldObj, value: val }
                        : oldObj,
                    ),
                  )
                }
                isVisible={patchedAttrVisibility[idObj.uniqueKey]}
                setVisible={(v) =>
                  setAttrVisibilityPatch((ps) => ({
                    ...ps,
                    [idObj.uniqueKey]: v,
                  }))
                }
                onDelete={() =>
                  setLocalCustomIds((oldCidList) =>
                    oldCidList.filter(
                      (oldObj) => oldObj.uniqueKey !== idObj.uniqueKey,
                    ),
                  )
                }
                areIdsHidden={isHidden}
              />
            ))}
          </SortableContext>
        </DndContext>
        <CPAddButton size="small" onClick={() => appendNewCustomId()}>
          ADD Custom ID
        </CPAddButton>
      </Stack>
    </Modal>
  );
};

export default EditIDs;
