import Modal from '../../CMSModal';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import DraggableProjectItem from '../DraggableProjectItem';
import CPAddButton from '../../CPAddButton';
import { v4 as uuidv4 } from 'uuid';
import { useContext, useEffect, useState } from 'react';
import { PersonaContext } from '../../../../v2api/PersonaContext/PersonaContext';
import { PersonaAccessContext } from '../../../../v2api/PersonaAccessContext/PersonaAccessContext';
import { areAttrsUnchanged } from '../../../../v2api/extraTypes';

const EditProjects = ({ isOpen, closeModal }) => {
  const { persona, updatePersona } = useContext(PersonaContext);
  const { personaAccess, projAttrAccess, updateAccess } =
    useContext(PersonaAccessContext);
  const [localProjects, setLocalProjects] = useState(
    persona?.customProjects || [],
  );
  const [visibilityPatch, setVisibilityPatch] = useState({});

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10, // Adjust this value as needed
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  useEffect(() => {
    if (isOpen) {
      setLocalProjects(persona?.customProjects || []);
    } else {
      setLocalProjects([]);
    }
  }, [isOpen, persona?.customProjects]);

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = localProjects.findIndex(
        (proj) => proj.uniqueKey === active.id,
      );
      const newIndex = localProjects.findIndex(
        (proj) => proj.uniqueKey === over.id,
      );
      setLocalProjects((items) => arrayMove(items, oldIndex, newIndex));
    }
  };

  const handleAdd = () => {
    setLocalProjects((state) => [
      ...state,
      {
        uniqueKey: uuidv4(), // XXX: should all uuids be created server-side?
        fieldName: '',
        value: '',
      },
    ]);
  };

  const handleChange = (patch) =>
    setLocalProjects((state) =>
      state.map((proj) => {
        if (proj.uniqueKey === patch.uniqueKey) {
          return { ...proj, ...patch };
        }
        return proj;
      }),
    );

  const handleDelete = (uniqueKey) =>
    setLocalProjects((state) =>
      state.filter((proj) => proj.uniqueKey !== uniqueKey),
    );

  const handleCancel = () => {
    setLocalProjects(persona.customProjects || []);
    setVisibilityPatch({});
    closeModal();
  };

  const handleSave = () => {
    if (Object.keys(visibilityPatch).length > 0) {
      updateAccess({
        method: 'ATTR_PATCH',
        attrsType: 'customProjects',
        payload: visibilityPatch,
      });
    }
    if (!areAttrsUnchanged(persona.customProjects || [], localProjects)) {
      updatePersona({
        method: 'PATCH',
        payload: { customProjects: localProjects },
      });
    }

    setVisibilityPatch({});
    closeModal();
  };

  const patchedVisibility = { ...projAttrAccess, ...visibilityPatch };
  const isHidden = !personaAccess?.customProjects;

  return (
    <Modal
      open={isOpen}
      onClose={handleCancel}
      onSave={handleSave}
      aria-labelledby="edit/create Project"
      aria-describedby="editing and creating Projects"
    >
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={localProjects?.map((el) => el.uniqueKey)}
          strategy={verticalListSortingStrategy}
        >
          {localProjects?.map((el) => (
            <DraggableProjectItem
              key={el.uniqueKey}
              id={el.uniqueKey}
              projectData={el}
              handleChange={handleChange}
              handleDelete={handleDelete}
              isVisible={patchedVisibility[el.uniqueKey]}
              setVisible={(v) =>
                setVisibilityPatch((ps) => ({ ...ps, [el.uniqueKey]: v }))
              }
              areProjectsHidden={isHidden}
            />
          ))}
        </SortableContext>
      </DndContext>
      <CPAddButton size="small" onClick={handleAdd}>
        ADD A CUSTOM PROJECT
      </CPAddButton>
    </Modal>
  );
};

export default EditProjects;
