import { IPassport, IPassportPatch, IPersonaSummary } from './types.ts';

const v2ApiBase = process.env.REACT_APP_V2_API_BASE;

export async function getPassport(jwtToken: string): Promise<IPassport> {
  const url = `${v2ApiBase}/passport/`;
  const headers = {
    Accept: 'application/json',
  };
  if (jwtToken) {
    headers['Authorization'] = 'Bearer ' + jwtToken;
  }
  const response = await fetch(url, { headers: headers });
  if (!response.ok) {
    throw new Error(JSON.stringify(await response.json()));
  }
  const responseJson = await response.json();
  return {
    passportId: responseJson['passport_id'],
    email: responseJson['email'],
    personaIds: responseJson['persona_ids'],
    unverifiedFirstname: responseJson['unverified_firstname'],
    unverifiedLastname: responseJson['unverified_lastname'],
    unverifiedCountry: responseJson['unverified_country'],
    unverifiedGender: responseJson['unverified_gender'],
    unverifiedDob: responseJson['unverified_dob'],
    yotiClaims: responseJson['yoti_claims'],
    personaSummaries: responseJson['persona_summaries'].map((jsonPS) => ({
      personaId: jsonPS['persona_id'],
      personaType: jsonPS['persona_type'],
      artistName: jsonPS['artist_name'],
      isProfessionalProfile: jsonPS['is_professional_profile']
    }))
  };
}

export async function patchPassport(jwtToken: any, patch: IPassportPatch): Promise<boolean> {
  const url = `${v2ApiBase}/passport/`;
  const headers = { Authorization: 'Bearer ' + jwtToken, 'Content-Type': 'application/json' };
  if (!jwtToken) throw new Error('No token provided');
  const patchJson = Object.fromEntries(Object.entries(patch).map(([key, val]) => [{
    unverifiedFirstname: 'unverified_firstname',
    unverifiedLastname: 'unverified_lastname',
    unverifiedCountry: 'unverified_country',
    unverifiedGender: 'unverified_gender',
    unverifiedDob: 'unverified_dob'
  }[key], val]));
  const response = await fetch(url, { method: 'PATCH', headers: headers, body: JSON.stringify(patchJson) });
  return response.ok;
}

export async function postNewPersona(jwtToken: any, newPersona: IPersonaSummary): Promise<string | null> {
  const url = `${v2ApiBase}/persona/`;
  const headers = { Authorization: 'Bearer ' + jwtToken, 'Content-Type': 'application/json' };
  if (!jwtToken) throw new Error('No token provided');
  if (newPersona.personaId !== null) throw new Error('Cannot post existing persona');
  const postJson = {
    artist_name: newPersona.artistName,
    is_professional_profile: newPersona.isProfessionalProfile,
    persona_type: newPersona.personaType,
  };
  const response = await fetch(url, { method: 'POST', headers: headers, body: JSON.stringify(postJson) });
  if (!response.ok) {
    console.warn(response.statusText);
    return null;
  }
  return await response.json();

}

export async function deletePersona(jwtToken: any, personaId: string): Promise<boolean> {
  const url = `${v2ApiBase}/persona/${personaId}/`;
  const headers = { Authorization: 'Bearer ' + jwtToken, 'Content-Type': 'application/json' };
  if (!jwtToken) throw new Error('No token provided');
  const response = await fetch(url, { method: 'DELETE', headers: headers });
  return response.ok;
}
