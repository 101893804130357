import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 24 24">
      <title>Go</title>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g id="Atoms/icons/arrow-complete/right" fill="#7B7D7F">
          <path
            d="M11.3731798,2 L22.4842909,12 L11.3731798,22 L10,20.4742447 L18.303,13 L2,13 L2,11 L18.305,11 L10,3.52575532 L11.3731798,2 Z"
            id="Combined-Shape"
          ></path>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
