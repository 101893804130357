import React, { useContext, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import styles from './styles';
import IconTextButton from '../../../components/IconTextButton';
import Plus from '../../../icons/atomsIconsAdd';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CPVisibleIcon from '../CPVisibleIcon';
import { EditBio } from './EditBio';
import CMSTitleInfo from '../Title';
import AtomHand from '../../../icons/atomHand';
import { PersonaContext } from '../../../v2api/PersonaContext/PersonaContext';
import { PersonaAccessContext } from '../../../v2api/PersonaAccessContext/PersonaAccessContext';

const useStyles = makeStyles(styles);

const CMSBioTabPanel = ({ value, index, data, isVisible }) => {
  const classes = useStyles();
  return (
    <Box
      role="tabpanel"
      className={classes.tabPanel}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box style={{ display: 'flex' }} sx={{ p: 2 }}>
          <Typography style={{ flex: 1, whiteSpace: 'pre-wrap' }}>
            {data}
          </Typography>
          <Box style={{ marginRight: '-10px', marginTop: '-10px' }}>
            <CPVisibleIcon isVisible={isVisible} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CMSBio = () => {
  const classes = useStyles();

  const { persona } = useContext(PersonaContext);
  const { personaAccess } = useContext(PersonaAccessContext);
  const [editorOpen, setEditorOpen] = useState(false);

  const [bioTabIndex, setBioTabIndex] = React.useState(0);

  // check if there is any bio
  const nBios =
    !!persona?.biographyLong +
    !!persona?.biographyShort +
    !!persona?.biographyHeadline;

  return (
    <Box className={classes.root}>
      <EditBio isOpen={editorOpen} closeModal={() => setEditorOpen(false)} />
      {nBios > 0 ? (
        <>
          <CMSTitleInfo
            title="Bios"
            isNotEmpty={true}
            message="Edit bio"
            onClick={() => setEditorOpen(true)}
          />

          <Box>
            <Tabs
              className={classes.tabs}
              value={bioTabIndex}
              onChange={(e, newIndex) => setBioTabIndex(newIndex)}
              TabIndicatorProps={{ style: { display: 'none' } }}
            >
              {persona?.biographyHeadline && (
                <Tab
                  key={'headline'}
                  label={'headline'}
                  {...a11yProps('headline')}
                  className={classes.tab}
                  sx={[
                    nBios === 1
                      ? {
                          display: 'none',
                        }
                      : {
                          display: 'inline',
                        },
                  ]}
                />
              )}
              {persona?.biographyShort && (
                <Tab
                  key={'short'}
                  label={'short'}
                  {...a11yProps('short')}
                  className={classes.tab}
                  sx={[
                    nBios === 1
                      ? {
                          display: 'none',
                        }
                      : {
                          display: 'inline',
                        },
                  ]}
                />
              )}
              {persona?.biographyLong && (
                <Tab
                  key={'long'}
                  label={'long'}
                  {...a11yProps('long')}
                  className={classes.tab}
                  sx={[
                    nBios === 1
                      ? {
                          display: 'none',
                        }
                      : {
                          display: 'inline',
                        },
                  ]}
                />
              )}
            </Tabs>
          </Box>
          {persona?.biographyHeadline && (
            <CMSBioTabPanel
              key={'headline'}
              value={bioTabIndex}
              index={0}
              data={persona.biographyHeadline}
              isVisible={personaAccess?.biographyHeadline}
            />
          )}
          {persona?.biographyShort && (
            <CMSBioTabPanel
              key={'short'}
              value={bioTabIndex}
              index={persona?.biographyHeadline ? 1 : 0}
              data={persona.biographyShort}
              isVisible={personaAccess?.biographyShort}
            />
          )}
          {persona?.biographyLong && (
            <CMSBioTabPanel
              key={'long'}
              value={bioTabIndex}
              index={!!persona?.biographyHeadline + !!persona?.biographyShort}
              data={persona.biographyLong}
              isVisible={personaAccess?.biographyLong}
            />
          )}
        </>
      ) : (
        <>
          <CMSTitleInfo title="Bios" message="Edit Bios" />
          <Box className={classes.empty}>
            <Stack
              gap={4}
              direction="column"
              alignItems="center"
              justifyContent="center"
              height="250px"
            >
              <AtomHand sx={{ width: '32px' }} />
              <Typography variant="body1">
                Add a couple of lines about yourself
              </Typography>
              <IconTextButton
                onClick={() => setEditorOpen(true)}
                size="standard"
                icon={<Plus hasGradient={true} sx={{ fontSize: 18 }} />}
              >
                Add your bio
              </IconTextButton>
            </Stack>
          </Box>
        </>
      )}
    </Box>
  );
};

export default CMSBio;
