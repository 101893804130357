import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 24 24">
      <title>Your Information is public. Click to make private.</title>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Atoms/icons/private/Default" fill="#ACAFB2">
          <path
            d="M20.2929,1.9999 L21.7069,3.4139 L3.7069,21.4139 L2.2929,19.9999 L20.2929,1.9999 Z M19.8574,6.6738 L24.0004,11.7068 L19.4854,17.1928 L12.0004,19.7068 L8.1274,18.4048 L9.7064,16.8258 L9.7084,16.8268 L10.9774,15.5578 C11.3054,15.6458 11.6444,15.7068 12.0004,15.7068 C14.2064,15.7068 16.0004,13.9118 16.0004,11.7068 C16.0004,11.3518 15.9384,11.0128 15.8504,10.6848 L15.8504,10.6848 L17.4104,9.1248 C17.7844,9.9078 18.0004,10.7808 18.0004,11.7068 C18.0004,13.5518 17.1654,15.2008 15.8544,16.3018 L15.8544,16.3018 L18.3054,15.4788 L21.4104,11.7068 L18.9354,8.7008 L18.1084,8.4228 L19.8574,6.6738 Z M11.9995,3.7074 L15.8695,5.0064 L14.5805,6.2954 C14.5805,6.2964 14.5815,6.2964 14.5815,6.2964 L13.0235,7.8564 C12.6945,7.7684 12.3555,7.7074 11.9995,7.7074 C9.7945,7.7074 7.9995,9.5014 7.9995,11.7074 C7.9995,12.0624 8.0615,12.4014 8.1495,12.7294 L8.1495,12.7294 L6.5895,14.2894 C6.2155,13.5054 5.9995,12.6324 5.9995,11.7074 C5.9995,10.6544 6.2725,9.6674 6.7475,8.8064 L6.7475,8.8064 L5.5145,9.2214 L3.0295,12.2404 L5.5605,15.3154 L4.1395,16.7364 L1.7345,13.8144 L-0.0005,11.7074 L4.5145,6.2214 L11.9995,3.7074 Z"
            id="Combined-Shape"
          ></path>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
