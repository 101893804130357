import React, { useState } from 'react';
import {
  Button,
  Container,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchIcon from '../icons/searchIcon.js';
import SignedInHeader from '../components/SignedInHeader';
import PublicNavbar from '../public-pages/components/PublicNavbar';
import Skeleton from '@mui/material/Skeleton';
import { useAuth } from 'react-oidc-context';
import SearchCard from './SearchCard';
import { Box } from '@mui/system';

const RESULTS_PER_PAGE = 6; // 10

const useStyles = makeStyles((theme) => ({
  searchRow: {
    width: '100%',
    display: 'flex',
    marginBottom: 'theme.spacing(2)',
    paddingTop: '1rem',
    [theme.breakpoints.only('xs')]: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
      paddingTop: '0rem',
    },
  },
  gridItem: {
    display: 'flex',
    alignItems: 'stretch',
  },
  searchField: {
    width: '100%',
    height: '50px',
    transition: '0.3s',
    //boxShadow: '2px 2px 6px 0px rgba(0,0,0,0.075)',
    '&:hover': {
      boxShadow: 'none',
      borderBottom: '1px solid #000',

      //backgroundColor: theme.palette.background.paper,
      //borderRadius: '0.75rem',
      //borderBottom: '0px solid #C5C8CC',
    },
    borderBottom: '1px solid #C5C8CC',
  },
  input: {
    paddingLeft: '0px',
    backgroundColor: '#000!important',
  },
  searchButton: {
    marginLeft: '1rem',
    padding: '0rem 2rem',
    color: '#626467',
    textTransform: 'capitalize',
    backgroundColor: theme.palette.background.paper,
  },
  searchicon: {
    marginBottom: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    color: 'grey',
    fontSize: 18,
  },
  skeleton: {
    borderRadius: '1rem',
    width: '100%',
    height: '203px',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.only('xs')]: {
      borderRadius: '0rem',
    },
  },
  query: {
    marginLeft: '0rem',
    [theme.breakpoints.only('xs')]: {
      marginLeft: '1rem',
    },
  },
}));

function SearchPortal() {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();
  const urlParams = new URLSearchParams(location.search);
  const query = urlParams.get('q');
  const [searchField, setSearchField] = useState(query || '');
  const [pageStatus, setPageStatus] = useState('init'); // init, found, not-found
  const [results, setResults] = useState([]);
  const [pagesLoaded, setPagesLoaded] = useState(0);
  const [moreToLoad, setMoreToLoad] = useState(false);

  const handleSearchChange = (e) => {
    setSearchField(e.target.value);
    // TODO realtime/suggestions (debounce)
  };

  const handleSubmit = (e) => {
    // FIXME submit search on page load with ?q=foo
    e.preventDefault();
    if (searchField && searchField !== '') {
      urlParams.set('q', searchField);
      navigate(`${location.pathname}?${urlParams.toString()}`);
      fetch(
        `${process.env.REACT_APP_V2_API_BASE}/search/personas/?query=${encodeURIComponent(searchField)}&limit=${RESULTS_PER_PAGE}`,
      ).then((response) => {
        if (response.status === 404) {
          return setPageStatus('not-found');
        }
        if (!response.ok) {
          return setPageStatus('error');
        }
        setPageStatus('found');
        setPagesLoaded(1);
        response.json().then((rslts) => {
          setResults(rslts);
          setMoreToLoad(rslts?.length >= RESULTS_PER_PAGE);
        });
      });
    } else {
      navigate(location.pathname);
    }
  };

  const loadMore = () => {
    // TODO: test changing search field then loading more doesn't load mixed results
    fetch(
      `${process.env.REACT_APP_V2_API_BASE}/search/personas/?query=${encodeURIComponent(searchField)}&offset=${pagesLoaded * RESULTS_PER_PAGE}&limit=${RESULTS_PER_PAGE}`,
    ).then((response) => {
      if (response.status === 404) {
        return setMoreToLoad(false);
      }
      if (!response.ok) {
        return setPageStatus('error');
      }
      setPagesLoaded((n) => n + 1);
      response.json().then((rslts) => {
        setResults((prevRslts) => [...prevRslts, ...rslts]);
        setMoreToLoad(rslts.length >= RESULTS_PER_PAGE);
      });
    });
  };

  let pageContent;
  if (pageStatus === 'init') {
    pageContent = null;
  } else if (pageStatus === 'not-found') {
    pageContent = <Typography>No Profiles Found</Typography>;
  } else if (pageStatus === 'loading') {
    // TODO, nothing sets this
    pageContent = (
      <>
        <Typography my={2}>
          Searching passports for <b>{query}</b>…
        </Typography>
        <Grid container spacing={2} mb={4}>
          {Array(6)
            .fill()
            .map((_, i) => (
              <Grid key={i} item md={6} xs={12}>
                <Skeleton variant="rounded" className={classes.skeleton} />
              </Grid>
            ))}
        </Grid>
      </>
    );
  } else if (pageStatus === 'found') {
    pageContent = (
      <div>
        <Box display="flex" flexDirection="column">
          <Typography my={2} className={classes.query}>
            Search results for <b>{query}</b>:
          </Typography>
          <Grid container spacing={2} mb={4}>
            {results.map((personaResult) => (
              <Grid
                item
                key={personaResult.persona_id}
                md={6}
                xs={12}
                className={classes.gridItem}
              >
                <SearchCard personaResult={personaResult} />
              </Grid>
            ))}
          </Grid>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginBottom="50px"
          >
            {moreToLoad && (
              <Button sx={{ maxWidth: '300px' }} onClick={loadMore}>
                Load more results
              </Button>
            )}
          </Box>
        </Box>
      </div>
    );
  } else {
    pageContent = <div>Error</div>;
  }

  return (
    <>
      {auth.isAuthenticated ? <SignedInHeader /> : <PublicNavbar />}
      <Container>
        <form onSubmit={handleSubmit}>
          <div className={classes.searchRow}>
            <TextField
              sx={{
                '& fieldset': { border: 'none' },
              }}
              fullWidth
              id="standard-search"
              className={classes.searchField}
              value={searchField}
              placeholder="Search for professionals, artists, bands, and so on"
              onChange={handleSearchChange}
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment
                      sx={{ paddingRight: '5px' }}
                      position="start"
                    >
                      <SearchIcon className={classes.searchicon} />
                    </InputAdornment>
                  ),
                },
              }}
            />
            <Button className={classes.searchButton} type="submit">
              Go
            </Button>
          </div>
        </form>
        {pageContent}
      </Container>
    </>
  );
}

export default SearchPortal;
