import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 24 24">
      <title>Notifications</title>
      <defs>
        <linearGradient
          x1="78.125%"
          y1="100%"
          x2="21.9264264%"
          y2="0.0914246886%"
          id="linearGradient-1"
        >
          <stop stop-color="#FF2667" offset="0.0914246886%"></stop>
          <stop stop-color="#73AEE6" offset="50.9814282%"></stop>
          <stop stop-color="#00FFCC" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g
        id="Symbols"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Atoms/icons/Notifications/Default"
          fill={props.fill ? 'url(#linearGradient-1)' : '#626466'}
        >
          <path
            d="M8.686,0 L4,4.687 L4,8 L4,13.001 L2,15 L2,19 L8,19 L8,21.691 L12,24 L16,21.691 L16,19 L22,19 L22,15 L20,13.001 L20,8 L20,4.687 L15.314,0 L8.686,0 Z M20,15.586 L18,13.586 L18,5.273 L13.728,1 L14.9,1 L19,5.101 L19,13.415 L21,15.414 L21,18 L20,18 L20,15.586 Z M11.268,22.424 L15,20.268 L15,21.114 L12,22.846 L11.268,22.424 Z"
            id="Fill-1"
          ></path>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
