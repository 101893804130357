import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <title>Suggestions</title>
      <g
        id="PWA---UI"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="UI-Elements"
          transform="translate(-600.000000, -723.000000)"
          fill="#5079A1"
        >
          <g id="Combined-Shape" transform="translate(596.000000, 723.000000)">
            <path d="M15.9997,22.0003 L15.9997,24.0003 L8.0007,24.0003 L8.0007,22.0003 L15.9997,22.0003 Z M15.314,2.84217094e-14 L20,4.687 L20,11.314 L16.028,15.286 L16.028,21.001 L8.029,21.001 L8.029,15.343 L4,11.314 L4,4.687 L8.686,2.84217094e-14 L15.314,2.84217094e-14 Z M14.486,2.001 L9.515,2.001 L6.999,4.515 L6.999,10.314 L11.029,14.343 L11.029,18 L14.028,18 L14.028,14.457 L14.614,13.872 L18,10.486 L18,5.515 L14.486,2.001 Z"></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
