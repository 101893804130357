import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
    >
      <title>atoms/icons/users</title>
      <defs>
        <linearGradient
          x1="78.125%"
          y1="100%"
          x2="21.9264264%"
          y2="0.0914246886%"
          id="linearGradient-1"
        >
          <stop stop-color="#FF2667" offset="0.0914246886%"></stop>
          <stop stop-color="#73AEE6" offset="50.9814282%"></stop>
          <stop stop-color="#00FFCC" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Profile-|-Basic---UI"
          transform="translate(-444.000000, -28.000000)"
          fill="url(#linearGradient-1)"
        >
          <g
            id="atoms/icons/users"
            transform="translate(444.000000, 28.000000)"
          >
            <path
              d="M19.5015584,1 L23,4.5 L23,11.989 L17.1626003,17.829 L12.0058974,22.999 L10.7104744,22.999 L9.33708621,21.625 L14.691701,16.268 L14.0439895,15.62 L8.69037429,20.977 L8.60541213,20.893 L6.6692746,18.956 L12.1478341,13.475 L11.5001227,12.827 L6.02156312,18.308 L4.394288,16.68 L4.00146298,16.287 L9.60496688,10.682 L8.95625585,10.034 L3.35375151,15.639 L2.2872266,14.572 L2.2872266,13.312 L8.11263165,7.484 L8.93126698,8.302 C9.82486892,9.197 10.9973466,9.644 12.1698244,9.644 C13.9760198,9.644 15.8231969,7.888 16.7177984,6.993 C17.0746395,6.635 17.0746395,6.055 16.7177984,5.697 L13.2983217,2.272 L14.5657571,1 L19.5015584,1 Z M9.42224827,1.0023 L11.66325,3.2493 L11.9941026,3.5813 L14.6639133,6.2353 C14.7398795,6.5443 13.0696235,7.8643 12.1700243,7.8113 C11.4373506,7.7673 10.7456588,7.5253 10.2268898,7.0053 L8.11383111,4.8923 L6.95734627,6.0493 L1.01599288,11.9923 L1,11.9763 L1,4.5003 L4.48844606,1.0023 L9.42224827,1.0023 Z"
              id="Combined-Shape"
            ></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
