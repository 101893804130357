import React, { useState, useContext, useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Box, Typography } from '@mui/material';
import countries from 'i18n-iso-countries';
import ProfileImage from '../../../components/ProfileImage';
import styles from './styles';
import SummaryEdit from './SummaryEdit';
import CPEditButton from '../CPEditButton';
import CPVisibleIcon from '../CPVisibleIcon';
import LocationIcon from '../../../icons/location';
import GenderIcon from '../../../icons/gender';
import DateofBirthIcon from '../../../icons/birthday';

import { PersonaContext } from '../../../v2api/PersonaContext/PersonaContext';
import { PassportContext } from '../../../v2api/PassportContext/PassportContext';
import { PersonaAccessContext } from '../../../v2api/PersonaAccessContext/PersonaAccessContext';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
const useStyles = makeStyles(styles);

const PersonaSummary = ({ isNew, onDelete }) => {
  const { persona } = useContext(PersonaContext);
  const { passport } = useContext(PassportContext);
  const { personaAccess } = useContext(PersonaAccessContext);
  const [isEditMode, setIsEditMode] = useState(isNew);

  const displayName = persona?.isProfessionalProfile
    ? `${passport.unverifiedFirstname || ''} ${
        passport.unverifiedLastname || ''
      }`
    : persona?.artistName;
  const displayCountry = persona?.isProfessionalProfile
    ? passport?.unverifiedCountry
    : persona?.countryAlpha2;
  const displayDob = persona?.isProfessionalProfile
    ? passport?.unverifiedDob
    : persona?.dateOfBirth;

  // const { thumbnail, isFetching } = useFetchImage(
  //   'profile',
  //   persona?.personaId
  // );
  const thumbnail = null;
  const isFetching = false;

  const classes = useStyles();

  const rolesOrFeatures = persona?.isProfessionalProfile
    ? 'musicRoles'
    : 'features';
  return (
    <>
      <SummaryEdit
        isOpen={isEditMode}
        closeModal={useCallback(() => {
          setIsEditMode(false);
        }, [setIsEditMode])}
        onDelete={onDelete}
      />

      <div className={classes.root}>
        <div className={classes.topInfo}>
          <CPEditButton
            title={`Edit ${
              persona?.isProfessionalProfile ? 'Personal' : 'Artist Project'
            } Summary`}
            onClick={() => setIsEditMode(true)}
            data-testid={`edit-${persona?.personaId}-button`}
          />
        </div>
        <div className={classes.body}>
          {/*** <SHARED> ***/}
          {/* Display: Avatar*/}
          <ProfileImage
            personaId={persona?.personaId}
            alt={displayName}
            disableUpload
            isBusy={isFetching}
            isVerified={
              0 /*profile?.VerifiedData ? Object.keys(profile.VerifiedData).length : 0*/
            }
          />
          <div className={classes.bodyInfo}>
            {/*Profile Display: Name*/}
            <Typography
              variant="h1"
              className={classes.name}
              data-testid={`first-last-name-${persona?.personaId}-display`}
            >
              {persona?.isProfessionalProfile && persona.alias ? (
                <>
                  {persona?.aliasOverwritesName
                    ? persona?.alias
                    : `${displayName} (${persona?.alias})`}
                </>
              ) : (
                `${displayName}`
              )}
            </Typography>
            <div className={classes.talents}>
              {persona &&
              rolesOrFeatures in persona &&
              persona[rolesOrFeatures]?.length ? (
                <Typography
                  className={classes.talent}
                  data-testid={`features-roles-${persona?.personaId}-display`}
                >
                  {persona[rolesOrFeatures]
                    .slice(0, 3)
                    .map((el, index, arr) => (
                      <React.Fragment key={el}>
                        {el} {index !== arr.length - 1 && '|'}{' '}
                      </React.Fragment>
                    ))}
                </Typography>
              ) : (
                <Typography />
              )}
            </div>
            <Box sx={{ mt: '1rem' }}>
              <Grid container direction="column" spacing={2}>
                {/*Profile Display: Location*/}
                {(displayCountry || persona?.city) && (
                  <Grid item className={classes.location}>
                    <Typography
                      className={classes.locationTitle}
                      data-testid={`country-${persona?.personaId}-display`}
                    >
                      {(displayCountry || persona?.city) && (
                        <LocationIcon sx={{ width: '16px' }} />
                      )}
                      {persona?.city}
                      {persona?.city && displayCountry ? ', ' : ''}
                      {countries.getName(displayCountry, 'EN')}
                    </Typography>
                    <CPVisibleIcon isVisible={personaAccess?.countryAlpha2} />{' '}
                  </Grid>
                )}
              </Grid>
              {/*** </SHARED> ***/}
              {persona?.isProfessionalProfile &&
              (passport.unverifiedGender || persona.pronouns) ? (
                <Grid item className={classes.location}>
                  {/*** <PROFESSIONAL> ***/}
                  {/*Professional Profile Display: Gender*/}
                  <Typography
                    className={classes.locationTitle}
                    data-testid={`gender-${persona?.personaId}-display`}
                  >
                    <GenderIcon sx={{ width: '16px', color: '#626466' }} />
                    {passport?.unverifiedGender}
                    {passport?.unverifiedGender && persona?.pronouns
                      ? ' | '
                      : null}
                    {persona?.pronouns}
                  </Typography>
                  <CPVisibleIcon isVisible={personaAccess?.pronouns} />
                  {/*** </PROFESSIONAL> ***/}
                </Grid>
              ) : null}

              {/*Profile Display: Birthday/Founding*/}
              {displayDob ? (
                <Grid item className={classes.location}>
                  <Typography className={classes.locationTitle}>
                    <DateofBirthIcon sx={{ width: '16px' }} />
                    {displayDob}
                  </Typography>
                  <CPVisibleIcon isVisible={personaAccess?.dateOfBirth} />
                </Grid>
              ) : null}
            </Box>
          </div>
        </div>
      </div>
      {/*** END OF DISPLAY ***/}
    </>
  );
};

export default PersonaSummary;
