import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import AtomsEyePrivateDefault from '../icons/defaultEyePrivate';
import AtomsEyePublicDefault from '../icons/defaultEyePublic';

const useStyles = makeStyles((theme) => ({
  eye: {
    color: '#ACAFB2',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '50%',
  },
  eyeActive: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '8px',
  },
}));

const CPVisibleIcon = ({ isVisible }) => {
  const classes = useStyles();
  return (
    <div className={isVisible ? classes.eyeActive : classes.eye}>
      {isVisible ? (
        <AtomsEyePublicDefault sx={{ fontSize: '16px' }} />
      ) : (
        <AtomsEyePrivateDefault sx={{ fontSize: '16px' }} />
      )}
    </div>
  );
};

export default CPVisibleIcon;
