import React, { useContext } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import SkillsIcon from '../../../icons/atomsSkills';
import LanguagesIcon from '../../../icons/languages';
import RolesIcon from '../../../icons/atomsRoles';
import InstrumentsIcon from '../../../icons/instruments';
import ToolsAndGearIcon from '../../../icons/toolsAndGear';
import InspirationsIcon from '../../../icons/inspirations';
import FavoriteArtistsIcon from '../../../icons/favoriteArtist';
import EndorsementIcon from '../../../icons/endorsement';
import GenresIcon from '../../../icons/genres';
import SupportedCauses from '../../../icons/supportedCauses';
import QuotesIcon from '../../../icons/quotes';
import FeaturesIcon from '../../../icons/atomsFeatures';
import { useTheme } from '@mui/material/styles';
import CMSTitleInfo from '../../../CMS/components/Title';
import { PersonaContext } from '../../../v2api/PersonaContext/PersonaContext';

const PublicProfile = () => {
  const theme = useTheme();
  const { persona } = useContext(PersonaContext);
  if (!persona) return null;
  if (
    !persona.musicRoles &&
    !persona.features &&
    !persona.otherSkills &&
    !persona.instrumentsPlayed &&
    !persona.instrumentsOwned &&
    !persona.toolsUsed &&
    !persona.toolsOwned &&
    !persona.favouriteMusicMakers &&
    !persona.endorsements?.length &&
    !persona.genres &&
    !persona.spokenLanguages &&
    !persona.interestsInspiration &&
    !persona.charitiesSupported &&
    !persona.favouriteQuote
  )
    return null;
  return (
    <React.Fragment>
      {/* Public Profile Page InfoField Section */}
      <CMSTitleInfo title="Profile Info" />
      <Grid
        mb={4}
        container
        gap={4}
        sx={{
          bgcolor: 'background.paper',
          borderRadius: '1rem',
          [theme.breakpoints.down('sm')]: {
            borderRadius: '0rem',
          },
          p: 3,
        }}
      >
        {persona.isProfessionalProfile
          ? persona.musicRoles && (
              <InfoComponent
                title={'Roles'}
                skills={persona.musicRoles}
                Icon={RolesIcon}
              />
            )
          : persona.features && (
              <InfoComponent
                title={'Keywords'}
                skills={persona.features}
                Icon={FeaturesIcon}
              />
            )}
        {persona.otherSkills && (
          <InfoComponent
            title={'Skills'}
            skills={persona.otherSkills}
            Icon={SkillsIcon}
          />
        )}
        {persona.instrumentsPlayed && (
          <InfoComponent
            title={'Instruments Played'}
            skills={persona.instrumentsPlayed}
            Icon={InstrumentsIcon}
          />
        )}
        {persona.instrumentsOwned && (
          <InfoComponent
            title={'Instruments Owned'}
            skills={persona.instrumentsOwned}
            Icon={InstrumentsIcon}
          />
        )}
        {persona.toolsUsed && (
          <InfoComponent
            title={'Tools Used'}
            skills={persona.toolsUsed}
            Icon={ToolsAndGearIcon}
          />
        )}
        {persona.toolsOwned && (
          <InfoComponent
            title={'Tools Owned'}
            skills={persona.toolsOwned}
            Icon={ToolsAndGearIcon}
          />
        )}
        {persona.favouriteMusicMakers && (
          <InfoComponent
            title={'Favorite Artists'}
            skills={persona.favouriteMusicMakers}
            Icon={FavoriteArtistsIcon}
          />
        )}
        {!!persona.endorsements?.length && (
          <InfoComponent
            title={'Endorsements'}
            skills={persona.endorsements}
            Icon={EndorsementIcon}
          />
        )}
        {persona.genres && (
          <InfoComponent
            title={'Genres'}
            skills={persona.genres}
            Icon={GenresIcon}
          />
        )}
        {persona.spokenLanguages && (
          <InfoComponent
            title={'Languages'}
            skills={persona.spokenLanguages}
            Icon={LanguagesIcon}
          />
        )}
        {persona.interestsInspiration && (
          <InfoComponent
            title={'Interests / Inspirations'}
            skills={persona.interestsInspiration}
            Icon={InspirationsIcon}
          />
        )}
        {persona.charitiesSupported && (
          <InfoComponent
            title={'Supported Causes'}
            skills={persona.charitiesSupported}
            Icon={SupportedCauses}
          />
        )}
        {persona.favouriteQuote && (
          <InfoComponent
            title={'Quotes'}
            skills={persona.favouriteQuote}
            Icon={QuotesIcon}
          />
        )}
      </Grid>
    </React.Fragment>
  );
};

export default PublicProfile;

const InfoComponent = ({ title, skills, Icon }) => {
  const skill_array = typeof skills === 'string' ? [skills] : skills;
  const theme = useTheme();
  return (
    <Grid item>
      <Typography
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: 2,
          fontWeight: '500',
          fontSize: '16px',
          lineHeight: '24px',
        }}
      >
        <Icon
          color={theme.palette.primary.hint}
          sx={{ mr: 1.5, width: '24px', height: '24px' }}
        />{' '}
        {title}
      </Typography>
      <Stack direction="row" rowGap={1} columnGap={1} flexWrap={'wrap'}>
        {skill_array.map((skill) => (
          <Typography
            key={skill}
            sx={(theme) => ({
              bgcolor: theme.palette.green.lighter,
              border: `2px solid ${theme.palette.green.main}`,
              color: theme.palette.green.contrastText,
              px: 2,
              py: 0.5,
              textTransform: 'uppercase',
              fontWeight: '500',
              fontSize: '0.8125rem',
              borderRadius: '0.25rem',
            })}
          >
            {skill}
          </Typography>
        ))}
      </Stack>
    </Grid>
  );
};
