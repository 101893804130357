import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  const { color } = props;
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 24 24">
      <title>Favorite Artist</title>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Atoms/icons/favorite-artist" fill={color || '#626466'}>
          <path
            d="M21.038,19.9984994 L16.962,19.9984994 L16.301,19.33325 L16.613,18.4419157 L21.387,18.4419157 L21.699,19.33325 L21.038,19.9984994 Z M17.99,11.6633738 C17.99,11.3842691 18.141,11.1241716 18.383,10.9831187 L18.598,10.8590722 C18.717,10.7910466 18.852,10.7550331 18.99,10.7550331 L18.99,10.7580343 L19.003,10.7580343 C19.136,10.760035 19.267,10.7930474 19.382,10.8590722 L19.598,10.9831187 C19.84,11.1241716 19.99,11.3842691 19.99,11.6633738 L19.99,13.2749781 L18.99,14.1773165 L17.99,13.2749781 L17.99,11.6633738 Z M7.996,6.71851944 L7.996,3.7344004 L18.99,2.26685007 L18.99,5.25096911 L7.996,6.71851944 Z M5.99,15.2757284 L4.99,16.1780668 L3.99,15.2757284 L3.99,13.664124 C3.99,13.3850194 4.141,13.1249218 4.383,12.983869 L4.598,12.8598224 C4.717,12.7917969 4.852,12.754783 4.99,12.754783 C5.128,12.754783 5.263,12.7917969 5.382,12.8598224 L5.598,12.983869 C5.84,13.1249218 5.99,13.3850194 5.99,13.664124 L5.99,15.2757284 Z M7.699,21.3340003 L7.038,21.9992497 L2.962,21.9992497 L2.301,21.3340003 L2.613,20.442666 L7.387,20.442666 L7.699,21.3340003 Z M22.806,16.4411654 L19.465,16.4411654 L21.99,14.165312 L21.99,13.2289609 L21.995,13.2289609 L21.986,0.990371389 L20.853,-7.10542736e-15 L5.863,2.00075028 L4.996,2.99312242 L5.003,10.7560335 C4.998,10.7560335 4.995,10.7540328 4.99,10.7540328 C4.509,10.7540328 4.029,10.8790797 3.598,11.1271727 L3.382,11.2522196 C2.521,11.749406 1.99,12.6697512 1.99,13.664124 L1.99,16.1660623 L4.515,18.4419157 L1.194,18.4419157 L-2.0250468e-13,21.8551957 L2.129,24 L7.87,24 L10,21.8551957 L8.806,18.4419157 L5.465,18.4419157 L7.99,16.1660623 L7.99,13.7591597 L7.996,13.7591597 L7.996,8.7362761 L18.99,7.26872577 L18.99,8.75428286 C18.509,8.75428286 18.029,8.87832937 17.598,9.12642241 L17.382,9.2514693 C16.521,9.74865575 15.99,10.6690009 15.99,11.6633738 L15.99,14.165312 L18.515,16.4411654 L15.194,16.4411654 L14,19.8544454 L16.129,21.9992497 L21.87,21.9992497 L24,19.8544454 L22.806,16.4411654 Z"
            id="Fill-1"
          ></path>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
