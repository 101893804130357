import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  const { color } = props;
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <title>Quotes</title>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Atoms/icons/quotes" fill={color || '#626466'}>
          <path
            d="M4.686,1 L2.999,2.686 L0,5.686 L0,12 L0,23 L11,23 L11,20 L11,12 L6.999,12 L6.999,8.585 L7.585,7.999 L11,7.999 L11,1 L4.686,1 Z M2.999,8.999 L2.999,5.514 L5.514,3 L9,3 L9,5.999 L7.585,5.999 L6.757,5.999 L6.171,6.585 L5.585,7.171 L4.999,7.756 L4.999,8.585 L4.999,12 L4.999,14 L6.999,14 L9,14 L9,20 L2.999,20 L2.999,8.999 Z M17.6856,1 L15.9986,2.686 L12.9996,5.686 L12.9996,12 L12.9996,23 L23.9996,23 L23.9996,20 L23.9996,12 L19.9996,12 L19.9996,8.585 L20.5856,7.999 L23.9996,7.999 L23.9996,1 L17.6856,1 Z M15.9986,8.999 L15.9986,5.514 L18.5136,3 L21.9996,3 L21.9996,5.999 L20.5856,5.999 L19.7576,5.999 L19.1716,6.585 L18.5856,7.171 L17.9996,7.756 L17.9996,8.585 L17.9996,12 L17.9996,14 L19.9996,14 L21.9996,14 L21.9996,20 L15.9986,20 L15.9986,8.999 Z"
            id="Combined-Shape"
          ></path>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
