import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <title>Official Links</title>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Atoms/icons/link/UI" fill="#626466">
          <path
            d="M6.27,9.1572 L7.699,10.5872 L4.143,14.1432 L9.142,19.1422 L12.699,15.5862 L13.413,16.3012 L14.842,17.7292 L10.572,22.0002 L7.714,22.0002 L2,16.2852 L2,13.4282 L6.27,9.1572 Z M14.5003,6.6425 L15.2153,7.3575 L15.2153,7.3565 L16.6423,8.7855 L8.7853,16.6425 L7.3573,15.2145 L6.6433,14.4995 L14.5003,6.6425 Z M16.2861,2 L22.0001,7.714 L22.0001,10.571 L17.6991,14.873 L16.2701,13.443 L15.5571,12.729 L19.8581,8.429 L14.8571,3.428 L10.5561,7.729 L9.1281,6.3 L13.4291,2 L16.2861,2 Z"
            id="Combined-Shape"
          ></path>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
