// // A method to check an object propery is unique in an array
// const rSpotifyId = /^[0-9A-Za-z]{22,}$/;
//
// const isValidSpotifyUri = (value) => {
//   if (!value.startsWith('spotify:')) {
//     return false;
//   }
//   const parts = value.split(':');
//   if (
//     parts.length !== 3 ||
//     ['artist', 'album', 'track', 'playlist', 'user', 'episode', 'show'].indexOf(
//       parts[1]
//     ) === -1
//   ) {
//     return false;
//   }
//   return rSpotifyId.test(parts[2]);
// };
//
// const spotifyUriTest = (value) => {
//   if (value == null) {
//     return true;
//   }
//   if (yup.string().url().isValidSync(value, { strict: true })) {
//     return true;
//   }
//   return isValidSpotifyUri(value);
// };
//
// const rDiscord =
//   /^((?!(discordtag|everyone|here)#)((?!@|#|:|```).{2,32})#\d{4})$/;
//
// const discordOrUrlTest = (value) => {
//   const schemas = [
//     yup.string().url(),
//     yup.string().matches(rDiscord, value, { excludeEmptyString: true }),
//   ];
//   return schemas.some((s) => s.isValidSync(value, { strict: true }));
// };
//
const removeSpaces = function (value, originalvalue) {
  return this.isType(value) && value !== null ? value.replace(/ /g, '') : value;
};

const upperCase = function (v) {
  return this.isType(v) && v != null ? v.toUpperCase() : v;
};

export const checkIsni = (v) =>
  v
    .transform(removeSpaces)
    .transform(upperCase)
    .length(16)
    .matches(
      /^[0-9]*X?$/,
      ({ path }) => `${path} must only contain digits (ISNI may end with an X)`,
    )
    // eslint-disable-next-line no-template-curly-in-string
    .test('validIsni', '${path} is invalid', (value) => {
      if (value === '' || value == null) {
        return true;
      }
      const chars = '0123456789X';
      const m = 11;
      const r = 2;
      const p = value
        .slice(0, 15)
        .split('')
        .map((d) => chars.indexOf(d))
        .reduce((p, val) => ((p + val) * r) % m);
      const checksum = (m - p + 1) % m;
      return chars[checksum] === value[15];
    });
