import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { Toaster } from 'react-hot-toast';
import { UserManager, WebStorageStateStore } from 'oidc-client-ts';
import { AuthProvider } from 'react-oidc-context';

import BaseThemeProvider from './themes/main';
import './styles/App.css';
// FIXME switch colouring
// import 'antd/lib/switch/style/index.css'; // Just import switch CSS
import SignedInRoutes from './components/SignedInRoutes';
import SearchPortal from './search-portal/SearchPortal';
import PublicPage from './public-pages/components/PublicPage';
import TrackingCode from './fathomtracking';
import WelcomePage from './pages/welcome';
import SigninCallback from './pages/SigninCallback';

export const userManager = new UserManager({
  authority: process.env.REACT_APP_KEYCLOAK_REALM_URI,
  client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
  redirect_uri: `${window.location.origin}/signin-callback`,
  post_logout_redirect_uri: window.location.origin,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  monitorSession: true, // this allows cross tab login/logout detection
});

export const onSigninCallback = (user) => {
  console.warn('onSigninCallback');
  window.history.replaceState({}, document.title, window.location.pathname);
};

const App = () => {
  return (
    <BaseThemeProvider>
      <CssBaseline />
      <TrackingCode />
      <AuthProvider
        userManager={userManager}
        onSigninCallback={onSigninCallback}
      >
        <Toaster position="top-center" />
        <BrowserRouter>
          <Routes>
            <Route exact path="/signin-callback" element={<SigninCallback />} />
            <Route path="/welcome" element={<WelcomePage />} />
            <Route exact path="/search" element={<SearchPortal />} />
            <Route
              path="/page/:passportId/:personaId"
              element={<PublicPage />}
            />
            <Route path="*" element={<SignedInRoutes />} />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </BaseThemeProvider>
  );
};

export default App;
