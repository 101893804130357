import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 24 24">
      <title>Your Information is public. Click to make private.</title>
      <defs>
        <linearGradient
          id="GradientActive"
          gradientUnits="userSpaceOnUse"
          x1="0%"
          y1="0%"
          x2="100%"
          y2="0%"
          gradientTransform="rotate(45)"
        >
          <stop offset="0%" stopColor="#00FFCC" stopOpacity="0" />
          <stop offset="51%" stopColor="#73AEE6" stopOpacity="1" />
          <stop offset="100%" stopColor="#FF2667" stopOpacity="1" />
        </linearGradient>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="UI-Elements"
          transform="translate(-968.000000, -676.000000)"
          fill="#626466"
        >
          <g
            id="public-active"
            transform="translate(968.000000, 676.000000)"
            fill="url(#GradientActive)"
          >
            <path
              d="M12.003,4 L19.488,6.515 L24.003,12 L19.488,17.485 L12.003,20 L4.518,17.485 L1.239,13.502 L1.237,13.504 L-3.55271368e-14,12 L4.515,6.515 L12,4 L12.003,4 Z M12,7.008 L7.003,8.687 C6.372,9.636 6.003,10.774 6.003,12 C6.003,15.313 8.69,18 12.003,18 C15.316,18 18.003,15.313 18.003,12 C18.003,10.775 17.635,9.639 17.005,8.689 L17.005,8.689 L12,7.008 Z M12.0001,10 C13.1041,10 14.0001,10.896 14.0001,12 C14.0001,13.104 13.1041,14 12.0001,14 C10.8961,14 10.0001,13.104 10.0001,12 C10.0001,10.896 10.8961,10 12.0001,10 Z"
              id="Combined-Shape"
            ></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
