import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  return (
    <SvgIcon viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <filter
          x="-50.0%"
          y="-50.0%"
          width="200.0%"
          height="200.0%"
          filterUnits="objectBoundingBox"
          id="filter-1"
        >
          <feOffset
            dx="2"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            stdDeviation="3"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0"
            type="matrix"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          ></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Dialogs"
          transform="translate(-764.000000, -285.000000)"
          fill="#626466"
        >
          <g
            id="Components/Overview-section/Profile/Editing---full"
            transform="translate(120.000000, 129.000000)"
          >
            <g
              id="Group-2-Copy-6"
              filter="url(#filter-1)"
              transform="translate(648.000000, 160.000000)"
            >
              <g
                id="atoms/icons/edit"
                transform="translate(8.000000, 8.000000)"
              >
                <path
                  d="M9.54066667,0 L11.0793333,2.66733333 L14.6666667,2.66733333 L14.6666667,4 L13.3366667,4 L13.3366667,14.2093333 L10.212,16 L5.794,16 L2.67,14.2093333 L2.67,4 L1.33333333,4 L1.33333333,2.66733333 L4.92133333,2.66733333 L6.46066667,0 L9.54066667,0 Z M12.0026667,4 L4.66933333,4 L4.66933333,13.5426667 L6.632,14.6673333 L9.85733333,14.6673333 L12.0026667,13.4366667 L12.0026667,4 Z M7.319,4.66753333 L7.319,12.6675333 L5.98566667,12.6675333 L5.98566667,4.66753333 L7.319,4.66753333 Z M9.997,4.66753333 L9.997,12.6675333 L8.66366667,12.6675333 L8.66366667,4.66753333 L9.997,4.66753333 Z M8.77,1.33333333 L7.23066667,1.33333333 L6.46066667,2.66733333 L9.54066667,2.66733333 L8.77,1.33333333 Z"
                  id="Combined-Shape"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
