import Modal from '../../CMSModal';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { v4 as uuidv4 } from 'uuid';
import React, { useContext, useEffect, useState } from 'react';
import { PersonaContext } from '../../../../v2api/PersonaContext/PersonaContext';
import { PersonaAccessContext } from '../../../../v2api/PersonaAccessContext/PersonaAccessContext';
import { areAttrsUnchanged } from '../../../../v2api/extraTypes';
import { text_fields } from '../../../../text_fields';
import { Button, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import styles from '../styles';
import DraggableLinkItem from '../DraggableLinkItem';

const useStyles = makeStyles(styles);

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => (images[item.replace('./', '')] = r(item)));
  return images;
}

const logos = importAll(
  require.context(
    '../../../../shared/icons/logos',
    false,
    /\.(png|jpe?g|svg)$/,
  ),
);

const specialLinks = text_fields['knownOfficialLinkTypes'];

const EditOfficialLinks = ({ isOpen, closeModal }) => {
  const classes = useStyles();

  const { persona, updatePersona } = useContext(PersonaContext);
  const { personaAccess, linkAttrAccess, updateAccess } =
    useContext(PersonaAccessContext);
  const [localLinks, setLocalLinks] = useState(persona?.officialLinks || []);
  const [visibilityPatch, setVisibilityPatch] = useState({});

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10, // Adjust this value as needed
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  useEffect(() => {
    if (isOpen) {
      setLocalLinks(persona?.officialLinks || []);
    } else {
      setLocalLinks([]);
    }
  }, [isOpen, persona?.officialLinks]);

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = localLinks.findIndex(
        (oli) => oli.uniqueKey === active.id,
      );
      const newIndex = localLinks.findIndex((oli) => oli.uniqueKey === over.id);

      setLocalLinks((items) => arrayMove(items, oldIndex, newIndex));
    }
  };

  const handleAdd = (fieldName) => {
    setLocalLinks((state) => [
      ...state,
      {
        uniqueKey: uuidv4(), // XXX: should all uuids be created server-side?
        fieldName: fieldName,
        value: '',
      },
    ]);
  };

  const handleChange = (patch) =>
    setLocalLinks((state) =>
      state.map((oli) => {
        if (oli.uniqueKey === patch.uniqueKey) {
          return { ...oli, ...patch };
        }
        return oli;
      }),
    );

  const handleDelete = (uniqueKey) =>
    setLocalLinks((state) =>
      state.filter((oli) => oli.uniqueKey !== uniqueKey),
    );

  const handleCancel = () => {
    setLocalLinks(persona.officialLinks || []);
    setVisibilityPatch({});
    closeModal();
  };

  const handleSave = () => {
    const nonRedundantVisPatch = {};
    // FIXME: new projects set as visible won't be patched. race condition...
    Object.entries(linkAttrAccess || []).forEach(([key, value]) => {
      if (key in visibilityPatch && visibilityPatch[key] !== value) {
        nonRedundantVisPatch[key] = visibilityPatch[key];
      }
    });
    if (Object.keys(nonRedundantVisPatch).length > 0) {
      updateAccess({
        method: 'ATTR_PATCH',
        attrsType: 'officialLinks',
        payload: nonRedundantVisPatch,
      });
    }
    if (!areAttrsUnchanged(persona.officialLinks || [], localLinks)) {
      updatePersona({
        method: 'PATCH',
        payload: { officialLinks: localLinks },
      });
    }

    setVisibilityPatch({});
    closeModal();
  };

  const patchedVisibility = { ...linkAttrAccess, ...visibilityPatch };

  const buttons = Object.entries(specialLinks).map(([name, data]) => {
    let icon = (
      <img
        src={logos[`${name}.svg`] || logos[`${name}.png`]}
        className={classes.icon}
        alt={name}
        draggable="false"
      />
    );

    return (
      <Tooltip
        title={data.label}
        classes={{ tooltip: classes.customWidth }}
        arrow
      >
        <Button
          data-testid={name}
          key={name}
          onClick={() => handleAdd(name)}
          value={name}
          className={classes.button}
        >
          {icon}
        </Button>
      </Tooltip>
    );
  });

  return (
    <Modal
      open={isOpen}
      onClose={handleCancel}
      onSave={handleSave}
      aria-labelledby="edit/create custom links"
      aria-describedby="editing and creating links"
    >
      <div>
        <div className={classes.buttonBed}>
          <div className={classes.buttonContainer}>{buttons}</div>
        </div>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={localLinks?.map((el) => el.uniqueKey)}
            strategy={verticalListSortingStrategy}
          >
            {localLinks.map((el) => (
              <DraggableLinkItem
                key={el.uniqueKey}
                id={el.uniqueKey}
                linkData={el}
                handleChange={handleChange}
                handleDelete={handleDelete}
                isVisible={patchedVisibility[el.uniqueKey]}
                setVisible={(v) =>
                  setVisibilityPatch((ps) => ({ ...ps, [el.uniqueKey]: v }))
                }
                areLinksHidden={!personaAccess?.officialLinks}
              />
            ))}
          </SortableContext>
        </DndContext>
      </div>
    </Modal>
  );
};

export default EditOfficialLinks;
