import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  const { color } = props;
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 24 24">
      <title>Mail</title>
      <g
        id="Symbols"
        stroke="none"
        stroke-width="1"
        fill="#626466"
        fill-rule="evenodd"
      >
        <g id="Atoms/icons/Mail/UI" fill={color || '#626466'}>
          <path
            d="M2,2 L0,4 L0,20 L2,22 L22,22 L24,20 L24,4 L22,2 L2,2 Z M3.415,4 L20.586,4 L12,12.586 L3.415,4 Z M11.293,14.706 L11.702,14.706 L12.707,14.706 L22,5.414 L22,18.585 L16.828,13.414 L15.415,14.828 L19.586,18.999 L4.414,18.999 L8.586,14.828 L7.172,13.414 L3,17.585 L3,7.419 L10.287,14.706 L11.293,14.706 Z"
            id="Fill-1"
          ></path>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
