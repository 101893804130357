import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  const { color } = props;
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 24 24">
      <title>Integrations</title>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="FF2667"
        fillRule="evenodd"
      >
        <g id="Atoms/icons/integrations/Default" fill={color || '#626466'}>
          <path
            d="M15,0 L13,2 L13,7 L11,7 L11,2 L9.001,0 L8.001,0 L6,2 L6,7 L3,7 L3,15.728 L8.273,21 L11,21 L11,24 L13,24 L13,21 L15.728,21 L16.728,20 L21,15.728 L21,7 L18.001,7 L18.001,2 L16,0 L15,0 Z M15,7 L16,7 L16,2 L15,2 L15,7 Z M8.001,7 L9.001,7 L9.001,2 L8.001,2 L8.001,7 Z M6,14.729 L6,9 L8.001,9 L9.001,9 L15,9 L16,9 L19.001,9 L19.001,14.9 L14.9,19 L10.272,19 L6,14.729 Z"
            id="Fill-1"
          ></path>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
