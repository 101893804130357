import React from 'react';
import { SvgIcon } from '@mui/material';

export default function Icon(props) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      {...props}
    >
      <title>Close</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="UI-Elements"
          transform="translate(-1748.000000, -291.000000)"
          fill="#00000070"
        >
          <g id="close" transform="translate(1748.000000, 291.000000)">
            <polygon
              id="Fill-1"
              points="18.545735 4 12.0005142 10.5452208 5.45426496 4 4 5.45426496 10.5452208 11.9994858 4 18.545735 5.45426496 20 12.0005142 13.4547792 18.545735 20 20 18.545735 13.4547792 11.9994858 20 5.45426496"
            ></polygon>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
