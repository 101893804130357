import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 24 24">
      <title>Actions</title>
      <defs>
        <linearGradient
          x1="78.125%"
          y1="100%"
          x2="21.9264264%"
          y2="0.0914246886%"
          id="linearGradient-1"
        >
          <stop stop-color="#FF2667" offset="0.0914246886%"></stop>
          <stop stop-color="#73AEE6" offset="50.9814282%"></stop>
          <stop stop-color="#00FFCC" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g
        id="Symbols"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Atoms/icons/actions/Selected"
          fill={props.fill ? 'url(#linearGradient-1)' : '#626466'}
        >
          <path
            d="M16.082,8.8817842e-15 L3,14 L9.573,14 L8.032,23.891 L11.328,20.111 L21.114,8.891 L14.539,8.891 L15.82,1.511 L16.082,8.8817842e-15 Z M12.955,6.285 L12.563,8.548 L12.155,10.897 L14.539,10.897 L16.702,10.897 L11.908,16.394 L12.593,12 L11.916,12 L11.917,11.993 L9.573,11.993 L7.62,11.993 L12.955,6.285 Z"
            id="Fill-1"
          ></path>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
