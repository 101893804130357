import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material/';
import styles from './styles';
import CPAddButton from '../../CPAddButton';
import CPVisibleToggle from '../../CPVisibleToggle';
import Plus from '../../../../icons/atomsIconsAdd';
import SuggestionIcon from '../../../../icons/suggestion';
import CPAutocomplete from '../../CPAutocomplete';
import { TextField } from '@mui/material';

const useStyles = makeStyles(styles);

const InfoFieldEdit = ({
  Icon,
  title,
  description,
  values,
  isVisible,
  setValues,
  setVisible,
  defaultSuggestions,
  personaId,
  secondaryValues,
  setSecondaryValues,
  primarySubheading,
  secondarySubheading,
  secondaryDefaultSuggestions,
  isString,
}) => {
  const MAX_SUGGESTIONS = 4;
  const classes = useStyles();
  const [suggestions, setSuggestions] = useState(
    defaultSuggestions?.filter(
      (sug, i) => !values?.includes(sug) && i < MAX_SUGGESTIONS,
    ) || [],
  );
  const [secondarySuggestions, setSecondarySuggestions] = useState(
    secondaryDefaultSuggestions?.filter(
      (sug, i) => !values?.includes(sug) && i < MAX_SUGGESTIONS,
    ) || [],
  );
  const [editMode, setEditMode] = useState(false);

  const handleInput = (e, newValues) => {
    setSuggestions(
      defaultSuggestions?.filter(
        (sug, i) => !newValues.includes(sug) && i < MAX_SUGGESTIONS,
      ) || [],
    );
    setValues(newValues.filter((v) => v.trim() !== ''));
  };

  const handleSecondaryInput = (e, newValues) => {
    setSecondarySuggestions(
      secondaryDefaultSuggestions?.filter(
        (sug, i) => !newValues.includes(sug) && i < MAX_SUGGESTIONS,
      ) || [],
    );
    setSecondaryValues(newValues.filter((v) => v.trim() !== ''));
  };

  if (!editMode && !values?.length)
    return (
      <CPAddButton
        size="small"
        onClick={() => setEditMode(true)}
        data-testid={`${personaId}-add-${title}-button`}
      >
        Add {title}
      </CPAddButton>
    );

  if (secondaryValues)
    return (
      <div className={classes.root} data-testid={`${personaId}-edit-${title}`}>
        <div className={classes.heading}>
          <div className={classes.headingText}>
            <Icon className={classes.headingTextIcon} />
            <p className={classes.headingTextP}>{title}</p>
          </div>
          <CPVisibleToggle
            darker={true}
            isVisible={isVisible}
            setVisible={setVisible}
          />
        </div>
        <p
          className={classes.subHeading}
          data-testid={`${personaId}-used-${title}`}
        >
          {primarySubheading}
        </p>
        <CPAutocomplete
          data-testid={`select-${title}-used-titles`}
          multiple
          fullWidth
          freeSolo
          options={suggestions || []}
          value={values}
          onChange={handleInput}
          placeholder="Type and hit enter"
        />
        <p
          className={classes.subHeading}
          data-testid={`${personaId}-owned-${title}`}
        >
          {secondarySubheading}
        </p>
        <CPAutocomplete
          data-testid={`select-${title}-menu`}
          multiple
          fullWidth
          freeSolo
          options={secondarySuggestions || []}
          value={secondaryValues}
          onChange={handleSecondaryInput}
          placeholder="Type and hit enter"
        />
      </div>
    );

  return (
    <div className={classes.root} data-testid={`${personaId}-edit-${title}`}>
      <div className={classes.heading}>
        <div className={classes.headingText}>
          <Icon className={classes.headingTextIcon} />
          <p className={classes.headingTextP}>{title}</p>
        </div>
        <CPVisibleToggle
          darker={true}
          isVisible={isVisible}
          setVisible={setVisible}
        />
      </div>
      <p className={classes.description}>{description}</p>
      {(suggestions?.length || null) && (
        <div className={classes.suggestions}>
          <Typography
            className={classes.suggestionsTitle}
            data-testid={`${personaId}-suggest-${title}`}
          >
            <SuggestionIcon />
            suggested
          </Typography>
          <div className={classes.suggestionsFlex}>
            {suggestions.map((el) => (
              <div
                className={classes.suggestion}
                key={el}
                onClick={() => handleInput(undefined, [...values, el])}
              >
                <p className={classes.suggestionP}>{el}</p>
                <Plus sx={{ fontSize: 14, color: '#5079A1' }} />
              </div>
            ))}
          </div>
        </div>
      )}
      <div data-testid={`${personaId}-dropdown-${title}`}>
        {isString ? (
          <TextField
            className={classes.input}
            placeholder={'Type here'}
            value={values}
            variant="standard"
            onChange={(e) => setValues(e.target.value)}
          />
        ) : (
          <CPAutocomplete
            data-testid={`select-${title}-menu`}
            multiple
            freeSolo
            options={defaultSuggestions || []}
            value={values}
            name={title}
            onChange={handleInput}
            placeholder="Type and hit enter"
          />
        )}
      </div>
    </div>
  );
};

export default InfoFieldEdit;
