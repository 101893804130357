import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  const { color } = props;
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <title>Supported Causes</title>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Atoms/icons/supported-causes" fill={color || '#626466'}>
          <path
            d="M15.089,-5.68434189e-14 L20.09,3.633 L22,9.511 L15.881,16.869 L12.538,12.901 L16.926,7.515 L12.09,4.001 L11.91,4.001 L7.073,7.515 L20.506,24 L14.048,24 L2.935,10.635 L2,9.511 L3.91,3.633 L8.91,-5.68434189e-14 L15.089,-5.68434189e-14 Z M8.1188,18.4333 L11.3498,22.3193 L9.9518,24.0003 L3.4938,24.0003 L8.1188,18.4333 Z M4.677,7.742 L4.243,9.08 L14.986,22.001 L16.294,22.001 L10.535,14.941 L5.521,8.777 L4.677,7.742 Z M19.322,7.742 L15.135,12.879 L15.872,13.753 L19.757,9.08 L19.322,7.742 Z"
            id="Combined-Shape"
          ></path>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
