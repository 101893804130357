import { makeStyles } from '@mui/styles';
import { Typography } from 'antd';
import React from 'react';

const styles = (theme) => ({
  label: {
    backgroundColor: '#5079A1',
    textTransform: 'uppercase',
    color: 'white',
    padding: '2px 20px',
    borderRadius: '4px',
    fontWeight: 'bold',
    fontSize: '12px',
  },
});

const useStyles = makeStyles(styles);

const LabelBox = ({ children, props }) => {
  const classes = useStyles();
  return (
    <Typography className={classes.label} {...props}>
      {children}
    </Typography>
  );
};

export default LabelBox;
