import React from 'react';
import withStyles from '@mui/styles/withStyles';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';

const styles = (theme) => ({
  offset: theme.mixins.toolbar,
  text: {
    margin: 'auto 10px',
    textAlign: 'center',
  },
  link: {
    color: '#626466',
    textDecoration: 'underline',
    '&:hover': {
      color: '#E8E8E8',
    },
  },
  appBar: {
    position: 'fixed',
    backgroundColor: '#E5FFF9',
    top: 'auto',
    bottom: 0,
    minHeight: '40px',
    zIndex: '1299', // Dialogs start at 1300
  },
});

const thisYear = new Date().getFullYear();

const Footer = ({ classes }) => {
  return (
    <React.Fragment>
      <div className={classes.offset} />
      <AppBar className={classes.appBar} component="footer">
        <Typography className={classes.text}>
          Creative Passport &copy; {thisYear} –{' '}
          <a
            className={classes.link}
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.creativepassport.net/terms"
          >
            Privacy Policy &amp; Terms of Use
          </a>
        </Typography>
      </AppBar>
    </React.Fragment>
  );
};

Footer.displayName = 'Footer';
export default withStyles(styles)(Footer);
