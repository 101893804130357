import { useState, useContext } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import CMSTitleInfo from '../Title';
import IconTextButton from '../../../components/IconTextButton';
import { makeStyles } from '@mui/styles';
import Plus from '../../../icons/atomsIconsAdd';
import { ReadOnlyProject } from './ReadOnlyProject';
import PassportIcon from '../../../icons/idIcon';
import EditProjects from './EditProjects';
import { PersonaContext } from '../../../v2api/PersonaContext/PersonaContext';
import { PersonaAccessContext } from '../../../v2api/PersonaAccessContext/PersonaAccessContext';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0.5rem',
      marginRight: '1rem',
    },
  },
  main: {
    width: '100%',
    padding: '1rem',
    backgroundColor: '#FFFFFF',
    borderRadius: '18px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0',
    },
  },
  edit: {
    padding: '0.2rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: '10px',
    gap: '0.75rem',
    [theme.breakpoints.down('sm')]: {
      width: '85%',
      margin: 'auto',
    },
  },
}));

const Projects = () => {
  const classes = useStyles();

  const { persona } = useContext(PersonaContext);
  const { personaAccess, projAttrAccess, updateAccess } =
    useContext(PersonaAccessContext);
  const [editorOpen, setEditorOpen] = useState(false);

  const projectList = persona?.customProjects || [];

  return (
    <>
      <EditProjects
        isOpen={editorOpen}
        closeModal={() => setEditorOpen(false)}
      />
      <Box className={classes.root}>
        <CMSTitleInfo
          title="Projects"
          isNotEmpty={projectList.some((el) => el.fieldName)}
          message="Edit Projects"
          onClick={() => setEditorOpen(true)}
          isVisible={personaAccess?.customProjects}
          setVisible={(v) =>
            updateAccess({
              method: 'PATCH',
              payload: { customProjects: v },
            })
          }
          isVisibilityDisabled={!personaAccess?.personaId}
        />
        {projectList.some((el) => el.fieldName) ? (
          <Stack spacing={2}>
            {projectList.map((proj) => (
              <ReadOnlyProject
                key={proj.uniqueKey}
                customAttr={proj}
                isVisible={projAttrAccess?.[proj.uniqueKey]}
              />
            ))}
          </Stack>
        ) : (
          <Box className={classes.main}>
            <Stack
              gap={4}
              direction="column"
              alignItems="center"
              justifyContent="center"
              height="250px"
            >
              <PassportIcon sx={{ width: '32px' }} />
              <Typography variant="body1">
                If you have any custom projects you can list them here
              </Typography>
              <IconTextButton
                onClick={() => setEditorOpen(true)}
                size="standard"
                icon={<Plus hasGradient={true} sx={{ fontSize: 18 }} />}
              >
                Add your custom projects
              </IconTextButton>
            </Stack>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Projects;
