import React from 'react';
import PublicBios from './SubComponents/PublicBios';
import OfficialLinks from './SubComponents/OfficialLinks';
import PublicProfile from './SubComponents/PublicProfile';

const ProfileMainContents = () => {
  return (
    <React.Fragment>
      <PublicProfile />
      <PublicBios />
      <OfficialLinks />
    </React.Fragment>
  );
};

export default ProfileMainContents;
