import { Stack, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import Delete from '../../../icons/atomsIconsDelete';
import CPVisibleToggle from '../../CPVisibleToggle';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export const EditCustomID = ({
  uniqueKey,
  label,
  setLabel,
  value,
  setValue,
  isVisible,
  setVisible,
  onDelete,
  areIdsHidden,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: uniqueKey });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const cursor = isDragging ? 'grabbing' : 'grab';

  return (
    <Box
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      sx={{
        backgroundColor: '#F7F8F9',
        padding: 2,
        borderRadius: 2,
      }}
    >
      <Stack
        alignItems="start"
        direction="row"
        justifyContent="space-between"
        sx={{ cursor: cursor }}
      >
        <Stack>
          <Typography
            sx={{
              lineHeight: '32px',
              color: '#626466',
              fontWeight: 'bold',
              textTransform: 'uppercase',
            }}
          >
            Custom ID
          </Typography>
          <TextField
            variant="standard"
            label={'ID Label'}
            type="text"
            value={label}
            onKeyDown={(e) => e.stopPropagation()} // fixes a dnd-kit bug
            onChange={(e) => setLabel(e.target.value)}
            placeholder={'ID Label'}
            sx={{ width: '100%', mt: 1 }}
          />
        </Stack>

        <Stack direction="row" spacing={1.5} alignItems="center">
          <CPVisibleToggle
            darker={true}
            isVisible={isVisible}
            setVisible={setVisible}
            isDisabled={areIdsHidden}
          />
          <Box
            sx={{
              backgroundColor: 'white',
              boxShadow: '2px 2px 6px 0px rgba(0,0,0,0.1)',
              height: '35px',
              width: '35px',
              borderRadius: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#D3D3D3',
              },
            }}
            onClick={() => onDelete()}
          >
            <Delete
              sx={{
                fontSize: '40px',
              }}
            />
          </Box>
        </Stack>
      </Stack>

      <TextField
        type="text"
        label={'ID Code'}
        variant="standard"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={(e) => e.stopPropagation()} // fixes a dnd-kit bug
        placeholder={'ID Code'}
        sx={{ width: '100%', mt: 3 }}
        disabled={false}
      />
    </Box>
  );
};
