import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <filter
          x="-50.0%"
          y="-50.0%"
          width="200.0%"
          height="200.0%"
          filterUnits="objectBoundingBox"
          id="filter-1"
        >
          <feOffset
            dx="2"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            stdDeviation="3"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0"
            type="matrix"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          ></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
      </defs>
      <g
        id="Atoms/icons/edit/UI"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <path
          d="M15.813453,1 L7.18858002,9.62550822 L0,16.8127541 L0,20.9506806 L0,24 L7.18858002,24 L15.813453,15.3755082 L23,8.18724589 L23,5.31275411 L18.6890883,1 L15.813453,1 Z M2.03296946,17.6543663 L5.51646263,14.1720435 L7.18858002,12.5 L8.62588942,11.0627541 L10.0631988,9.62550822 L17.2507624,2.43826233 L20.9355195,6.1218402 L13.747956,13.3090861 L12.3106466,14.746332 L12.3106466,14.7473484 L12.3096301,14.746332 L10.8723207,16.1845943 L6.10602378,20.9506806 L2.03296946,20.9506806 L2.03296946,17.6543663 Z"
          id="Fill-1"
          fill="#626466"
        ></path>
      </g>
    </SvgIcon>
  );
}

export default Icon;
