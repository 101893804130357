const styles = (theme) => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
  },
  image: {
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: 3,
    width: '95%',
    height: '100%',
    margin: 'auto',
    padding: 0,
    border: '2px solid transparent',
    background:
      'linear-gradient(270deg, #ff00b4 0%, #82b4dc 49%, #00ffcc 100%) border-box',
    'mask-image':
      ' linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)',
    '-webkit-mask-composite': 'destination-out',
    'mask-composite': 'exclude',
  },
  emptyImage: {
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: 3,
    width: '95%',
    height: '100%',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '50px',
  },
  inputField: {
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: 5,
    width: 'inherit',
    height: 'inherit',
    opacity: '0',
  },
  inputHelper: {
    display: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: 4,
    width: 'inherit',
    height: 'inherit',
    backgroundColor: 'rgba(255,255,255, 0.4)',
    borderRadius: '50%',
    fontSize: '50px',
  },

  checkmark: {
    position: 'absolute',
    bottom: '-8px',
    left: '68px',
    padding: '5px',
    paddingTop: '2px',
    backgroundColor: '#ffffff',
    zIndex: 100,
  },
  '@keyframes spin': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
  },
  inputLoader: {
    animation: '$spin 1s linear infinite',
  },
});

export default styles;
