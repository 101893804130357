import React from 'react';
import { SvgIcon } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function Icon(props) {
  const theme = useTheme();
  const { primary } = theme.palette;

  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 17" {...props}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-601 -28)">
          <g transform="translate(601 28.5)">
            <path
              d="M10 0L8.667 1.333v3.334H7.333V1.333L6.001 0h-.667L4 1.333v3.334H2v5.818L5.515 14h1.818v2h1.334v-2h1.818l.667-.667L14 10.485V4.667h-2V1.333L10.668 0H10zm0 4.667h.667V1.333H10v3.334zm-4.666 0h.667V1.333h-.667v3.334zM4 9.819V6h8.667v3.933l-2.734 2.734H6.848L4 9.819z"
              fill={primary.hint}
            ></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
