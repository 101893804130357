import { makeStyles } from '@mui/styles';
import { Typography, Box } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    padding: '1.5rem',
    borderRadius: '1rem',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0rem',
    },
  },
  gear: {
    width: '70px',
    height: '70ox',
    marginBottom: '1rem',
    [theme.breakpoints.down('md')]: {
      marginBottom: '0.5rem',
    },
  },
  title: {
    marginBottom: '1rem',
    [theme.breakpoints.down('md')]: {
      marginBottom: '0rem',
    },
  },
}));

const ConnectionsBlock = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.topHeader}>
        <Typography variant="h4" className={classes.title}>
          Connections
        </Typography>
      </div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          component="img"
          src="/images/icon/gear.svg"
          className={classes.gear}
        />
        <Typography sx={{ fontWeight: '500' }}>Work in progress</Typography>
        <Typography sx={{ textAlign: 'center' }}>
          This functionality is under construction
        </Typography>
      </Box>
    </div>
  );
};

export default ConnectionsBlock;
