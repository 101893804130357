import React, { useContext } from 'react';
import { Box, Tab, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useTheme } from '@mui/material/styles';
import CMSTitleInfo from '../../../CMS/components/Title';
import { PersonaContext } from '../../../v2api/PersonaContext/PersonaContext';

const PublicBios = () => {
  const { persona } = useContext(PersonaContext);
  const theme = useTheme();

  const [bioTabIndex, setBioTabIndex] = React.useState(0);

  // check if there is any bio
  const nBios =
    !!persona?.biographyLong +
    !!persona?.biographyShort +
    !!persona?.biographyHeadline;
  const title = nBios === 1 ? 'Bio' : 'Bios';
  const styles = {
    tabs: {
      width: '100%',
      minHeight: '100%',
      paddingLeft: '0px !important',
      paddingRight: '0px !important',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      overflow: 'hidden !important'
    },
    tab: {
      marginLeft: '0px',
      justifyContent: 'center',
      borderRadius: '0.75rem',
      marginBottom: '20px',
      flexGrow: 1,
      height: '50px',
      paddingTop: '15px',
      '&.MuiTab-root': {
        minHeight: 0,
        verticalAlign: 'middle'
      },
      '&.Mui-selected': {
        backgroundColor: '#000',
        borderRadius: '0.75rem',
        fontFamily: 'Roboto, Sans-serif',
        color: '#313233',
        textStroke: '0.3px black',
        WebkitTextStroke: '0.3px black',
        strokeLinejoin: 'round',
        '&:hover': {
          backgroundColor: '#000'
        }
      },
      '&#simple-tab-headline': {
        marginRight: '0px !important'
        // marginLeft: 'auto',
      },
      '&#simple-tab-short': {
        margin: '0px !important'
      },
      '&#simple-tab-long': {
        marginRight: '0px !important'
      },
      textTransform: 'capitalize'
      // marginLeft: 'auto',
    },
    tabPanel: {
      backgroundColor: theme.palette.background.paper,
      width: '100%',
      border: 'none',
      resize: 'none',
      borderRadius: '1rem',
      fontFamily: 'inherit',
      '&:focus-visible': {
        outline: 'none'
      }
    }
  };

  if (!nBios) return null;
  return (
    (<React.Fragment>
      <CMSTitleInfo title={title} />
      <Box mb={4}>
        <TabContext value={bioTabIndex}>
          <Box>
            {nBios > 1 && ( // Conditionally render TabList
              (<TabList
                style={styles.tabs}
                indicatorColor=""
                onChange={(e, t) => setBioTabIndex(t)}
                aria-label="Biography tabs"
              >
                {persona.biographyHeadline && (
                  <Tab
                    style={styles.tab}
                    key={'headline'}
                    label={'headline'}
                    sx={[bioTabIndex === 0 ? {
                      backgroundColor: 'background.paper'
                    } : {
                      backgroundColor: 'transparent'
                    }, {
                      color: 'primary.main'
                    }]}
                  />
                )}
                {persona.biographyShort && (
                  <Tab
                    style={styles.tab}
                    key={'short'}
                    label={'short'}
                    sx={[bioTabIndex === 0 + !!persona.biographyHeadline ? {
                      backgroundColor: 'background.paper'
                    } : {
                      backgroundColor: 'transparent'
                    }, {
                      color: 'primary.main'
                    }]}
                  />
                )}
                {persona.biographyLong && (
                  <Tab
                    style={styles.tab}
                    key={'long'}
                    label={'long'}
                    sx={[bioTabIndex === !!persona.biographyHeadline + !!persona.biographyShort ? {
                      backgroundColor: 'background.paper'
                    } : {
                      backgroundColor: 'transparent'
                    }, {
                      color: 'primary.main'
                    }]}
                  />
                )}
              </TabList>)
            )}
          </Box>
          {persona.biographyHeadline && (
            <TabPanel
              sx={{
                bgcolor: 'background.paper',
                borderRadius: '1rem',
                [theme.breakpoints.down('sm')]: {
                  borderRadius: '0rem'
                },
                p: 3,
                mb: 1
              }}
              value={0}
              style={styles.panel}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  lineHeight: '26px',
                  fontWeight: '400',
                  whiteSpace: 'pre-wrap'
                }}
              >
                {persona.biographyHeadline}
              </Typography>
            </TabPanel>)
          }
          {persona.biographyShort && (
            <TabPanel
              sx={{
                bgcolor: 'background.paper',
                borderRadius: '1rem',
                [theme.breakpoints.down('sm')]: {
                  borderRadius: '0rem'
                },
                p: 3,
                mb: 1
              }}
              value={persona.biographyHeadline ? 1 : 0}
              style={styles.panel}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  lineHeight: '26px',
                  fontWeight: '400',
                  whiteSpace: 'pre-wrap'
                }}
              >
                {persona.biographyShort}
              </Typography>
            </TabPanel>)}
          {persona.biographyLong && (
            <TabPanel
              sx={{
                bgcolor: 'background.paper',
                borderRadius: '1rem',
                [theme.breakpoints.down('sm')]: {
                  borderRadius: '0rem'
                },
                p: 3,
                mb: 1
              }}
              value={!!persona.biographyHeadline + !!persona.biographyShort}
              style={styles.panel}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  lineHeight: '26px',
                  fontWeight: '400',
                  whiteSpace: 'pre-wrap'
                }}
              >
                {persona.biographyLong}
              </Typography>
            </TabPanel>)}
        </TabContext>
      </Box>
    </React.Fragment>)
  );
};

export default PublicBios;
