import { useState, useContext, useCallback } from 'react';
import toast from 'react-hot-toast';
import { makeStyles } from '@mui/styles';
import { Tooltip, Grid, Typography, Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CPSwitch from '../CPSwitch';
import Toolclick from '../CPHelpMessage';
import { useNavigate } from 'react-router-dom';
import SettingsIcon from '../../../icons/atomSettings';
import LinkIcon from '../../../icons/link';
import SettingsModal from '../SettingsModal';
import countries from 'i18n-iso-countries';

import { PersonaContext } from '../../../v2api/PersonaContext/PersonaContext';
import { PassportContext } from '../../../v2api/PassportContext/PassportContext';
import { PersonaAccessContext } from '../../../v2api/PersonaAccessContext/PersonaAccessContext';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'space-between',
    display: 'flex',
    backgroundColor: '#FFF',
    padding: '1.5rem',
    borderRadius: '1rem',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0rem',
    },
  },
  topHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
  },
  help: {
    height: '16px',
    color: '#5079A1',
    width: '100%',
    textAlign: 'left',
    alignItems: 'center',
    fontFamily: 'Heebo, Sans-serif',
    fontSize: '12px',
    display: 'flex',
    marginTop: '0.5rem',
  },
  toolclick: {
    height: '16px',
    width: '16px',
    color: '#5079A1',
    marginTop: '5px',
    marginLeft: '5px',
  },
  buttons: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '2px 2px 6px 0px rgba(0,0,0,0.1)',
    padding: '0.4rem',
  },
  bottomIconsChildIcon: {
    fontSize: '14px',
    color: '#626466',
  },
  option: {
    backgroundColor: theme.palette.background.dark,
    padding: '0.75rem 1.2rem',
    [theme.breakpoints.only('md')]: {
      padding: '0.75rem 1rem',
    },
    borderRadius: '0.75rem',
    display: 'flex',
    alignItems: 'center',
    height: '57px',
  },
}));

const CMSPublicProfile = ({ allowOnMap }) => {
  const { persona } = useContext(PersonaContext);
  const { personaAccess, updateAccess } = useContext(PersonaAccessContext);
  const { passport } = useContext(PassportContext);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleCancel = useCallback(() => setOpen(false), [setOpen]);

  const isVisible = personaAccess?.personaId || false;

  return (
    <>
      <Box flexDirection="column" className={classes.root}>
        <Box>
          <div className={classes.topHeader}>
            <Typography variant="h4">Public Visibility</Typography>
          </div>
          <Typography variant="help" sx={{ mt: '1rem', mb: '1rem' }}>
            <Toolclick
              className={classes.toolclick}
              message="Public pages are individual links to your profile including all the information you have decided to share publicly. You can share the link with others so they can access all your data in one place."
              title="What is a public page?"
            />
          </Typography>
          <Grid
            container
            justifyContent="space-between"
            className={classes.option}
          >
            <Grid item>
              <Typography sx={{ fontWeight: '500' }}>Page</Typography>
            </Grid>
            <Grid item>
              <Grid container spacing={0.8} alignItems="center">
                {isVisible && (
                  <Grid item>
                    <Tooltip title="Customise public page">
                      <IconButton
                        className={classes.buttons}
                        onClick={() => setOpen(!open)}
                      >
                        <SettingsIcon
                          className={classes.bottomIconsChildIcon}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
                <Grid item>
                  <a
                    href={`/page/${passport?.passportId}/${persona?.personaId}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {isVisible && (
                      <Tooltip title="Page URL">
                        <IconButton
                          className={classes.buttons}
                          onClick={() => navigate('#')}
                        >
                          <LinkIcon className={classes.bottomIconsChildIcon} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </a>
                </Grid>
                <Tooltip title="Toggle page visibility">
                  <Grid item>
                    <CPSwitch
                      checked={isVisible}
                      onClick={() =>
                        updateAccess({
                          method: 'POST',
                          payload: !isVisible,
                        })
                      }
                    />
                  </Grid>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          {isVisible && allowOnMap && passport?.unverifiedCountry && (
            <Grid
              container
              justifyContent="space-between"
              className={classes.option}
              mt={1.5}
            >
              <Grid item>
                <Typography sx={{ fontWeight: '500' }}>Map</Typography>
              </Grid>

              <Grid item>
                <Grid container spacing={0.8} alignItems="center">
                  <Grid item>
                    <a
                      href="https://map.creativepassport.net"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Tooltip title="View map">
                        <IconButton className={classes.buttons}>
                          <LinkIcon className={classes.bottomIconsChildIcon} />
                        </IconButton>
                      </Tooltip>
                    </a>
                  </Grid>

                  <Tooltip title="Toggle map visibility">
                    <Grid item>
                      <CPSwitch
                        checked={false}
                        onClick={() => {
                          if (passport?.unverifiedCountry) {
                            console.warn('Not Implemented');
                          } else {
                            toast.error(
                              'Please add a country to your profile before joining the map.',
                            );
                          }
                        }}
                      />
                    </Grid>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Box>
        {/*
        <>
        {!isVisible ? (
          <Typography variant="body1">
            This profile is hidden from public.
          </Typography>
        ) : (
          <>
            {isMapVisible ? (
              <Typography variant="body1">
                A public page for this profile is enabled and is discoverable on
                the map.
              </Typography>
            ) : (
              <Typography variant="body1">
                A public page for this profile is enabled.
              </Typography>
            )}
          </>
        )}
            </>*/}
      </Box>
      <SettingsModal
        open={open}
        previewLink={`page/${passport?.passportId}/${persona?.personaId}`}
        handleCancel={handleCancel}
      />
    </>
  );
};

export default CMSPublicProfile;
