import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    marginTop: 100,
    textAlign: 'center',
  },
});

const PageNotFound = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h2">Page Not Found</Typography>
      <div>
        <Link to="/">Return to Home Page</Link>
      </div>
    </div>
  );
};
export default PageNotFound;
