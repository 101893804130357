import { Button, Typography } from '@mui/material';
import Icon from '../../../icons/cpButtonPlus';

const CPButton = ({ children, ...rest }) => {
  return (
    <Button
      {...rest}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        gap: 1,
        alignItems: 'center',
        maxWidth: '400px',
        backgroundColor: 'white',
        borderRadius: '200px',
      }}
      variant="contained"
    >
      <Icon
        sx={{
          fontSize: '20px',
        }}
      />
      <Typography
        sx={{
          fontSize: '14px !important',
        }}
      >
        {children}
      </Typography>
    </Button>
  );
};

export default CPButton;
