import * as React from 'react';
import Radio from '@mui/material/Radio';
import radio_checked_icon from '../images/icons/Atoms_Radio_checked.svg';
import radio_unchecked_icon from '../images/icons/Atoms_Radio_unchecked.svg';

const BpUnCheckedIcon = (
  <img
    aria-label="Radio Unchecked Icon"
    src={radio_unchecked_icon}
    alt="Radio Unchecked Icon"
  />
);

const BpCheckedIcon = (
  <img
    aria-label="Radio Checked Icon"
    src={radio_checked_icon}
    alt="Radio Checked Icon"
  />
);

function CPRadio(props) {
  return (
    <Radio
      sx={{
        '&:hover': {
          backgroundColor: 'transparent',
        },
      }}
      disableRipple
      color="default"
      checkedIcon={BpCheckedIcon}
      icon={BpUnCheckedIcon}
      {...props}
    />
  );
}

export default CPRadio;
