const styles = (theme) => ({
  welcome: {
    backgroundColor: theme.palette.blue.lighter,
    padding: '1.5rem',
    marginBottom: '1.5rem',
  },
  container: {
    display: 'flex',
    alignItems: 'start',
    gap: theme.spacing(4),
    marginTop: theme.spacing(5),
  },
  containerProgress: {
    width: '30%',
  },
  containerIntegrations: {
    width: '70%',
  },
  progressBtn: {
    padding: '0.5rem 1rem',
    display: 'flex',
    alignItems: 'center',
    gap: '0.25rem',
    color: '#626466',
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    fontWeight: '500',
    fontSize: '12px',
    backgroundColor: '#FFFFFF',
    boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.1)',
    border: 'none',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      marginTop: '0.5rem',
    },
  },
  progressBtnIcon: {
    fontSize: '30px',
  },
  progressLinkContainer: {
    marginTop: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    fontSize: '12px',
  },
  progressLinkIcon: {
    color: '#5079A1',
  },
  progressLink: {
    textDecoration: 'underline',
    color: '5079A1!important',
    fontSize: '14px',
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    fontWeight: '400',
  },
  progressSelect: {
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    maxWidth: '280px',
    margin: '0 auto',
    height: '48px',
    borderRadius: '12px',
    fontSize: '16px',
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    fontWeight: '500',
    LineHeight: '22px',
    paddingLeft: '10px',
    alignItems: 'center',
    color: '#626466',
  },

  integrationHeader: {
    fontSize: '20px',
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    fontWeight: '500',
    margin: 0,
    marginBottom: theme.spacing(2),
    color: '#313233',
  },
  cards: {
    display: 'flex',
    flexWrap: 'wrap',
  },
});

export const progressStyles = (theme) => ({
  progressRoot: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  progressFlex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 0,
    flexDirection: 'column',
  },
  progressTextFlex: {
    display: 'flex',
    alignItems: 'start',
    height: '10px',
    flexDirection: 'column',
    paddingTop: '10px',
  },
  progressDot: {
    display: 'block',
    height: '16px',
    width: '16px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.1)',
    borderRadius: '40%',
  },
  progressLine: {
    display: 'block',
    height: '30px',
    width: '2px',
  },
  progressText: {
    color: '#626466',
    marginTop: '3px',
    marginBottom: '5px',
    cursor: 'pointer',
    LineHeight: '100px',
    fontSize: '14px',
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    fontWeight: '400',
    height: '60px',
  },
});

export const ActiveLineStyle = {
  display: 'block',
  padding: '7px 30px',
  borderRadius: '28px',
  alignItems: 'center',
  color: '#626466',
  fontFamily: ['Roboto', 'sans-serif'].join(','),
  fontWeight: '500',
  textTransform: 'uppercase',
  fontSize: '12px',
  backgroundColor: '#FFFFFF',
  boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.1)',
  border: 'none',
};
export const ActiveDotStyle = {
  ...ActiveLineStyle,
  display: 'none',
  width: '10px',
  height: '10px',
};

export default styles;
