import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import countries from 'i18n-iso-countries';
// import AtomVerification from '../../icons/atomVerification';
import { makeStyles, useTheme } from '@mui/styles';
import LocationIcon from '../../icons/location';
import GenderIcon from '../../icons/gender';
import DateofBirthIcon from '../../icons/birthday';
import { PersonaContext } from '../../v2api/PersonaContext/PersonaContext';
import ProfileImage from '../../components/ProfileImage';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

const useStyles = makeStyles((theme) => ({
  bioSection: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '1rem',
    [theme.breakpoints.only('xs')]: {
      borderRadius: '0rem',
    },
    display: 'flex',
    padding: '2rem',
    marginBottom: '2rem',
  },
  bioText: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '3rem',
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      alignItems: 'center',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  artistName: {
    fontFamily: 'Lexend Deca',
    fontWeight: 'bold',
    fontSize: '36px',
    justifyContent: 'left',
  },
  verifiedIcon: {
    marginLeft: '1rem',
    height: '29px',
    width: '29px',
  },
  musicRole: {
    fontWeight: '500',
    fontSize: '28px',
    textTransform: 'capitalize',
  },
  location: {
    fontSize: '18px',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'capitalize',
  },
  gender: {
    fontSize: '18px',
  },
  dateOfBirth: {
    fontSize: '18px',
  },
  bioIcon: {
    marginRight: '0.5rem',
    width: '16px',
    height: '16px',
  },
}));

const InfoSection = () => {
  const theme = useTheme();
  const classes = useStyles();
  const { persona } = useContext(PersonaContext);
  if (!persona) return null;
  const rolesOrFeatures =
    (persona.isProfessionalProfile ? persona.musicRoles : persona.features) ||
    [];
  return (
    <Box>
      {/* Public Profile Page Personal Summary Section */}
      <Box className={classes.bioSection}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={4} md={3} lg={3}>
            <ProfileImage personaId={persona.personaId} isPublicPage={true} />
          </Grid>
          <Grid
            gap={3}
            item
            xs={12}
            sm={8}
            md={9}
            lg={9}
            className={classes.bioText}
          >
            <Typography className={classes.artistName}>
              {persona.artistName}
              {
                /* FIXME publicProfileData?.verifiedFields?.ipn?.toUpperCase() ===
              'PPL' ? (
                <AtomVerification className={classes.verifiedIcon} />
              ) :*/ null
              }
            </Typography>

            <Typography className={classes.musicRole}>
              {rolesOrFeatures.slice(0, 3).join(' | ')}
            </Typography>

            {persona.city || persona.countryAlpha2 ? (
              <Typography className={classes.location}>
                <LocationIcon
                  color={theme.palette.primary.hint}
                  className={classes.bioIcon}
                />
                {persona.city}
                {persona.city && persona.countryAlpha2 ? ', ' : ''}
                {countries.getName(persona.countryAlpha2, 'EN')}
              </Typography>
            ) : null}

            {persona.gender || persona.pronouns ? (
              <Typography className={classes.gender}>
                <GenderIcon
                  color={theme.palette.primary.hint}
                  className={classes.bioIcon}
                />
                {persona.gender}
                {persona.gender && persona.pronouns ? ' | ' : ''}
                {persona.pronouns}
              </Typography>
            ) : null}

            {persona.dateOfBirth && (
              <Typography className={classes.dateOfBirth}>
                <DateofBirthIcon
                  color={theme.palette.primary.hint}
                  className={classes.bioIcon}
                />
                {persona.dateOfBirth}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default InfoSection;
