import React from 'react';
import { makeStyles } from '@mui/styles';
import styles from './styles';
import { Grid, Typography, Link } from '@mui/material';
import CPVisibleIcon from '../../CPVisibleIcon';
import { text_fields } from '../../../../text_fields';

const useStyles = makeStyles(styles);

const specialLinks = text_fields['knownOfficialLinkTypes'];

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => (images[item.replace('./', '')] = r(item)));
  return images;
}

const logos_links = importAll(
  require.context(
    '../../../../shared/icons/logos',
    false,
    /\.(png|jpe?g|svg)$/,
  ),
);

const absolutifyUrl = (userUrl) => {
  if (!/^https?:\/\//i.test(userUrl)) {
    return 'https://' + userUrl;
  }
  return userUrl;
};

const ReadOnlyOfficialLink = ({ customAttr, isVisible }) => {
  const classes = useStyles();
  const isSpecial = customAttr.fieldName in specialLinks; // FIXME: prepend with OFFICIAL::?

  const imgFile = isSpecial
    ? logos_links[`${customAttr.fieldName}.svg`] ||
      logos_links[`${customAttr.fieldName}.png`]
    : logos_links['website.svg'];

  if (!imgFile) console.warn(`No img file found for ${customAttr.fieldName}`);

  const logoIcon = (
    <img
      src={imgFile}
      className={classes.icon}
      alt={customAttr.fieldName}
      draggable="false"
    />
  );

  return (
    <>
      <Grid container>
        <Grid item className={classes.officialinktile}>
          <Link
            target="_blank"
            href={absolutifyUrl(customAttr.value)}
            rel="noreferrer"
            className={classes.dashIcon}
          >
            {React.cloneElement(logoIcon, {
              style: {
                width: '48px',
              },
            })}
          </Link>
          <Typography
            className={classes.infoLabel}
            sx={{ textAlign: 'center' }}
          >
            {isSpecial
              ? specialLinks[customAttr.fieldName].label
              : customAttr?.fieldName}
          </Typography>
          {/*<Typography>{customAttr?.value}</Typography>*/}
          <CPVisibleIcon isVisible={isVisible} />
        </Grid>
      </Grid>
    </>
  );
};

export default ReadOnlyOfficialLink;
