import { useEffect, useRef, useState } from 'react';
import { loadScript } from '../utils/utils';

const useLoadScript = (name, url, onLoad, cleanup) => {
  const [loaded, setLoaded] = useState(false);
  const callback = useRef();
  callback.current = onLoad; // Ignore callback changes
  useEffect(() => {
    let cleanup;
    loadScript(name, url, () => {
      setLoaded(true);
      if (callback.current) {
        cleanup = callback.current();
      }
    });
    return () => {
      if (cleanup) {
        cleanup();
      }
    };
  }, [name, url]);
  return loaded;
};

export default useLoadScript;
