const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '15px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0',
    },
  },
  topInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#777777',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0.5rem',
      marginRight: '1rem',
    },
  },
  reps: {
    flexDirection: 'column',
    display: 'flex',
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0',
    },
  },
  editDialog: {
    display: 'flex',
    flexDirection: 'column',
  },
  empty: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    padding: '1rem',
    borderRadius: '1rem',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0',
    },
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
      width: '100%',
    },
  },
});

export default styles;
