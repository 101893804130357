import React from 'react';
import withStyles from '@mui/styles/withStyles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import { Box } from '@mui/material';
import styles from './styles';
import useCPHelpMessage from './hooks/useCPHelpMessage';
import ToolTip from '../../../icons/atomsTooltip.js';

const propTypes = {};
const defaultProps = {};

const CPHelpMessage = ({ classes, title, message, ...other }) => {
  const { getAnchorEl, handleClick, handleClose } = useCPHelpMessage();

  const open = Boolean(getAnchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Box
        onClick={handleClick}
        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
      >
        <IconButton
          disableRipple
          sx={{ height: '16px', width: '16px', marginRight: '0.4rem' }}
          variant="outlined"
          aria-label="Help"
          size="medium"
        >
          <ToolTip sx={{ height: '16px', width: '16px', color: '#5079A1' }} />
        </IconButton>
        <Typography className={classes.title}>{title}</Typography>
      </Box>
      <Popover
        sx={{ mt: '2px' }}
        id={id}
        open={open}
        anchorEl={getAnchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            borderRadius: 0,
          },
        }}
      >
        <Box
          sx={{
            transform: 'translate(50%, 50%)',
            position: 'relative',
            mt: '10px',
            '&::before': {
              backgroundColor: '#E3EEFA',
              content: '""',
              display: 'block',
              position: 'absolute',
              width: 12,
              height: 12,
              top: -6,
              transform: 'rotate(45deg)',
            },
          }}
        />
        <Box
          sx={{
            backgroundColor: '#E3EEFA',
            pt: '10px',
            borderRadius: '10px',
            display: 'flex',
            // alignItems: 'center',
          }}
        >
          <Typography
            className={classes.dialog_text}
            style={{
              color: '#2E455C',
              fontSize: '14px',
              textAlign: 'left',
            }}
            gutterBottom
          >
            {message}
          </Typography>
          <Box sx={{ mr: 1 }}>
            <IconButton variant="outlined" onClick={handleClose} size="small">
              <CloseIcon
                sx={{ height: '20px', width: '20px', color: '#5079A1' }}
              />
            </IconButton>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

CPHelpMessage.displayName = 'CPHelpMessage';
CPHelpMessage.propTypes = propTypes;
CPHelpMessage.defaultProps = defaultProps;

export default withStyles(styles)(CPHelpMessage);
