import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 24 24">
      <title>Actions</title>
      <defs>
        <linearGradient
          x1="78.125%"
          y1="100%"
          x2="21.9264264%"
          y2="0.0914246886%"
          id="linearGradient-1"
        >
          <stop stop-color="#FF2667" offset="0.0914246886%"></stop>
          <stop stop-color="#73AEE6" offset="50.9814282%"></stop>
          <stop stop-color="#00FFCC" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g
        id="Symbols"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Atoms/icons/actions/Selected"
          fill={props.fill ? 'url(#linearGradient-1)' : '#626466'}
        >
          <path
            d="M16.082,3.73034936e-14 L3,14 L9.574,14 L8.032,23.891 L21.114,8.891 L14.54,8.891 L16.082,3.73034936e-14 Z M13.972,3.723 L14.524,3.132 L13.351,9.891 L18.914,9.891 L18.042,10.891 L12.728,10.891 L13.972,3.723 Z"
            id="Fill-1"
          ></path>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
