import React from 'react';
import { useContext } from 'react';
import { Box } from '@mui/system';
import CMSBio from '../Bio';
import Representatives from '../Representatives';
import Mediums from '../AllMedium';
import MediumsArtistic from '../AllMediumArtistic';
import PersonalInfo from '../PersonalInfo';
import OfficialLinks from '../OfficialLinks';
import IndustryIds from '../IndustryIDs';
import Projects from '../Projects';
import PersonaSummary from '../PersonaSummary';

import { PersonaContext } from '../../../v2api/PersonaContext/PersonaContext';

// import Milestones from '../Milestones';

const PersonaPrivateView = ({ onDelete }) => {
  const { persona, isNew } = useContext(PersonaContext);
  if (!persona) return null;
  if (persona.personaId === null) {
    return <div>Loading...</div>;
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'column',
        gap: '2rem',
        mb: '3rem',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <PersonaSummary onDelete={onDelete} isNew={isNew} />
      {persona?.isProfessionalProfile ? <Mediums /> : <MediumsArtistic />}
      <PersonalInfo />
      <CMSBio />
      <Projects />
      <IndustryIds />
      <Representatives />
      <OfficialLinks />
    </Box>
  );
};

export default PersonaPrivateView;
