export const repTypes = {
  label: {
    key: 0,
    label: 'Label',
  },
  publisher: {
    key: 1,
    label: 'Publisher',
  },
  collection_society: {
    key: 2,
    label: 'Collection Society',
  },
  distributor: { key: 3, label: 'Distributor' },
  pr_agent: {
    key: 11,
    label: 'PR Agency',
  },
  agent: {
    key: 4,
    label: 'Agent',
  },
  legal: {
    key: 5,
    label: 'Legal',
  },
  sync_agency: {
    key: 6,
    label: 'Sync Agency',
  },
  management: {
    key: 7,
    label: 'Management',
  },
  general: { key: 8, label: 'General Contact' },
};
