import React, { useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, Box, IconButton } from '@mui/material';
import CheckTik from '../../../icons/atomIconsTick';
import { PersonaContext } from '../../../v2api/PersonaContext/PersonaContext';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderRadius: '16px',
    backgroundColor: theme.palette.background.paper,
    padding: '1rem',
    marginBottom: '0.7rem',
  },
  progresscontainer: {
    position: 'relative',
    backgroundColor: theme.palette.background.dark,
    width: '100%',
    borderRadius: '100rem',
    marginTop: '8px',
    marginBottom: '5px',
  },
  progress: {
    backgroundImage: 'linear-gradient(to right, #FF2667, #73AEE6, #00FFCC)',
    padding: '0.3rem',
    borderRadius: '100rem',
    backgroundSize: '100%',
    transition: '0.3s',
    [theme.breakpoints.only('xl')]: {
      backgroundSize: '363px',
    },
    [theme.breakpoints.down('xl')]: {
      backgroundSize: 'calc(33vw - 8rem)',
    },
    [theme.breakpoints.down('md')]: {
      backgroundSize: 'calc(100vw - 64px)',
    },
  },
  iconBtn: {
    backgroundColor: theme.palette.background.dark,
    marginRight: '0.5rem',
    width: '32px',
    height: '32px',
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '0.7rem',
  },
  text: {
    textAlign: 'center',
  },
}));

const ProfileCompletion = () => {
  const classes = useStyles();
  const { persona } = useContext(PersonaContext);
  // const { thumbnail } = useFetchImage('profile', persona?.personaId);
  // FIXME
  const thumbnail = null;
  const isFetching = false;

  let percentage = 0.0;

  if (persona) {
    if (persona.artistName) percentage += 12.5;
    if (thumbnail) percentage += 12.5;
    if (persona.officialLinks?.length) percentage += 12.5;
    if (persona.otherSkills?.length) percentage += 12.5;
    if (persona.musicRoles?.length || persona.features?.length) {
      percentage += 12.5;
    }
    if (
      persona?.biographyLong ||
      persona?.biographyShort ||
      persona?.biographyHeadline
    ) {
      percentage += 12.5;
    }
    if (
      persona?.unverifiedIpi ||
      persona?.unverifiedIpi ||
      persona?.unverifiedIpn
    ) {
      percentage += 12.5;
    }
    if (persona.isProfessionalProfile) {
      if (
        persona.instrumentsPlayed?.length ||
        persona.instrumentsOwned?.length ||
        persona.toolsUsed?.length ||
        persona.toolsOwned?.length
      ) {
        percentage += 12.5;
      }
    } else {
      if (persona.genres?.length) {
        percentage += 12.5;
      }
    }
  }

  return (
    <Box className={classes.root}>
      <div className={classes.progresscontainer}>
        <div
          className={classes.progress}
          style={{ width: `${percentage}%` }}
        ></div>
      </div>
      <Box className={classes.textContainer}>
        <IconButton disableRipple size="small" className={classes.iconBtn}>
          <CheckTik sx={{ height: '16px', width: '16px' }} />
        </IconButton>
        <Typography>Your Profile is {percentage}% complete</Typography>
      </Box>
    </Box>
  );
};

export default ProfileCompletion;
