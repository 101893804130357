import { useState } from 'react';

const useDisclosure = (isOpenDefault) => {
  const [isOpen, setIsOpen] = useState(isOpenDefault);
  return {
    isOpen,
    onOpen: () => setIsOpen(true),
    onClose: () => setIsOpen(false),
    toggle: () => setIsOpen((state) => !state),
  };
};

export default useDisclosure;
