import { Typography, Box, Button } from '@mui/material';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import Plus from '../../../icons/atomsIconsAdd';
import QuestionMark from '../../../icons/cpQuestion';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: '2px 2px 6px 0px rgba(0,0,0,0.1)',
  },
  icon: {
    fontSize: '18px',
    marginRight: '0.5rem',
  },
  small: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
    justifyContent: 'space-between',
    borderRadius: '0.75rem',
    height: '2.5rem',
    backgroundColor: '#F7F8F9',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '0 auto',
      paddingRight: '1.5rem',
    },
  },
  standard: {
    backgroundColor: '#F7F8F9',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    height: '3rem',
    borderRadius: '1.5rem',
    color: '#626466',
    justifyContent: 'center',
    boxShadow: '0.67px 0.67px 4px 0 rgba(0,0,0,0.1)',
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
      paddingRight: '2rem',
    },
  },
  textSmall: {
    fontSize: 10,
    fontWeight: 500,
    color: '#626466',
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
  },
  textStandard: {
    fontSize: 12,
    fontWeight: 500,
    color: '#626466',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
}));

const CPAddButton = ({
  children,
  hasGradient,
  help,
  size,
  className,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Button
      {...rest}
      className={clsx(
        size === 'small' ? classes.small : classes.standard,
        className ? className : null,
      )}
      variant="contained"
    >
      <Plus className={classes.icon} hasGradient={hasGradient} />
      <Box
        sx={[
          {
            display: 'flex',
          },
          size === 'small'
            ? {
                alignItems: 'start',
              }
            : {
                alignItems: 'center',
              },
        ]}
      >
        <Typography
          className={
            size === 'small' ? classes.textSmall : classes.textStandard
          }
        >
          {children}
        </Typography>
        {help ? <QuestionMark fontSize="13px" /> : null}
      </Box>
      <div></div>
    </Button>
  );
};

export default CPAddButton;
