import { Box, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import CPEditButton from './CPEditButton';
import CPVisibleToggle from './CPVisibleToggle';

const styles = (theme) => ({
  topInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '2rem',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '1rem',
      marginRight: '1rem',
    },
  },
});

const useStyles = makeStyles(styles);

const CMSTitleInfo = ({ title, isNotEmpty, message, onClick, isVisible, setVisible, isVisibilityDisabled }) => {
  const classes = useStyles();
  return (
    <Box className={classes.topInfo}>
      <Stack direction="row" spacing={2}>
        <Typography variant="h3">
          {title}
        </Typography>
        {setVisible && <CPVisibleToggle isVisible={isVisible} setVisible={setVisible} isDisabled={isVisibilityDisabled} />}
      </Stack>
      {isNotEmpty && <CPEditButton title={message} onClick={onClick} />}
    </Box>
  );
};

export default CMSTitleInfo;
