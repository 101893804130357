import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <title>IDs</title>
      <g
        id="Atoms/icons/IDs"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <path
          d="M22.001,2.0002 L24,4.0002 L24,19.9992 L22,22.0002 L2,22.0002 L1.0125234e-13,19.9992 L1.0125234e-13,4.0002 L1.999,2.0002 L22.001,2.0002 Z M22,4.0002 L3,4.0002 L3,18.9992 L22,18.9992 L22,4.0002 Z M8.4918,4.9771 C8.9728,4.9771 9.4528,5.1011 9.8838,5.3501 C10.7448,5.8471 11.2758,6.7661 11.2758,7.7611 L11.2758,9.9671 L9.0458,11.9761 L11.9258,11.9761 L12.9998,15.0471 L11.0838,16.9761 L5.9168,16.9761 L3.9998,15.0471 L5.0748,11.9761 L7.9368,11.9761 L5.7068,9.9671 L5.7068,7.7611 C5.7068,6.7661 6.2378,5.8471 7.0988,5.3501 C7.5298,5.1011 8.0108,4.9771 8.4918,4.9771 Z M10.5068,13.9761 L6.4938,13.9761 L6.3018,14.5261 L6.7488,14.9761 L10.2508,14.9761 L10.6988,14.5261 L10.5068,13.9761 Z M20.0001,10.211 L20.0001,12.211 L14.0001,12.211 L14.0001,10.211 L20.0001,10.211 Z M8.4918,6.9771 C8.3538,6.9771 8.2188,7.0131 8.0988,7.0811 C7.8568,7.2211 7.7068,7.4821 7.7068,7.7611 L7.7068,9.0771 L8.4918,9.7851 L9.2758,9.0771 L9.2758,7.7611 C9.2758,7.4821 9.1258,7.2211 8.8838,7.0811 C8.7648,7.0131 8.6288,6.9771 8.4918,6.9771 Z M21.0001,6.283 L21.0001,8.283 L14.0001,8.283 L14.0001,6.283 L21.0001,6.283 Z"
          id="Combined-Shape"
          fill="#626466"
        ></path>
      </g>
    </SvgIcon>
  );
}

export default Icon;
