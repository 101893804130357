import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, Box, IconButton, Divider } from '@mui/material';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { TbAddressBook, TbBug } from 'react-icons/tb';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '0.9rem',
    marginTop: '1.5rem',
  },
  iconUp: {
    cursor: 'default',
    backgroundColor: '#ACCFF0',
    alignSelf: 'flex-start',
    '&:hover': {
      backgroundColor: '#ACCFF0',
    },
  },
  iconDown: {
    backgroundColor: '#FFF',
    alignSelf: 'flex-end',
    boxShadow: '2px 2px 6px 0px rgba(0,0,0,0.1)',
  },
  iconDownLink: {
    color: 'currentColor',
    alignSelf: 'flex-end',
  },
  card: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '0.9rem',
    backgroundColor: theme.palette.background.dark,

    borderRadius: '0.75rem',
  },
  textContent: {
    flexGrow: 1,
    padding: '10px',
  },
  textTitle: {
    textTransform: 'none',
    fontSize: '14px',
    lineHeight: '18px',
  },
  textDesc: {
    textTransform: 'none',
    fontSize: '12px',
    lineHeight: '1.5',
  },
}));

const GuidanceAndSupport = (props) => {
  const classes = useStyles();
  return (
    <>
      <Divider />
      <Box className={classes.root}>
        <Typography variant="h4">Guidance & Support</Typography>
        <Card2 />
      </Box>
    </>
  );
};

export default GuidanceAndSupport;

const Card1 = () => {
  const classes = useStyles();
  return (
    <Box className={classes.card}>
      <IconButton disableRipple size="small" className={classes.iconUp}>
        <TbAddressBook color="#4F7AA2" />
      </IconButton>
      <Box className={classes.textContent}>
        <Typography variant="h5">Read the FAQ</Typography>
        <Typography className={classes.textDesc} variant="subtitle1">
          Read frequently asked questions, and learn more about The Creative
          Passport platform.
        </Typography>
      </Box>
      <a
        className={classes.iconDownLink}
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.creativepassport.net/faq/"
      >
        <IconButton size="small" className={classes.iconDown}>
          <AiOutlineArrowRight />
        </IconButton>
      </a>
    </Box>
  );
};

const Card2 = () => {
  const classes = useStyles();
  return (
    <Box className={classes.card}>
      <IconButton disableRipple size="small" className={classes.iconUp}>
        <TbBug color="#4F7AA2" />
      </IconButton>
      <Box className={classes.textContent}>
        <Typography variant="h5">Report a Problem</Typography>
        <Typography className={classes.textDesc} variant="subtitle1">
          Let us know about any issues during your time here. We always welcome
          your feedback.
        </Typography>
      </Box>
      <a
        className={classes.iconDownLink}
        href="mailto:support@creativepassport.net"
      >
        <IconButton size="small" className={classes.iconDown}>
          <AiOutlineArrowRight />
        </IconButton>
      </a>
    </Box>
  );
};
