import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from '@mui/material/CssBaseline';
import SignedInHeader from '../SignedInHeader';
import Footer from '../Footer';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
    //Dashboard background colour
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    [theme.breakpoints.up('md')]: {
      paddingTop: '1rem',
    },
    paddingBottom: '2rem',
    width: '100vw',
  },
  loadingContainer: {
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80vh',
  },
}));

// Adds a header, and intercepts new users to the new user flow
const AuthedPagesWrapper = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <SignedInHeader />
      <div className={classes.container}>
        <main className={classes.content}>
          <div>{props.children}</div>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default AuthedPagesWrapper;
