import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Slide,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import styles from './styles';
import Checkmark from '../../../../icons/tick';
import Button from '../../../../components/Button';
import CPDeleteButton from '../../CPDeleteButton';
import ProfileImage from '../../../../components/ProfileImage';
import CPAddButton from '../../CPAddButton';
import CPRadio from '../../../../components/CPRadio';
import CPAutocomplete from '../../CPAutocomplete';
import countries from 'i18n-iso-countries';
import CPVisibleToggle from '../../CPVisibleToggle';
import { PersonaContext } from '../../../../v2api/PersonaContext/PersonaContext';
import { PassportContext } from '../../../../v2api/PassportContext/PassportContext';
import SoloProject from '../../../../icons/atomSoloProject';
import Drum from '../../../../icons/atomDrum';
import OrchestraIcon from '../../../../icons/atomOrchestra';
import GroupIcon from '../../../../icons/atomGroup';
import Puzzle from '../../../../icons/atomPuzzle';
import { PersonaAccessContext } from '../../../../v2api/PersonaAccessContext/PersonaAccessContext';
import { useAuth } from 'react-oidc-context';

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      easing={{ enter: 'cubic-bezier(0, 1.3, .4, 1)', exit: 'linear' }}
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

const SummaryEdit = ({ isOpen, closeModal }) => {
  const auth = useAuth();
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { persona, updatePersona } = useContext(PersonaContext);
  const { passport, updatePassport } = useContext(PassportContext);
  const { personaAccess, updateAccess } = useContext(PersonaAccessContext);

  const projectTypes = [
    { name: 'Solo project', slug: 'solo', icon: SoloProject },
    { name: 'Band', slug: 'band', icon: Drum },
    { name: 'Orchestra', slug: 'orchestra', icon: OrchestraIcon },
    { name: 'Collective', slug: 'collective', icon: GroupIcon },
    { name: 'Other', slug: 'other', icon: Puzzle },
  ];

  // The patch represents the changes we have made, (excluding no-ops) so we can press save and patch the React state
  // and db with the same patch. `key: null` indicates a deletion. If the value is set to "", this should indicate
  // that that key needs to be removed from the patch on submit - this is not a proper delete.
  // On submit, delete any keys where value is ""; delete key if value == oldValue, or if value=null and oldValue undefined
  const [personaPatch, setPersonaPatch] = useState({});
  const [passportPatch, setPassportPatch] = useState({});
  const [state, setState] = useState({});

  const [personaVisibilityPatch, setPersonaVisibilityPatch] = useState({});

  useEffect(() => {
    if (!persona) return;
    const personaKeys = [
      'aliasOverwritesName',
      'city',
      'musicRoles',
      'pronouns',
      'alias',
      'personaType',
      'features',
    ];
    const patchedState = Object.fromEntries(
      personaKeys.map((key) => [
        key,
        key in personaPatch ? personaPatch[key] : persona[key] || null,
      ]),
    );
    patchedState.aliasOverwritesName =
      patchedState.aliasOverwritesName || false;
    if (persona.isProfessionalProfile) {
      patchedState.unverifiedFirstname =
        'unverifiedFirstname' in passportPatch
          ? passportPatch.unverifiedFirstname
          : passport.unverifiedFirstname;
      patchedState.unverifiedLastname =
        'unverifiedLastname' in passportPatch
          ? passportPatch.unverifiedLastname
          : passport.unverifiedLastname;
      patchedState.countryAlpha2 =
        'unverifiedCountry' in passportPatch
          ? passportPatch.unverifiedCountry
          : passport.unverifiedCountry || null;
      patchedState.dateOfBirth =
        'unverifiedDob' in passportPatch
          ? passportPatch.unverifiedDob
          : passport.unverifiedDob || null;
      patchedState.gender =
        'unverifiedGender' in passportPatch
          ? passportPatch.unverifiedGender
          : passport.unverifiedGender || null;
    } else {
      patchedState.artistName =
        'artistName' in personaPatch
          ? personaPatch.artistName
          : persona.artistName;
      patchedState.countryAlpha2 =
        'countryAlpha2' in personaPatch
          ? personaPatch.countryAlpha2
          : persona.countryAlpha2 || null;
      patchedState.dateOfBirth =
        'dateOfBirth' in personaPatch
          ? personaPatch.dateOfBirth
          : persona.dateOfBirth || null;
      patchedState.gender =
        'gender' in personaPatch ? personaPatch.gender : persona.gender || null;
    }

    setState(patchedState);
  }, [personaPatch, passportPatch, passport, persona]);

  const patchedVisibility = {
    ...personaAccess,
    ...personaVisibilityPatch,
  };

  const handleSave = () => {
    const personaPatchCopy = { ...personaPatch };
    Object.keys(personaPatch).forEach((key) => {
      if (personaPatch[key] === '') {
        delete personaPatchCopy[key]; // undeleted blank entries are cancelled
      } else if (personaPatch[key] === null && !persona[key]) {
        delete personaPatchCopy[key]; // remove no-op deletes
      } else if (personaPatch[key] === persona[key]) {
        delete personaPatchCopy[key]; // remove no-op updates
      }
    });
    if (persona.isProfessionalProfile) {
      let computedDisplayName = `${passport.unverifiedFirstname || ''} ${passport.unverifiedLastname || ''}`;
      if (state.alias) {
        if (state.aliasOverwritesName) {
          computedDisplayName = state.alias;
        } else {
          computedDisplayName = computedDisplayName + ` (${state.alias})`;
        }
      }
      if (computedDisplayName !== persona.artistName) {
        personaPatchCopy['artistName'] = computedDisplayName;
      }
    }
    if (Object.keys(personaPatchCopy).length >= 1) {
      updatePersona({ method: 'PATCH', payload: personaPatchCopy });
    }
    const personaVisibilityPatchCopy = { ...personaVisibilityPatch };
    Object.keys(personaVisibilityPatch).forEach((key) => {
      if (personaVisibilityPatch[key] === personaAccess[key]) {
        delete personaVisibilityPatchCopy[key];
      }
    });
    if (Object.keys(personaVisibilityPatchCopy).length >= 1) {
      updateAccess({
        method: 'PATCH',
        payload: personaVisibilityPatchCopy,
      });
    }
    if (persona.isProfessionalProfile) {
      const passportPatchCopy = { ...passportPatch };
      Object.keys(passportPatch).forEach((key) => {
        if (passportPatch[key] === '') {
          delete passportPatchCopy[key]; // undeleted blank entries are cancelled
        } else if (passportPatch[key] === null && !passport[key]) {
          delete passportPatchCopy[key]; // remove no-op deletes
        } else if (passportPatch[key] === passport[key]) {
          delete passportPatchCopy[key]; // remove no-op updates
        }
      });
      if (Object.keys(passportPatchCopy).length >= 1) {
        updatePassport({ method: 'PATCH', payload: passportPatchCopy });
      }
    }
    setPersonaPatch({});
    setPassportPatch({});
    setPersonaVisibilityPatch({});
    closeModal();
  };

  const handleCancel = () => {
    setPersonaPatch({});
    setPassportPatch({});
    setPersonaVisibilityPatch({});
    closeModal();
  };

  const handleDelete = () => {
    closeModal();
    updatePassport({ method: 'DELETE_PERSONA', payload: persona.personaId });
  };

  return (
    <Dialog
      fullScreen={isSmallScreen}
      open={isOpen}
      onClose={closeModal}
      TransitionComponent={Transition}
    >
      <DialogContent>
        <form>
          <Grid container className={classes.editForm}>
            {/* Editor: Avatar */}
            <Grid item xs={12} sm={4} className={classes.image}>
              <ProfileImage
                personaId={persona?.personaId}
                alt={state.artistName}
              />
            </Grid>
            <Grid item xs={12} sm={8} className={classes.modalForm}>
              {persona?.isProfessionalProfile ? (
                <>
                  {/*** <PROFESSIONAL> ***/}
                  {/* Professional Profile Editor: Person Name */}
                  <Box
                    className={classes.fields}
                    sx={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                    }}
                  >
                    {/* Professional Profile Editor: First Name */}
                    <div style={{ width: '48%' }}>
                      <p className={classes.myLabel}>First name</p>
                      <TextField
                        value={state.unverifiedFirstname}
                        onChange={(e) =>
                          setPassportPatch((p) => ({
                            ...p,
                            unverifiedFirstname: e.target.value,
                          }))
                        }
                        className={classes.nameField}
                        variant="standard"
                        sx={{ width: '100% !important' }}
                        placeholder="First Name"
                        data-testid={`first-name-${persona?.personaId}-edit`}
                        required
                      />
                    </div>
                    {/* Professional Profile Editor: Last Name */}
                    <div style={{ width: '48%' }}>
                      <p className={classes.myLabel}>Surname</p>
                      <TextField
                        value={state.unverifiedLastname}
                        onChange={(e) =>
                          setPassportPatch((p) => ({
                            ...p,
                            unverifiedLastname: e.target.value,
                          }))
                        }
                        className={classes.nameField}
                        variant="standard"
                        sx={{ width: '100% !important' }}
                        placeholder="Surname"
                        data-testid={`last-name-${persona?.personaId}-edit`}
                        required
                      />
                    </div>
                  </Box>
                  {/* nickname */}
                  {state.alias === null ? (
                    <CPAddButton
                      onClick={() => {
                        setPersonaPatch((p) => ({ ...p, alias: '' }));
                      }}
                    >
                      Add an alias/stage name/nickname
                    </CPAddButton>
                  ) : (
                    <div>
                      <Typography
                        className={classes.myLabel}
                        data-testid={`first-last-name-${persona?.personaId}-edit`}
                      >
                        Alias / Stage Name / Nickname
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          gap: 2,
                        }}
                      >
                        <TextField
                          className={classes.inputField}
                          placeholder="Alias/Nickname/Moniker"
                          variant="standard"
                          value={state.alias}
                          onChange={(e) =>
                            setPersonaPatch((p) => ({
                              ...p,
                              alias: e.target.value,
                            }))
                          }
                        />
                        <CPDeleteButton
                          onClick={() => {
                            setPersonaPatch((p) => ({ ...p, alias: null }));
                          }}
                        />
                      </Box>

                      <FormControl sx={{ width: '100%' }}>
                        <RadioGroup
                          value={state.aliasOverwritesName}
                          onChange={(e) =>
                            setPersonaPatch((p) => ({
                              ...p,
                              aliasOverwritesName: e.target.value === 'true',
                            }))
                          }
                          defaultValue={false}
                          aria-label="display-alias-option"
                          name="display-alias-option"
                          className={classes.displayAlias}
                          data-testid={`alias-show-${persona?.personaId}-group`}
                        >
                          <Grid
                            container
                            className={classes.fields}
                            spacing={1}
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Grid item xs={6}>
                              <FormControlLabel
                                className={classes.eachAlias}
                                value={false}
                                control={
                                  <CPRadio
                                    inputProps={{
                                      'data-testid': `alias-show-${persona?.personaId}-display-both`,
                                    }}
                                  />
                                }
                                label="Display both"
                                style={{
                                  width: '100%',
                                  backgroundColor: state.aliasOverwritesName
                                    ? 'transparent'
                                    : '#F7F8F9',
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <FormControlLabel
                                className={classes.eachAlias}
                                value={true}
                                control={
                                  <CPRadio
                                    inputProps={{
                                      'data-testid': `alias-show-${persona?.personaId}-display-alias`,
                                    }}
                                  />
                                }
                                label="Display Alias only"
                                style={{
                                  width: '100%',
                                  backgroundColor: state.aliasOverwritesName
                                    ? 'transparent'
                                    : '#F7F8F9',
                                }}
                              />
                            </Grid>
                          </Grid>
                        </RadioGroup>
                      </FormControl>
                    </div>
                  )}
                  {/*** </PROFESSIONAL> ***/}
                </>
              ) : (
                <>
                  {/*** <ARTISTIC> ***/}
                  {/* Artist Profile Editor: Name */}
                  <div>
                    <Typography className={classes.myLabel}>Name</Typography>
                    <TextField
                      value={state.artistName}
                      onChange={(e) =>
                        setPersonaPatch((p) => ({
                          ...p,
                          artistName: e.target.value,
                        }))
                      }
                      variant="standard"
                      placeholder="Name"
                      className={classes.inputField}
                      fullWidth
                      slotProps={{
                        inputLabel: { shrink: true },
                      }}
                    />
                  </div>
                  {/* Artist Profile Editor: Type */}
                  <div style={{ marginBottom: '23px' }}>
                    <Typography className={classes.myLabel}>Type</Typography>
                    <FormControl>
                      <RadioGroup
                        value={state.personaType}
                        onChange={(e) =>
                          setPersonaPatch((p) => ({
                            ...p,
                            personaType: e.target.value,
                          }))
                        }
                        defaultValue="band"
                        aria-label="artistic-project-type"
                        name="artistic-project-type"
                        className={classes.types}
                      >
                        {projectTypes.map((project) => {
                          const BaseIcon = project.icon;
                          return (
                            <FormControlLabel
                              className={
                                state.personaType === project.slug
                                  ? classes.radioSelected
                                  : classes.radio
                              }
                              value={project.slug}
                              control={
                                <Radio
                                  icon={<BaseIcon />}
                                  checkedIcon={<BaseIcon hasGradient={true} />}
                                  disableRipple
                                  sx={{
                                    '&.MuiButtonBase-root:hover': {
                                      bgcolor: 'transparent',
                                    },
                                  }}
                                  className={
                                    state.personaType === project.slug
                                      ? classes.radioIconSelected
                                      : classes.radioIcon
                                  }
                                />
                              }
                              label={project.name}
                            />
                          );
                        })}
                      </RadioGroup>
                    </FormControl>
                  </div>
                  {/*** </ARTISTIC> ***/}
                </>
              )}

              {/*** <SHARED> ***/}
              {/* Country */}
              <div>
                {state?.countryAlpha2 === null ? (
                  <CPAddButton
                    data-testid={`edit-${persona?.personaId}-country-button`}
                    onClick={() => {
                      if (persona?.isProfessionalProfile) {
                        setPassportPatch((p) => ({
                          ...p,
                          unverifiedCountry: '',
                        }));
                      } else {
                        setPersonaPatch((p) => ({ ...p, countryAlpha2: '' }));
                      }
                    }}
                  >
                    Add Country
                  </CPAddButton>
                ) : (
                  <>
                    <p className={classes.myLabel}>Country</p>
                    <div
                      className={classes.eyes}
                      data-testid={`edit-${persona?.personaId}-country`}
                    >
                      <CPAutocomplete
                        data-testid="autocomplete-select"
                        options={Object.keys(countries.getNames('EN'))}
                        value={state.countryAlpha2}
                        getOptionLabel={(x) => countries.getName(x, 'EN') ?? ''}
                        onChange={(e, value) => {
                          if (persona?.isProfessionalProfile) {
                            setPassportPatch((p) => ({
                              ...p,
                              unverifiedCountry: value,
                            }));
                          } else {
                            setPersonaPatch((p) => ({
                              ...p,
                              countryAlpha2: value,
                            }));
                          }
                        }}
                      ></CPAutocomplete>
                      <CPVisibleToggle
                        isVisible={patchedVisibility.countryAlpha2}
                        setVisible={(v) =>
                          setPersonaVisibilityPatch((p) => ({
                            ...p,
                            countryAlpha2: v,
                          }))
                        }
                      />
                      <CPDeleteButton
                        onClick={() => {
                          if (persona?.isProfessionalProfile) {
                            setPassportPatch((p) => ({
                              ...p,
                              unverifiedCountry: null,
                            }));
                          } else {
                            setPersonaPatch((p) => ({
                              ...p,
                              countryAlpha2: null,
                            }));
                          }
                        }}
                      />
                    </div>
                  </>
                )}
              </div>

              {/* City Name */}
              <div>
                {state.city === null ? (
                  <CPAddButton
                    onClick={() => setPersonaPatch((p) => ({ ...p, city: '' }))}
                    data-testid={`add-${persona?.personaId}-city-button`}
                  >
                    Add City
                  </CPAddButton>
                ) : (
                  <>
                    <p className={classes.myLabel}>City</p>
                    <div className={classes.eyes}>
                      <TextField
                        className={classes.inputField}
                        placeholder="City"
                        variant="standard"
                        value={state.city}
                        onChange={(e) =>
                          setPersonaPatch((p) => ({
                            ...p,
                            city: e.target.value,
                          }))
                        }
                      />
                      <CPVisibleToggle
                        isVisible={patchedVisibility.city}
                        setVisible={(v) =>
                          setPersonaVisibilityPatch((p) => ({ ...p, city: v }))
                        }
                      />
                      <CPDeleteButton
                        onClick={() =>
                          setPersonaPatch((p) => ({ ...p, city: null }))
                        }
                      />
                    </div>
                  </>
                )}
              </div>

              {/* dateOfBirth: date of birth or project founding date*/}
              {state.dateOfBirth === null ? (
                <CPAddButton
                  onClick={() => {
                    if (persona?.isProfessionalProfile) {
                      setPassportPatch((p) => ({ ...p, unverifiedDob: '' }));
                    } else {
                      setPersonaPatch((p) => ({ ...p, dateOfBirth: '' }));
                    }
                  }}
                >
                  Add{' '}
                  {persona?.isProfessionalProfile
                    ? 'date of birth'
                    : 'founding date'}
                </CPAddButton>
              ) : (
                <div>
                  <p className={classes.myLabel}>
                    {persona?.isProfessionalProfile
                      ? 'Date of birth'
                      : 'Founding date'}
                  </p>
                  <div className={classes.eyes}>
                    <TextField
                      value={state.dateOfBirth}
                      onChange={(e) => {
                        if (persona?.isProfessionalProfile) {
                          setPassportPatch((p) => ({
                            ...p,
                            unverifiedDob: e.target.value,
                          }));
                        } else {
                          setPersonaPatch((p) => ({
                            ...p,
                            dateOfBirth: e.target.value,
                          }));
                        }
                      }}
                      className={classes.inputField}
                      variant="standard"
                      type="date"
                      placeholder={
                        persona?.isProfessionalProfile
                          ? 'Date of birth'
                          : 'Founding date'
                      }
                    />
                    <CPVisibleToggle
                      isVisible={patchedVisibility.dateOfBirth}
                      setVisible={(v) =>
                        setPersonaVisibilityPatch((p) => ({
                          ...p,
                          dateOfBirth: v,
                        }))
                      }
                    />
                    <CPDeleteButton
                      onClick={() => {
                        if (persona?.isProfessionalProfile) {
                          setPassportPatch((p) => ({
                            ...p,
                            unverifiedDob: null,
                          }));
                        } else {
                          setPersonaPatch((p) => ({ ...p, dateOfBirth: null }));
                        }
                      }}
                    />
                  </div>
                </div>
              )}

              {/*** </SHARED> ***/}
              {persona?.isProfessionalProfile ? (
                <>
                  {/*** <Professional 2> ***/}

                  {/* Professional Profile Editor: Gender */}
                  <div>
                    {state.gender === null ? (
                      <CPAddButton
                        onClick={() =>
                          setPassportPatch((p) => ({
                            ...p,
                            unverifiedGender: '',
                          }))
                        }
                        data-testid={`edit-${persona?.personaId}-gender-button`}
                      >
                        Add Gender
                      </CPAddButton>
                    ) : (
                      <div>
                        <p className={classes.myLabel}>Gender</p>
                        <div
                          className={classes.eyes}
                          data-testid={`edit-${persona?.personaId}-gender`}
                        >
                          <CPAutocomplete
                            data-testid={`autocomplete-${persona?.personaId}-gender`}
                            options={[
                              'Male',
                              'Female',
                              'Non-Binary',
                              'Genderfluid',
                              'Agender',
                            ]}
                            value={state.gender}
                            onChange={(e, value) => {
                              if (persona?.isProfessionalProfile) {
                                setPassportPatch((p) => ({
                                  ...p,
                                  unverifiedGender: value,
                                }));
                              } else {
                                setPersonaPatch((p) => ({
                                  ...p,
                                  gender: value,
                                }));
                              }
                            }}
                            freeSolo
                          ></CPAutocomplete>
                          <CPVisibleToggle
                            isVisible={patchedVisibility.gender}
                            setVisible={(v) =>
                              setPersonaVisibilityPatch((p) => ({
                                ...p,
                                gender: v,
                              }))
                            }
                          />
                          <CPDeleteButton
                            onClick={() =>
                              setPassportPatch((p) => ({
                                ...p,
                                unverifiedGender: null,
                              }))
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Professional Profile Editor: Pronouns */}
                  <div>
                    {state.pronouns === null ? (
                      <CPAddButton
                        onClick={() =>
                          setPersonaPatch((p) => ({ ...p, pronouns: '' }))
                        }
                      >
                        Add Pronouns
                      </CPAddButton>
                    ) : (
                      <div>
                        <p className={classes.myLabel}>Pronouns</p>
                        <div className={classes.eyes}>
                          <Select
                            className={classes.pronouns}
                            variant="standard"
                            placeholder="Pronouns"
                            value={state.pronouns}
                            onChange={(e) =>
                              setPersonaPatch((p) => ({
                                ...p,
                                pronouns: e.target.value,
                              }))
                            }
                          >
                            <MenuItem value="she/her">she/her</MenuItem>
                            <MenuItem value="he/him">he/him</MenuItem>
                            <MenuItem value="they/them">they/them</MenuItem>
                            <MenuItem value="it/its">it/its</MenuItem>
                            <MenuItem value="co/cos">co/cos</MenuItem>
                            <MenuItem value="hy/hym">hy/hym</MenuItem>
                            <MenuItem value="xe/xem">xe/xem</MenuItem>
                            <MenuItem value="ze/hir">ze/hir</MenuItem>
                          </Select>
                          <CPVisibleToggle
                            isVisible={patchedVisibility.pronouns}
                            setVisible={(v) =>
                              setPersonaVisibilityPatch((p) => ({
                                ...p,
                                pronouns: v,
                              }))
                            }
                          />
                          <CPDeleteButton
                            onClick={() =>
                              setPersonaPatch((p) => ({ ...p, pronouns: null }))
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  {/*** </Professional 2> ***/}
                </>
              ) : null}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions className={classes.save}>
        {!persona?.isProfessionalProfile && (
          <>
            {isSmallScreen ? (
              <CPDeleteButton onClick={handleDelete} />
            ) : (
              <Button
                aria-label="close"
                onClick={handleDelete}
                className={classes.deleteButton}
              >
                Delete project
              </Button>
            )}
          </>
        )}
        <Button
          size="large"
          className={classes.cancelButton}
          onClick={handleCancel}
          data-testid={`cancel-button`}
        >
          Cancel
        </Button>
        <Button
          size="large"
          type="submit"
          className={classes.saveButton}
          data-testid="save-button"
          onClick={handleSave}
        >
          <Checkmark sx={{ fontSize: 12 }} />
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SummaryEdit;
