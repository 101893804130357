import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  const { color } = props;
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 24 24">
      <title>Languages</title>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Atoms/icons/languages/UI" fill={color || '#626466'}>
          <path
            d="M18,1 L20,3 L20,15 L18,17 L10.25,17 L2,23 L2,22.891 L0,20.891 L0,3 L2,1 L18,1 Z M21.9999,1 L23.9999,3 L23.9999,22.891 L21.9999,22.891 L21.9999,23 L16.4999,19 L19.8999,19 L21.9999,20.526 L21.9999,2.171 L20.8279,1 L21.9999,1 Z M18,3 L4,3 L4,19.072 L9.599,15 L18,15 L18,3 Z M10.9997,3.9996 C13.7607,3.9996 15.9997,6.2386 15.9997,8.9996 C15.9997,11.7616 13.7607,13.9996 10.9997,13.9996 C8.2387,13.9996 5.9997,11.7616 5.9997,8.9996 C5.9997,6.2386 8.2387,3.9996 10.9997,3.9996 Z M14.4147,9.7496 L12.9697,9.7496 C12.9157,10.6426 12.7677,11.4496 12.5507,12.1216 C13.4847,11.6556 14.1847,10.7946 14.4147,9.7496 L14.4147,9.7496 Z M9.0297,9.7496 L7.5847,9.7496 C7.8147,10.7946 8.5157,11.6556 9.4487,12.1216 C9.2317,11.4496 9.0837,10.6426 9.0297,9.7496 L9.0297,9.7496 Z M11.4667,9.7496 L10.5327,9.7496 C10.6037,10.7516 10.7997,11.5426 10.9997,12.0356 C11.1997,11.5426 11.3957,10.7516 11.4667,9.7496 L11.4667,9.7496 Z M9.4487,5.8786 C8.5157,6.3446 7.8147,7.2056 7.5847,8.2496 L7.5847,8.2496 L9.0297,8.2496 C9.0837,7.3576 9.2317,6.5506 9.4487,5.8786 Z M10.9997,5.9646 C10.7997,6.4566 10.6037,7.2476 10.5327,8.2496 L10.5327,8.2496 L11.4667,8.2496 C11.3957,7.2476 11.1997,6.4566 10.9997,5.9646 Z M12.5507,5.8786 C12.7677,6.5506 12.9157,7.3576 12.9697,8.2496 L12.9697,8.2496 L14.4147,8.2496 C14.1847,7.2056 13.4847,6.3446 12.5507,5.8786 Z"
            id="Combined-Shape"
          ></path>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
