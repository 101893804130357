import React from 'react';
import { SvgIcon } from '@mui/material';

function Icon(props) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
    >
      <title>Users</title>
      <defs>
        <linearGradient
          x1="78.125%"
          y1="100%"
          x2="21.9264264%"
          y2="0.0914246886%"
          id="linearGradient-1"
        >
          <stop stop-color="#FF2667" offset="0.0914246886%"></stop>
          <stop stop-color="#73AEE6" offset="50.9814282%"></stop>
          <stop stop-color="#00FFCC" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Profile-|-Basic---UI"
          transform="translate(-444.000000, -28.000000)"
          fill="url(#linearGradient-1)"
        >
          <g
            id="atoms/icons/users"
            transform="translate(444.000000, 28.000000)"
          >
            <path
              d="M12.6664,3.55271368e-15 L13.3317333,0.666 L5.33306667,0.666666667 L5.33306667,1.33333333 L12.6664,1.33333333 L12.6664,12.6666667 L13.3330667,12.6666667 L13.3327333,0.667 L13.9997333,1.33333333 L13.9997333,14.6666667 L12.6664,16 L3.33306667,16 L1.99973333,14.6666667 L1.99973333,1.33333333 L3.33306667,3.55271368e-15 L12.6664,3.55271368e-15 Z M11.3330667,11.3333333 L5.33306667,11.3333333 L5.33306667,12.6666667 L11.3330667,12.6666667 L11.3330667,11.3333333 Z M8.33306667,3.33333333 C6.6764,3.33333333 5.33306667,4.67666667 5.33306667,6.33333333 C5.33306667,7.98933333 6.6764,9.33333333 8.33306667,9.33333333 C9.98973333,9.33333333 11.3330667,7.98933333 11.3330667,6.33333333 C11.3330667,4.67666667 9.98973333,3.33333333 8.33306667,3.33333333 Z M8.33306667,4.66666667 C9.2524,4.66666667 9.99973333,5.414 9.99973333,6.33333333 C9.99973333,7.252 9.2524,8 8.33306667,8 C7.4144,8 6.6664,7.252 6.6664,6.33333333 C6.6664,5.414 7.4144,4.66666667 8.33306667,4.66666667 Z"
              id="Combined-Shape"
            ></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Icon;
