import React from 'react';
import { makeStyles } from '@mui/styles';
import styles from './styles';
import CPVisibleIcon from '../../CPVisibleIcon';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';

const useStyles = makeStyles(styles);

const InfoField = ({ Icon, title, values, isVisible }) => {
  if (typeof values === 'string') {
    values = values ? [values] : [];
  }
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.heading}>
        <Typography variant="h4" className={classes.headingText}>
          <Icon />
          {title}
        </Typography>
        <CPVisibleIcon isVisible={isVisible} />
      </div>
      <Box className={classes.items}>
        {values?.length ? (
          <div className={classes.items}>
            {values.map((el, i) => (
              <div key={i} className={classes.itemFull}>
                <div className={classes.item}>{el}</div>
              </div>
            ))}
          </div>
        ) : null}
      </Box>
    </div>
  );
};

export default InfoField;
